import React from 'react';
import AssetMap from './AssetMap';
import { UserType } from './types';
import { useTranslation } from 'react-i18next';


interface HomePageProps {
  user: UserType;
}

const HomePage: React.FC<HomePageProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div>
      <AssetMap user={user} />
      {/* Add other components or content as needed */}
    </div>
  );
};

export default HomePage;