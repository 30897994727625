import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc,DocumentData  } from 'firebase/firestore';
import { db, app } from './firebase-config';
import { UserType } from './types';
import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import './BillingPages.css';
import { useTranslation } from 'react-i18next';


// Make sure to replace with your actual publishable key
const stripePromise = loadStripe('pk_live_51PgRMcD36Q5evwnab8Ec4hPjDEyHjv8evN73kqUa23l2Th3mS7yjl5IJVx6CZZAjz2C3yHMJvolWIrEM1FGEbnPx00JGLqJL45');

const BillingInfoPage: React.FC<{ user: UserType }> = ({ user }) => {
  const [organizationType, setOrganizationType] = useState<'individual' | 'organization' | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();


  interface CheckoutSessionResponse {
    sessionId: string;
  }

  interface OrganizationData extends DocumentData {
    type: 'individual' | 'organization';  // Adjust these types as needed
    // Add other fields that you expect in your organization document
  }

  const subscriptionPlans = [
    { name: 'Basic', priceId: 'price_1PuqW1D36Q5evwnaVxFlLcQK', description: t('Up to 150 assets'), price: t('50€/month'), overUse: t('0,3€/asset/month') },
    { name: 'Standard', priceId: 'price_1PuqXkD36Q5evwnacuBuUZZi', description: t('Up to 400 assets'), price: t('100€/month'), overUse: t('0,3€/asset/month') },
    { name: 'Enterprise', priceId: 'price_1PuqYxD36Q5evwnaJnAGhDjX', description: t('Up to 1000 assets'), price: t('200€/month'), overUse: t('0,3€/asset/month') },
  ];

  useEffect(() => {
    const fetchOrganizationType = async () => {
      setLoading(true);
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        
        if (!userDoc.exists()) {
          throw new Error('User document not found');
        }
        
        const userData = userDoc.data();
        const organizationRef = userData?.organization;
  
        if (!organizationRef) {
          throw new Error('User has no associated organization');
        }
  
        const orgDoc = await getDoc(organizationRef);
        
        if (orgDoc.exists()) {
          const orgData = orgDoc.data() as OrganizationData;
          if (orgData && orgData.type) {
            setOrganizationType(orgData.type);
          } else {
            setOrganizationType('organization');
            //throw new Error('Organization type not found');
          }
        } else {
          throw new Error('Organization document not found');
        }
      } catch (err) {
        console.error('Error fetching organization information:', err);
        setError('Failed to load organization information');
      } finally {
        setLoading(false);
      }
    };
  
    if (user && user.uid) {
      fetchOrganizationType();
    }
  }, [user]);

  const handleSubscribe = async (priceId: string) => {
    setLoading(true);
    try {
      const functions = getFunctions(app, 'europe-west1');
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      
      const result = await createCheckoutSession({ 
        priceId,
        userId: user.uid // Pass the user ID to the cloud function
      });
      const { sessionId } = result.data as { sessionId: string };
  
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to initialize');
  
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) throw error;
    } catch (error: any) {
      setError(error.message || 'Failed to initiate checkout');
    } finally {
      setLoading(false);
    }
  };


  if (loading) return <div className="loading">{t("Loading...")}</div>;
  if (error) return <div className="error">{error}</div>;
  if (organizationType === null) return <div className="error">{t("Failed to load organization information")}</div>;

  return (
    <div className="billing-container">
      <h1>{t("Choose Your Subscription Plan")}</h1>
      <div className="subscription-plans">
        {subscriptionPlans.map((plan) => (
          <div key={plan.priceId} className="plan-container">
            <div className="plan-card">
              <div className="plan-card-content">
                <h2>{plan.name}</h2>
                <p className="plan-price">{plan.price}<span className="per-month"></span></p>
                <p className="plan-description">
                <span className="highlight">{plan.description}</span>
                </p>
                <p className="plan-overuse">
                  {t("Overuse")}: <span className="highlight">{plan.overUse}</span>
                </p>
              </div>
              <div className="plan-card-button">
                <button 
                  onClick={() => handleSubscribe(plan.priceId)} 
                  disabled={loading}
                  className="btn btn-primary"
                >
                  {loading ? t('Processing...') : t('Subscribe')}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};
  


export default BillingInfoPage;