import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { orderBy, limit, collection, query, where, getDocs, getDoc, startAfter, updateDoc, doc, deleteDoc, addDoc, writeBatch, DocumentReference, arrayUnion  } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from './firebase-config';
import { AssetType, Asset, AssetWithLocation, UserType, Observation } from './types';
import { CSVLink } from "react-csv";
import { ColDef, GridApi, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import './AssetListPage.css';
import './global.css';
import './AssetModal.css';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import ObservationModal from './ObservationModal'
import { Link } from 'react-router-dom';
import AssetModal from './AssetModal';
import { useParams, useLocation } from 'react-router-dom';


const extractFileName = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    const pathname = decodeURIComponent(parsedUrl.pathname);
    return pathname.split('/').pop() || 'Unknown file';
  } catch (error) {
    return 'Unknown file';
  }
};

interface AssetListPageProps {
  user: UserType;
}

const AssetListPage: React.FC<AssetListPageProps> = ({ user }) => {
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
  //const [selectedAssetType, setSelectedAssetType] = useState<string>('');
  const [selectedAssetTypeRef, setSelectedAssetTypeRef] = useState<string>('');
  const [allAssets, setAllAssets] = useState<AssetWithLocation[]>([]);
  const [loading, setLoading] = useState(false);
  const [deletingAsset, setDeletingAsset] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const {t} = useTranslation();
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const [observationModalOpen, setObservationModalOpen] = useState(false);
  const [selectedAssetForObservations, setSelectedAssetForObservations] = useState<Asset | null>(null);
  const [currentObservationIndex, setCurrentObservationIndex] = useState(0);
  const [selectedObservation, setSelectedObservation] = useState<Observation | null>(null);
  const [selectedAssetObservations, setSelectedAssetObservations] = useState<Observation[]>([]);
  const [processedAssetDetails, setProcessedAssetDetails] = useState<JSX.Element[]>([]);
  const [documents, setDocuments] = useState<{ url: string; name: string }[]>([]);
  const { assetId } = useParams<{ assetId?: string }>();
  const location = useLocation()
  const [isLoadingAssetType, setIsLoadingAssetType] = useState(false);
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const [isObservationModalOpen, setIsObservationModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalAssets, setTotalAssets] = useState(0);
  const pageSize = 20;

 
  useEffect(() => {
    if (assetId && assetTypes.length > 0) {
      handleAssetIdInUrl(assetId);
    }
  }, [assetId, assetTypes]);
  
  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('rowData', allAssets);
    }
  }, [allAssets, gridApi]);

  const handleAssetIdInUrl = async (assetId: string) => {
    setIsLoadingAssetType(true);
    try {
      const assetsQuery = query(
        collection(db, "assets"), 
        where("organization", "==", user.organization), 
        where("id", "==", assetId)
      );
      const assetSnapshot = await getDocs(assetsQuery);
      if (!assetSnapshot.empty) {
        const assetDoc = assetSnapshot.docs[0];
        const assetData = assetDoc.data();
        const assetTypeRef = assetData.assetType as DocumentReference;

        // Find the corresponding asset type in our list
        const matchingAssetType = assetTypes.find(type => type.id === assetTypeRef.id);

        if (matchingAssetType) {
          setSelectedAssetTypeRef(matchingAssetType.id);
          await fetchAssets(); // This will now load the assets for the correct type
        }
      }
    } catch (error) {
      console.error("Error handling asset ID from URL:", error);
    } finally {
      setIsLoadingAssetType(false);
    }
  };

  const openObservationModal = async (asset: Asset) => {
    try {
      const observationsQuery = query(
        collection(db, 'observations'),
        where('asset', '==', doc(db, 'assets', asset.firebaseId)),
        where("organization", "==", user.organization),
        where('noted', '==', false)
      );
      const observationSnapshot = await getDocs(observationsQuery);
      if (!observationSnapshot.empty) {
        const observations = observationSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        } as Observation));
        setSelectedAssetObservations(observations);
        setCurrentObservationIndex(0);
        setIsObservationModalOpen(true);
        setIsAssetModalOpen(false); 
      } else {
        console.log('No active observations for this asset');
      }
    } catch (error) {
      console.error('Error fetching observations:', error);
    }
  };

  
  const handleNextObservation = () => {
    setCurrentObservationIndex((prevIndex) => 
      (prevIndex + 1) % selectedAssetObservations.length
    );
  };
  const handlePreviousObservation = () => {
    setCurrentObservationIndex((prevIndex) => 
      (prevIndex - 1 + selectedAssetObservations.length) % selectedAssetObservations.length
    );
  };

  const closeObservationModal = () => {
    setObservationModalOpen(false);
    setSelectedAssetForObservations(null);
  };

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  useEffect(() => {
    fetchAssetTypes().catch(error => {
      console.error("Error fetching asset types:", error);
    });
  }, [user]);
  

  //useEffect(() => {
   // if (selectedAssetTypeRef) {
    //  const unsubscribe = fetchAssets();
     // return () => unsubscribe();
    //}
  //}, [selectedAssetTypeRef]);


  const handleDocumentUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedAsset || !event.target.files || event.target.files.length === 0) return;
  
    const file = event.target.files[0];
    const fileType = file.type;
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];
  
    if (!validTypes.includes(fileType)) {
      alert(t("Only PDF and image files are allowed"));
      return;
    }
  
    const storage = getStorage();
    const orgRef = user.organization as DocumentReference;
    const orgDoc = await getDoc(orgRef);
    const safeOrgName = orgDoc.id;
  
    const storageRef = ref(storage, `documents/${safeOrgName}/${selectedAsset.firebaseId}/${Date.now()}_${file.name}`);
  
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
  
      const assetRef = doc(db, 'assets', selectedAsset.firebaseId);
      await updateDoc(assetRef, {
        documentURLs: arrayUnion({ url: downloadURL, name: file.name })
      });
  
      // Update local state
      setSelectedAsset({
        ...selectedAsset,
        documentURLs: [...(selectedAsset.documentURLs || []), { url: downloadURL, name: file.name }]
      });
  
      setDocuments(prevDocuments => [...prevDocuments, { url: downloadURL, name: file.name }]);
  
      alert(t("Document uploaded successfully!"));
    } catch (error) {
      console.error("Error uploading document: ", error);
      alert(t("Failed to upload document. Please try again."));
    }
  };

  const openDocument = (doc: string | { url: string; name: string }) => {
    const url = typeof doc === 'string' ? doc : doc.url;
    window.open(url, '_blank');
  };

const onGridReady = (params: GridReadyEvent) => {
  setGridApi(params.api);
};

const processAssetDetails = async (asset: Asset) => {
  const detailPromises = Object.entries(asset).map(([key, value]) => {
    if (key !== 'id' && key !== 'name' && key !== 'firebaseId' && key !== 'type' && key !== 'organization' && key !== 'photoURLs' && key !== 'documentURLs') {
      return renderAssetDetail(key, value);
    }
    return Promise.resolve(null);
  });

  const details = await Promise.all(detailPromises);
  setProcessedAssetDetails(details.filter((detail): detail is JSX.Element => detail !== null));
};

const formatKey = (key: string): string => {
  const specialCases: {[key: string]: string} = {
    'createdBy': 'Created by',
    'activeObservationCount' : t('Active observation count')
    // Add more special cases here
  };

  if (key in specialCases) {
    return specialCases[key];
  }
  // Convert camelCase to Title Case
  return key.replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
    .trim();
};

const renderAssetDetail = (key: string, value: any): Promise<JSX.Element | null> => {
  return new Promise(async (resolve) => {
    const formattedKey = formatKey(key);

    if (key === 'latestLocation') {
      if (value && typeof value === 'object') {
        resolve(
          <div key={key}>
            <strong>{t("Latest Location")}:</strong>
            <p><strong>{t("Coordinates")}:</strong> {value.coords.latitude}, {value.coords.longitude}</p>
            <p><strong>{t("Address")}:</strong> {value.address}</p>
            <p><strong>{t("Timestamp")}:</strong> {new Date(value.timestamp.seconds * 1000).toLocaleString()}</p>
          </div>
        );
      } else {
        resolve(<p key={key}><strong>{formattedKey}:</strong> Not available</p>);
      }
    } else if (value instanceof DocumentReference) {
      if (key === 'createdBy') {
        try {
          const docSnap = await getDoc(value);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            resolve(<p key={key}><strong>{t("Created By")}:</strong> {userData.email}</p>);
          } else {
            resolve(<p key={key}><strong>{t("Created By")}:</strong> [User not found]</p>);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          resolve(<p key={key}><strong>{formattedKey}:</strong> [Error fetching data]</p>);
        }
      } else {
        resolve(<p key={key}><strong>{formattedKey}:</strong> [Document Reference]</p>);
      }
    } else if (typeof value === 'object' && value !== null) {
      resolve(<p key={key}><strong>{formattedKey}:</strong> {JSON.stringify(value)}</p>);
    } else {
      resolve(<p key={key}><strong>{formattedKey}:</strong> {value}</p>);
    }
  });
};

const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  if (!selectedAsset || !event.target.files || event.target.files.length === 0) return;

  const file = event.target.files[0];
  const storage = getStorage();
  const orgRef = user.organization as DocumentReference;
  const orgDoc = await getDoc(orgRef);
  // Create a URL-friendly version of the organization name

  const safeOrgName = orgDoc.id;
  const storageRef = ref(storage, `asset_photos/${safeOrgName}/${selectedAsset.firebaseId}/${Date.now()}_${file.name}`);
  console.log("Uploading to path:", storageRef.fullPath);
  console.log("User organization ID:", safeOrgName);
  console.log("Asset firebaseId:", selectedAsset.firebaseId);

  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    const assetRef = doc(db, 'assets', selectedAsset.firebaseId);
    await updateDoc(assetRef, {
      photoURLs: [...(selectedAsset.photoURLs || []), downloadURL]
    });

    // Update local state
    setSelectedAsset({
      ...selectedAsset,
      photoURLs: [...(selectedAsset.photoURLs || []), downloadURL]
    });

    alert("Image uploaded successfully!");
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('storage/unauthorized')) {
        alert("You don't have permission to upload images. Please check your account permissions.");
      } else {
        console.error("Error uploading file: ", error);
        alert(`Failed to upload image: ${error.message}`);
      }
    } else {
      console.error("An unknown error occurred", error);
      alert("An unknown error occurred. Please try again.");
    }
  }
};

const toggleColumnVisibility = (field: string) => {
  setVisibleColumns(prev => 
    prev.includes(field) ? prev.filter(col => col !== field) : [...prev, field]
  );
};

const fetchAssetTypes = async () => {
  if (!user.organization) {
    console.warn("No user organization or found");
    return;
  }
  const assetTypesQuery = query(collection(db, "assetTypes"), where("organization", "==", user.organization));
  const assetTypesSnapshot = await getDocs(assetTypesQuery);
  const fetchedAssetTypes = assetTypesSnapshot.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      typeName: data.typeName || data.name,
      organization: data.organization,
      attributes: data.attributes || [],
      parentType: data.parentType
    } as AssetType;
  });
  console.log("Fetched Asset Types:", fetchedAssetTypes);
  setAssetTypes(fetchedAssetTypes);
};

const fetchAssetObservationCounts = async (assets: AssetWithLocation[]): Promise<Record<string, number>> => {
  const counts = await Promise.all(assets.map(async (asset) => {
    const q = query(
      collection(db, 'observations'),
      where('organization', '==', user.organization),
      where('asset', '==', doc(db, 'assets', asset.firebaseId)),
      where('noted', '==', false)
    );
    const snapshot = await getDocs(q);
    return { assetId: asset.firebaseId, count: snapshot.size };
  }));

  return counts.reduce((acc, { assetId, count }) => {
    acc[assetId] = count;
    return acc;
  }, {} as Record<string, number>);
};

const fetchAssets = useCallback(async () => {
  if (!user.organization || !selectedAssetTypeRef) return;
  setLoading(true);

  try {
    const assetTypeRef = doc(db, 'assetTypes', selectedAssetTypeRef);
    
    const assetsQuery = query(
      collection(db, "assets"),
      where("organization", "==", user.organization),
      where("assetType", "==", assetTypeRef)
    );

    const snapshot = await getDocs(assetsQuery);
    setTotalAssets(snapshot.size);

    const fetchedAssets = await Promise.all(snapshot.docs.map(async (doc) => {
      const data = doc.data();
      const latestLocation = await fetchLatestLocation(doc.ref);
      const documentURLs = data.documentURLs ? data.documentURLs.map((doc: string | { url: string; name: string }) => {
        
        if (typeof doc === 'string') {
          // If it's just a string URL, extract the filename
          const name = doc.split('/').pop() || 'Unknown';
          return { url: doc, name };
        }
        return doc;
      }) : [];

      return {
        firebaseId: doc.id,
        id: data.id || '',
        assetType: data.assetType,
        organization: user.organization,
        attributes: data.attributes || {},
        ...data,
        latestLocation: latestLocation ? {
          coords: {
            latitude: latestLocation.coords.latitude,
            longitude: latestLocation.coords.longitude
          },
          timestamp: latestLocation.timestamp,
          geofenceName: latestLocation.geofenceName || null, // Include geofenceName here
        } : undefined,
        documentURLs,
      } as AssetWithLocation;
    }));

    const observationCounts = await fetchAssetObservationCounts(fetchedAssets);
    const assetsWithCounts = fetchedAssets.map(asset => ({
      ...asset,
      activeObservationCount: observationCounts[asset.firebaseId] || 0
    }));

    setAllAssets(assetsWithCounts);
    if (assetId) {
      const assetToOpen = assetsWithCounts.find(a => a.id === assetId);
      if (assetToOpen) {
        openAssetModal(assetToOpen);
      }
    }
  } catch (error) {
    console.error("Error fetching assets:", error);
  } finally {
    setLoading(false);
  }
}, [user.organization, selectedAssetTypeRef]);


const getLastVisibleAsset = async (page: number) => {
  const assetTypeRef = doc(db, 'assetTypes', selectedAssetTypeRef);
  const q = query(
    collection(db, "assets"),
    where("organization", "==", user.organization),
    where("type", "==", assetTypeRef),
    orderBy("id"),
    limit(page * pageSize)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs[snapshot.docs.length - 1];
};

const fetchLatestLocation = async (assetRef: DocumentReference) => {
  const locationsQuery = query(
    collection(db, "locations"),
    where("asset", "==", assetRef),
    where("organization", "==", user.organization),
    orderBy("timestamp", "desc"),
    limit(1)
  );
  const locationSnapshot = await getDocs(locationsQuery);
  const latestLocation = locationSnapshot.docs[0]?.data();

  return latestLocation ? {
    coords: { 
      latitude: latestLocation.location.latitude, 
      longitude: latestLocation.location.longitude 
    },
    timestamp: latestLocation.timestamp,
    address: latestLocation.address,
    geofenceName: latestLocation.geofenceName || null,
  } : undefined;
};

useEffect(() => {
  if (selectedAssetTypeRef) {
    const selectedType = assetTypes.find(type => type.id === selectedAssetTypeRef);
    if (selectedType) {
      const initialVisibleColumns = [
        'id', 
        'name', 
        ...selectedType.attributes.map(attr => `attributes.${attr.name}`),
        'latestLocation.coords',
        'latestLocation.geofenceName',
        'latestLocation.timestamp',
        'activeObservationCount',
        'actions'
      ];
      setVisibleColumns(initialVisibleColumns);
    }
    fetchAssets();
  }
}, [selectedAssetTypeRef, assetTypes, fetchAssets]);

useEffect(() => {
  if (selectedAssetTypeRef) {
    fetchAssets();
  }
}, [selectedAssetTypeRef, fetchAssets]);

//const onPageChanged = (newPage: number) => {
 // setCurrentPage(newPage);
 /// fetchAssets(newPage);
//};

  const handleAssetTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAssetTypeRef(e.target.value);
    setEditMode(false);
  }; 

  const openAssetModal = (asset: Asset) => {
    setSelectedAsset(asset);
    processAssetDetails(asset);
    
    // Convert all document URLs to the { url, name } format
    const formattedDocuments = (asset.documentURLs || []).map(doc => {
      if (typeof doc === 'string') {
        return { url: doc, name: extractFileName(doc) };
      }
      return doc;
    });
    
    setDocuments(formattedDocuments);
    setIsAssetModalOpen(true);
    setIsObservationModalOpen(false); // 
  };

  const closeAssetModal = () => {
    setSelectedAsset(null);
    setIsAssetModalOpen(false);
    };

  const columnDefs = useMemo<ColDef[]>(() => {
    if (!selectedAssetTypeRef) return [];
    const selectedType = assetTypes.find(type => type.id === selectedAssetTypeRef);
    if (!selectedType) return [];
  
    const cols: ColDef[] = [
      { 
        field: 'id', 
        headerName: 'ID', 
        editable: editMode,
        cellRenderer: (params: any) => (
          <span 
            style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}
            onClick={() => openAssetModal(params.data)}
          >
            {params.value}
          </span>
        )
      },
      ...selectedType.attributes.map(attr => ({
        field: `attributes.${attr.name}`,
        headerName: attr.name,
        editable: editMode,
        cellEditor: attr.type === 'enum' ? 'agSelectCellEditor' : 
                    attr.type === 'boolean' ? 'agSelectCellEditor' : 'agTextCellEditor',
        cellEditorParams: attr.type === 'enum' ? { values: attr.options || [] } :
                          attr.type === 'boolean' ? { values: ['0', '1'] } : undefined,
        valueFormatter: attr.type === 'boolean' ? 
          (params: ValueFormatterParams) => params.value === '1' ? 'True' : 'False' : undefined,
        valueGetter: (params: any) => {
          return params.data.attributes ? params.data.attributes[attr.name] : '';
        },
        valueSetter: (params: any) => {
          if (!params.data.attributes) {
            params.data.attributes = {};
          }
          params.data.attributes[attr.name] = params.newValue;
          return true;
        }
      })),
      {
        field: 'latestLocation.coords',
        headerName: t('Location'),
        //filter: 'agTextColumnFilter',
        //floatingFilter: true,
        valueFormatter: (params) => {
          const coords = params.value;
          return coords ? `${coords.latitude}, ${coords.longitude}` : 'N/A';
        },
      },
      {
        field: 'latestLocation.geofenceName',
        headerName: t('Geofence'),
        valueFormatter: (params) => {
          return params.value ? `${params.value}` : t('Outside Geofence');
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        //filter: 'agTextColumnFilter',
        //floatingFilter: true,
      },
      {
        field: 'latestLocation.timestamp',
        headerName: t('Days Since Last Update'),
        valueGetter: (params) => {
          const timestamp = params.data.latestLocation?.timestamp;
          if (!timestamp) return null;
          const diffTime = Math.abs(new Date().getTime() - timestamp.toDate().getTime());
          return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        },
        valueFormatter: (params) => {
          return params.value === null ? 'N/A' : params.value.toString();
        },
        sortable: true,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'activeObservationCount',
        headerName: t('Observations'),
        cellRenderer: (params: any) => (
          <button onClick={() => openObservationModal(params.data)}
          className="action-button observation-button">
            {params.value}
          </button>
        )
      },
      {
        field: 'publicPage',
        headerName: t('Public Page'),
        cellRenderer: (params: any) => (
          <input
            type="checkbox"
            checked={params.value}
            onChange={() => handlePublicPageChange(params.data)}
            disabled={!editMode}
          />
        )
      },
      {
        headerName: t('Actions'),
        cellRenderer: (params: any) => (
          <button 
            onClick={() => handleDeleteAsset(params.data.firebaseId)}
            disabled={!editMode || deletingAsset === params.data.firebaseId}
            className="action-button delete-button"
          >
            {deletingAsset === params.data.firebaseId ? t('Deleting...') : t('Delete')}
          </button>
        )
      }
    ] as ColDef[];

    return cols.map(col => ({
      ...col,
      hide: col.field ? !visibleColumns.includes(col.field) : false
    }));

  }, [selectedAssetTypeRef, assetTypes, deletingAsset, editMode, visibleColumns]);

  const defaultColDef = useMemo<ColDef>(() => ({
    sortable: true,
    filter: true,
    resizable: true
  }), []);

  const onCellValueChanged = async (params: any) => {
    if (!editMode || !user) return;
    const { data, colDef, newValue } = params;
    
    try {
      const userRef = doc(db, 'users', user.uid);
      if (data.firebaseId) {
        // Existing asset, update in Firestore
        const assetRef = doc(db, 'assets', data.firebaseId);
        if (colDef.field.startsWith('attributes.')) {
          const attributeName = colDef.field.split('.')[1];
          await updateDoc(assetRef, { 
            [`attributes.${attributeName}`]: newValue, 
            lastUpdatedBy: userRef,
            lastUpdatedAt: new Date() 
          });
        } else {
          await updateDoc(assetRef, { 
            [colDef.field]: newValue, 
            lastUpdatedBy: userRef,
            lastUpdatedAt: new Date() 
          });
        }
        console.log(`Updated asset ${data.firebaseId}, field ${colDef.field} to ${newValue}`);
      } else {
        // New asset, add to Firestore
        const newAsset: Partial<Asset> & { attributes: Record<string, any> } = {
          id: data.id || '',
          assetType: doc(db, 'assetTypes', selectedAssetTypeRef),
          createdBy: userRef,
          organization: user.organization,
          attributes: {},
          lastUpdatedBy: userRef,
          lastUpdatedAt: new Date()
        };
        
        if (colDef.field.startsWith('attributes.')) {
          const attributeName = colDef.field.split('.')[1];
          if (attributeName) {
            newAsset.attributes[attributeName] = newValue;
          }
        } else {
          (newAsset as any)[colDef.field] = newValue;
        }
        const docRef = await addDoc(collection(db, 'assets'), newAsset);
        data.firebaseId = docRef.id;
        console.log(`Added new asset with ID ${docRef.id}`);
      }
      
      // Update the grid data
      if (gridApi) {
        const updatedData = { ...data, [colDef.field]: newValue, firebaseId: data.firebaseId };
        gridApi.applyTransaction({ update: [updatedData] });
      }
    } catch (error) {
      console.error("Error updating/adding asset:", error);
    }
  };

  const handlePublicPageChange = async (asset: AssetWithLocation) => {
    if (!editMode) return;
    
    try {
      const newPublicPageValue = !asset.publicPage;
      await updateDoc(doc(db, 'assets', asset.firebaseId), {
        publicPage: newPublicPageValue
      });
      
      // Update the local state
      setAllAssets(prevAssets => 
        prevAssets.map(a => 
          a.firebaseId === asset.firebaseId ? { ...a, publicPage: newPublicPageValue } : a
        )
      );
    } catch (error) {
      console.error("Error updating publicPage:", error);
      // Optionally, you can show an error message here
    }
  };

  const handleDeleteAsset = async (firebaseId: string) => {
  if (!editMode) return;
  if (window.confirm(t("Are you sure you want to delete this asset? This will also delete related QR codes and locations."))) {
    try {
      setDeletingAsset(firebaseId);
      
      // Remove the asset from the local state first
      setAllAssets(prevAssets => prevAssets.filter(asset => asset.firebaseId !== firebaseId));

      // Update the grid
      if (gridApi) {
        const rowToRemove = gridApi.getRowNode(firebaseId);
        if (rowToRemove) {
          gridApi.applyTransaction({ remove: [rowToRemove.data] });
        }
      }

      // Reference to the asset
      const assetRef = doc(db, "assets", firebaseId);
      
      // Query to find all QR codes associated with this asset
      const qrCodesQuery = query(
        collection(db, "qrcodes"), 
        where("asset", "==", assetRef),
        where("organization", "==", user.organization)
      );
      const qrCodesSnapshot = await getDocs(qrCodesQuery);
      
      // Start a batch write
      const batch = writeBatch(db);
      
      // For each QR code
      for (const qrDoc of qrCodesSnapshot.docs) {
        // Query to find all locations associated with this QR code
        const locationsQuery = query(
          collection(db, "locations"), 
          where("qrcode", "==", qrDoc.ref),
          where("organization", "==", user.organization)
        );
        const locationsSnapshot = await getDocs(locationsQuery);
        
        // Delete all locations associated with this QR code
        locationsSnapshot.docs.forEach((locationDoc) => {
          batch.delete(locationDoc.ref);
        });
        
        // Delete the QR code
        batch.delete(qrDoc.ref);
      }
      
      // Delete the asset
      batch.delete(assetRef);
      
      // Commit the batch
      await batch.commit();
      
      console.log("Asset and related data deleted successfully");

    } catch (error) {
      console.error("Error deleting asset and related data:", error);
      alert("An error occurred while deleting the asset and related data");
    } finally {
      setDeletingAsset(null);
    }
  }
};

  const getCSVData = () => {
    const selectedType = assetTypes.find(type => type.id === selectedAssetTypeRef);
    if (!selectedType) return [['No data available']];
  
    // Get attribute names from the selected asset type
    const attributeNames = selectedType.attributes.map(attr => attr.name);
  
    const headers = ['id', 'name', ...attributeNames];
    const data = allAssets.map(asset => [
      asset.id,
      asset.name,
      ...attributeNames.map(attrName => asset[attrName] || '')
    ]);
  
    return [headers, ...data];
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const addNewAsset = useCallback(() => {
    if (!gridApi || !user || !selectedAssetTypeRef) return;
    const newAsset: Partial<Asset> = {
      firebaseId: '',
      id: '',
      assetType: doc(db, 'assetTypes', selectedAssetTypeRef),
      organization: user.organization || '',
      attributes: {},
    };
    gridApi.applyTransaction({ add: [newAsset] });
  }, [gridApi, selectedAssetTypeRef, user.organization]);

  const ColumnVisibilityFilter: React.FC<{
    columns: ColDef[];
    visibleColumns: string[];
    toggleColumnVisibility: (field: string) => void;
  }> = ({ columns, visibleColumns, toggleColumnVisibility }) => {
    return (
      <div className="column-visibility-filter">
        <h4>{t('Column Visibility')}</h4>
          {columns.filter(col => col.field && col.field !== 'actions').map(col => (
          <label key={col.field}>
            <input
              type="checkbox"
              checked={visibleColumns.includes(col.field || '')}
              onChange={() => toggleColumnVisibility(col.field || '')}
            />
            {col.headerName}
          </label>
        ))}
      </div>
    );
  };


  return (
    <div className="asset-list-container">
      <h1 className="page-title">{t('Asset List')}</h1>
      {isLoadingAssetType ? (
        <div className="loading">{t("Loading asset type...")}</div>
      ) : (
        <div className="asset-type-selector">
          <select onChange={handleAssetTypeChange} value={selectedAssetTypeRef}>
            <option value="">{t('Select Asset Type')}</option>
            {assetTypes.map(type => (
              <option key={type.id} value={type.id}>{type.typeName}</option>
            ))}
          </select>
        <Modal
          isOpen={isAssetModalOpen}
          onRequestClose={closeAssetModal}
          contentLabel="Asset Details"
          className="asset-modal"
          overlayClassName="asset-modal-overlay"
          style={{
            content: {
              width: '90%',
              maxWidth: '1200px',
              margin: 'auto',
              height: '90%',
              overflow: 'auto',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
          }}
        >
          {selectedAsset && (
            <AssetModal
              asset={selectedAsset}
              closeModal={closeAssetModal}
              handleFileUpload={handleFileUpload}
              handleDocumentUpload={handleDocumentUpload}
              openDocument={openDocument}
              t={t}
              user={user}
            />
          )}
        </Modal>
      </div>
  )}
      {selectedAssetTypeRef && (
        <>
          <div className="edit-controls">
            <button type="button" className="btn edit-button" onClick={toggleEditMode}>
              {editMode ? t('Disable Editing') : t('Enable Editing')}
            </button>
            {editMode && (
              <button type="button" className="btn add-button" onClick={addNewAsset}>{t('Add New Asset')}</button>
            )}
          </div>
          <div className="ag-theme-alpine asset-grid" style={{ height: 10, width: '100%' }}>
          </div>
          <ColumnVisibilityFilter
            columns={columnDefs}
            visibleColumns={visibleColumns}
            toggleColumnVisibility={toggleColumnVisibility}
          />
           <div className="ag-theme-alpine asset-grid" style={{ height: 400, width: '100%' }}>
        <AgGridReact<Asset>
          rowData={allAssets}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellValueChanged={onCellValueChanged}
          onGridReady={onGridReady}
          readOnlyEdit={!editMode}
          getRowId={(params: any) => params.data.firebaseId}  
          pagination={true}
          paginationPageSize={pageSize}
          paginationAutoPageSize={false}
          suppressPaginationPanel={false}
        />
      </div>
  
          {allAssets.length > 0 && (
            <CSVLink className="csv-link" data={getCSVData()} filename={"assets.csv"}>{t('Download CSV')}</CSVLink>
          )}
        </>
      )}
  
      {loading && <div className="loading">Loading...</div>}
      {selectedAssetObservations.length > 0 && (
        
        <ObservationModal
        isOpen={isObservationModalOpen}
          onRequestClose={() => {
            closeObservationModal();
            setSelectedAssetObservations([]);
          }}
          observation={selectedAssetObservations[currentObservationIndex]}
          user={user}
          onNext={handleNextObservation}
          onPrevious={handlePreviousObservation}
          totalObservations={selectedAssetObservations.length}
          currentIndex={currentObservationIndex}
        />
      )}
  </div>
);
};

export default AssetListPage;

