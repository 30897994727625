export function generateVCard(name: string, companyName: string, phone: string, email: string, address: string): string {
return `BEGIN:VCARD
VERSION:3.0
N:${name};;;;
FN:${name}
ORG:${companyName}
TEL;TYPE=CELL:${phone}
EMAIL;TYPE=WORK:${email}
ADR;TYPE=WORK:;;${address};;;;;
END:VCARD`;
}
