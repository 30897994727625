import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { 
  collection, 
  addDoc, 
  doc, 
  updateDoc, 
  deleteDoc,
  query,
  getDocs,
  where
} from 'firebase/firestore';
import { ObservationType, UserType, ObservationTypeAttribute, AssetType } from './types';
import { useTranslation } from 'react-i18next';
import './global.css';
//import './ObservationTypeForm.css';

import { 
  TextField, Select, MenuItem, Button, Grid, Typography, 
  Accordion, AccordionSummary, AccordionDetails, IconButton,
  List, ListItem, ListItemIcon, ListItemText, Checkbox, FormControlLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface ObservationTypeFormProps {
  user: UserType;
  existingObservationType?: ObservationType | null;
  onComplete: () => void;
}

const ObservationTypeForm: React.FC<ObservationTypeFormProps> = ({ user, existingObservationType, onComplete }) => {
  const [typeName, setTypeName] = useState('');
  const [attributes, setAttributes] = useState<ObservationTypeAttribute[]>([]);
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
  const [selectedAssetTypes, setSelectedAssetTypes] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchAssetTypes();
  }, []);

  useEffect(() => {
    if (existingObservationType) {
      setTypeName(existingObservationType.typeName);
      setAttributes(existingObservationType.attributes || []);
      setSelectedAssetTypes(existingObservationType.applicableAssetTypes || []);
    } else {
      setTypeName('');
      setAttributes([]);
      // Set all asset types as selected by default for new observation types
      setSelectedAssetTypes(assetTypes.map(type => type.id));
    }
  }, [existingObservationType, assetTypes]);

  const fetchAssetTypes = async () => {
    const assetTypesQuery = query(collection(db, "assetTypes"), where("organization", "==", user.organization));
    const assetTypesSnapshot = await getDocs(assetTypesQuery);
    const fetchedAssetTypes = assetTypesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as AssetType));
    setAssetTypes(fetchedAssetTypes);
    
    // If it's a new observation type, select all asset types by default
    if (!existingObservationType) {
      setSelectedAssetTypes(fetchedAssetTypes.map(type => type.id));
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!typeName.trim()) {
      alert("Observation Type Name is required");
      return;
    }
  
    const observationTypeData = {
      typeName,
      attributes,
      organization: user.organization,
      applicableAssetTypes: selectedAssetTypes
    };
  
    try {
      if (existingObservationType && existingObservationType.id) {
        await updateDoc(doc(db, 'observationTypes', existingObservationType.id), observationTypeData);
      } else {
        await addDoc(collection(db, 'observationTypes'), observationTypeData);
      }
      onComplete();
    } catch (error) {
      console.error("Error saving observation type:", error);
      alert(t("An error occurred while saving the observation type"));
    }
  };

  const addAttribute = () => {
    setAttributes([...attributes, { name: '', type: 'string', options: [] }]);
  };

  const removeAttribute = (index: number) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const updateAttribute = (index: number, field: keyof ObservationTypeAttribute, value: string) => {
    const newAttributes = [...attributes];
    if (field === 'type' && value === 'enum') {
      newAttributes[index] = { ...newAttributes[index], [field]: value, options: [''] };
    } else {
      newAttributes[index] = { ...newAttributes[index], [field]: value };
    }
    setAttributes(newAttributes);
  };

  const handleDelete = async () => {
    if (!existingObservationType || !window.confirm(t("Are you sure you want to delete this observation type?"))) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'observationTypes', existingObservationType.id));
      onComplete();
    } catch (error) {
      console.error("Error deleting observation type:", error);
      alert("An error occurred while deleting the observation type");
    }
  };

  const handleAssetTypeToggle = (assetTypeId: string) => {
    setSelectedAssetTypes(prevSelected =>
      prevSelected.includes(assetTypeId)
        ? prevSelected.filter(id => id !== assetTypeId)
        : [...prevSelected, assetTypeId]
    );
  };

  const handleSelectAllToggle = () => {
    if (selectedAssetTypes.length === assetTypes.length) {
      setSelectedAssetTypes([]);
    } else {
      setSelectedAssetTypes(assetTypes.map(type => type.id));
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("Observation Type Name")}
            value={typeName}
            onChange={(e) => setTypeName(e.target.value)}
            required
          />
        </Grid>
        
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("Attributes")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {attributes.map((attr, index) => (
                <Grid container spacing={2} key={index} style={{ marginBottom: '16px' }}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label={t("Attribute Name")}
                      value={attr.name}
                      onChange={(e) => updateAttribute(index, 'name', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      fullWidth
                      value={attr.type}
                      onChange={(e) => updateAttribute(index, 'type', e.target.value as string)}
                    >
                      <MenuItem value="string">{t("String")}</MenuItem>
                      <MenuItem value="number">{t("Number")}</MenuItem>
                      <MenuItem value="boolean">{t("Boolean")}</MenuItem>
                      <MenuItem value="enum">{t("Enum")}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => removeAttribute(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  {attr.type === 'enum' && (
                    <Grid item xs={12}>
                      {attr.options?.map((option, optionIndex) => (
                        <TextField
                          key={optionIndex}
                          value={option}
                          onChange={(e) => {
                            const newAttributes = [...attributes];
                            newAttributes[index].options![optionIndex] = e.target.value;
                            setAttributes(newAttributes);
                          }}
                          placeholder={`${t("Option")} ${optionIndex + 1}`}
                          style={{ marginRight: '8px', marginBottom: '8px' }}
                        />
                      ))}
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => {
                          const newAttributes = [...attributes];
                          newAttributes[index].options = [...(newAttributes[index].options || []), ''];
                          setAttributes(newAttributes);
                        }}
                        variant="outlined"
                        size="small"
                      >
                        {t("Add Option")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={addAttribute}
                variant="outlined"
                style={{ marginTop: '16px' }}
              >
                {t("Add Attribute")}
              </Button>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("Applicable Asset Types")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAssetTypes.length === assetTypes.length}
                    indeterminate={selectedAssetTypes.length > 0 && selectedAssetTypes.length < assetTypes.length}
                    onChange={handleSelectAllToggle}
                  />
                }
                label={t("Select/Deselect All")}
              />
              <List>
                {assetTypes.map((assetType) => (
                  <ListItem key={assetType.id} dense button onClick={() => handleAssetTypeToggle(assetType.id)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedAssetTypes.includes(assetType.id)}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={assetType.typeName} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            {existingObservationType && existingObservationType.id ? t('Update') : t('Create')} {t("Observation Type")}
          </Button>
          {existingObservationType && existingObservationType.id && (
            <Button
              onClick={handleDelete}
              variant="contained"
              color="secondary"
              style={{ marginLeft: '16px' }}
            >
              {t("Delete Observation Type")}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default ObservationTypeForm;