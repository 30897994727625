import { initializeApp, firebase } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import 'firebase/functions';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyB6ApCkE6G6hVP1VwGcgJEK1jnaPgbp0I0",
    authDomain: "effiasset-d51d6.firebaseapp.com",
    projectId: "effiasset-d51d6",
    storageBucket: "effiasset-d51d6.appspot.com",
    messagingSenderId: "292818778883",
    appId: "1:292818778883:web:d493582bb8b8e8d029c736",
    measurementId: "G-WHV04QFLD4"
  };
  
  

  //if (process.env.NODE_ENV === 'development') {
  //  connectFirestoreEmulator(db, 'localhost', 8080);
  //  connectFunctionsEmulator(functions, 'localhost', 5001);
  //  connectAuthEmulator(auth, 'http://localhost:9099');
  //}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { db, auth, app, functions };
