import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, orderBy, limit, getDocs, getDoc } from 'firebase/firestore';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


interface OrganizationData {
    name: string;
    // Add other fields that you expect in your organization document
  }

const SuperAdminDashboard: React.FC = () => {
    const [dailyStats, setDailyStats] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      fetchDailyStats();
    }, []);
  
    const fetchDailyStats = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        const statsQuery = query(collection(db, 'dailyStats'), orderBy('date', 'desc'), limit(30)); // Last 30 days
        const statsSnapshot = await getDocs(statsQuery);
        
        const statsPromises = statsSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const orgStatsPromises = data.orgStats.map(async (orgStat: any) => {
            const orgDoc = await getDoc(orgStat.organization);
            const orgData = orgDoc.data() as OrganizationData | undefined;
            return {
              ...orgStat,
              organizationName: orgData?.name || 'Unknown Organization',
            };
          });
          const resolvedOrgStats = await Promise.all(orgStatsPromises);
          return {
            date: doc.id,
            ...data,
            orgStats: resolvedOrgStats,
          };
        });
  
        const stats = await Promise.all(statsPromises);
        setDailyStats(stats.reverse()); // Reverse to show oldest first in chart
      } catch (err) {
        console.error('Failed to fetch statistics:', err);
        setError('Failed to fetch statistics');
      } finally {
        setLoading(false);
      }
    };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Get the most recent stats for the summary
  const latestStats = dailyStats[dailyStats.length - 1];

  return (
    <div>
      <h1>Super Admin Dashboard</h1>
      
      <h2>Current Summary Statistics</h2>
      <p>Total Assets: {latestStats.totalAssets}</p>
      <p>Total Users: {latestStats.totalUsers}</p>
      <p>Total Locations: {latestStats.totalLocations}</p>

      <h2>Organization Statistics</h2>
      <table>
        <thead>
          <tr>
            <th>Organization</th>
            <th>Assets</th>
            <th>Users</th>
            <th>Locations</th>
          </tr>
        </thead>
        <tbody>
          {latestStats.orgStats.map((org: any) => (
            <tr key={org.organization.id}>
              <td>{org.organizationName}</td>
              <td>{org.assetsCount}</td>
              <td>{org.usersCount}</td>
              <td>{org.locationsCount}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Trend Analysis</h2>
      <LineChart width={600} height={300} data={dailyStats}>
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="totalAssets" stroke="#8884d8" />
        <Line type="monotone" dataKey="totalUsers" stroke="#82ca9d" />
        <Line type="monotone" dataKey="totalLocations" stroke="#ffc658" />
      </LineChart>
    </div>
  );
};

export default SuperAdminDashboard;