import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export default function Pricing() {
  const { t } = useTranslation();
  const tiers = [
    {
      title: 'Basic',
      price: '50',
      description: [
       t('150assets'),
       t('50userIncluded'),
       t('30snt'),
       t('firstMonthFree')
      ],
      buttonText: t('Sign up'),
      buttonVariant: 'outlined',
      buttonColor: 'primary',
    },
    {
      title: 'Standard',
      price: '100',
      description: [
        t('400assets'),
        t('100usersIncluded'),
        t('30snt'),
        t('firstMonthFree')
      ],
      buttonText: t('Sign up'),
      buttonVariant: 'contained',
      buttonColor: 'secondary',
    },
    {
      title: 'Enterprise',
      price: '200',
      description: [
        t('unlimitedUsers'),
        t('100assets'),
        t('30snt'),
        t('firstMonthFree')
      ],
      buttonText: t('Sign up'),
      buttonVariant: 'outlined',
      buttonColor: 'primary',
    },
  ];

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          {t("Pricing")}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {t("pricingText")}
        </Typography>
      </Box>
      <Grid container spacing={3} sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        {tiers.map((tier) => (
          <Grid
            item
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
            key={tier.title}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                ...(tier.title === 'Standard' && {
                  border: 'none',
                  background: 'linear-gradient(180deg, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                  boxShadow: '0 8px 12px hsla(220, 20%, 42%, 0.2)',
                }),
              }}
            >
              <CardContent>
                <Box sx={{
                  mb: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  color: tier.title === 'Standard' ? 'grey.100' : 'inherit',
                }}>
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  color: tier.title === 'Standard' ? 'grey.50' : 'inherit',
                }}>
                  <Typography component="h3" variant="h2">
                    {tier.price}€
                  </Typography>
                  <Typography component="span" variant="h6">
                    &nbsp; {t("per month")}
                  </Typography>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: tier.title === 'Standard' ? 'primary.light' : 'primary.main',
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: tier.title === 'Standard' ? 'grey.50' : 'inherit',
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Link
                  href="/login"
                  sx={{
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                    color={tier.buttonColor as 'primary' | 'secondary'}
                  >
                    {tier.buttonText}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}