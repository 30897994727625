import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase-config';
import ObservationTypeForm from './ObservationTypeForm';
import { ObservationType, UserType, ObservationTypeTemplate } from './types';
import { useTranslation } from 'react-i18next';
import templateData from './observationTypeTemplates.json';
import './ObservationTypeManagement.css';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextField from '@mui/material/TextField';

interface ObservationTypeManagementProps {
  user: UserType;
}

const ObservationTypeManagement: React.FC<ObservationTypeManagementProps> = ({ user }) => {
  const [observationTypes, setObservationTypes] = useState<ObservationType[]>([]);
  const [selectedObservationType, setSelectedObservationType] = useState<ObservationType | null>(null);
  const [groupedTemplates, setGroupedTemplates] = useState<Record<string, ObservationTypeTemplate[]>>({});
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const groupTemplatesByCategory = (templates: ObservationTypeTemplate[]) => {
    return templates.reduce((acc, template) => {
      if (!acc[template.category]) {
        acc[template.category] = [];
      }
      acc[template.category].push(template);
      return acc;
    }, {} as Record<string, ObservationTypeTemplate[]>);
  };

  useEffect(() => {
    fetchObservationTypes();
    setGroupedTemplates(groupTemplatesByCategory(templateData.templates));
  }, [user]);

  const fetchObservationTypes = async () => {
    if (!user.organization) {
      console.warn("No user organization found");
      return;
    }
    const observationTypesQuery = query(collection(db, "observationTypes"), where("organization", "==", user.organization));
    const observationTypesSnapshot = await getDocs(observationTypesQuery);
    const fetchedObservationTypes = observationTypesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as ObservationType));
    setObservationTypes(fetchedObservationTypes);
  };

  const handleComplete = () => {
    fetchObservationTypes();
    setSelectedObservationType(null);
  };

  const handleTemplateSelect = (template: ObservationTypeTemplate) => {
    setSelectedObservationType({
      id: '',
      typeName: template.name,
      organization: user.organization,
      attributes: template.attributes,
    } as ObservationType);
  };

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category) 
        : [...prev, category]
    );
  };

  const renderTree = (nodes: Record<string, ObservationTypeTemplate[]>) => {
    return Object.entries(nodes).map(([category, templates]) => (
      <TreeItem key={category} itemId={category} label={`${category} (${templates.length})`}>
        {templates
          .filter(template => 
            template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            category.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((template) => (
            <TreeItem
              key={template.id}
              itemId={template.id}
              label={template.name}
              onClick={() => handleTemplateSelect(template)}
            />
          ))}
      </TreeItem>
    ));
  };


  return (
    <div className="container">
      <h1 className="page-title">{t('Observation Type Management')}</h1>
      <div className="observation-type-management">
        <div className="template-list card">
          <h2>{t('Templates')}</h2>
          <TextField
            label={t('Search templates')}
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <SimpleTreeView
            aria-label="observation type templates"
            defaultExpandedItems={[]}
            expandedItems={expandedItems}
            onExpandedItemsChange={(event, itemIds) => setExpandedItems(itemIds)}
            slots={{
              collapseIcon: ExpandMoreIcon,
              expandIcon: ChevronRightIcon,
            }}
          >
            {renderTree(groupedTemplates)}
          </SimpleTreeView>
        </div>
        <div className="observation-type-form card">
          <h2>{t("Select Observation Type")}</h2>
          <select 
            className="select-input"
            onChange={(e) => {
              const selected = observationTypes.find(type => type.id === e.target.value);
              setSelectedObservationType(selected || null);
            }}
            value={selectedObservationType?.id || ''}
          >
            <option value="">{t('Create New Observation Type')}</option>
            {observationTypes.map(type => (
              <option key={type.id} value={type.id}>{type.typeName}</option>
            ))}
          </select>
          <h2>{selectedObservationType ? t('Edit Observation Type') : t('Create New Observation Type')}</h2>
          <ObservationTypeForm
            user={user}
            existingObservationType={selectedObservationType}
            onComplete={handleComplete}
          />
        </div>
      </div>
    </div>
  );
};


export default ObservationTypeManagement;