import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams, ValueFormatterParams, GridReadyEvent } from 'ag-grid-community';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc, addDoc, DocumentReference, getDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import { ObservationType, Observation, UserType, Asset } from './types';
import ObservationModal from './ObservationModal';
import { useTranslation } from 'react-i18next';
import './ObservationListPage.css';
import './global.css';

interface ObservationsListPageProps {
  user: UserType;
}

const ObservationsListPage: React.FC<ObservationsListPageProps> = ({ user }) => {
  const [observationTypes, setObservationTypes] = useState<ObservationType[]>([]);
  const [selectedObservationType, setSelectedObservationType] = useState<string>('');
  const [observations, setObservations] = useState<Observation[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedObservations, setSelectedObservations] = useState<Observation[]>([]);
  const [currentObservationIndex, setCurrentObservationIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const { t } = useTranslation();
  const [asset, setAsset] = useState<Asset | null>(null);
  const gridApiRef = useRef<any>(null);
  const pageSize = 20;

  useEffect(() => {
    fetchObservationTypes();
  }, [user]);

  useEffect(() => {
    if (selectedObservationType) {
      const selectedType = observationTypes.find(t => t.id === selectedObservationType);
      if (selectedType) {
        const initialVisibleColumns = [
          'assetData.id',
          'noted',
          'timestamp',
          'assetData.assetType',
          ...selectedType.attributes.map(attr => `data.${attr.name}`),
          'location',
          'daysOld',
          'actions'
        ];
        setVisibleColumns(initialVisibleColumns);
      }
      fetchObservations();
    }
  }, [selectedObservationType, observationTypes]);

  const fetchObservationTypes = async () => {
    try {
      const q = query(collection(db, "observationTypes"), where("organization", "==", user.organization));
      const querySnapshot = await getDocs(q);
      const types = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as ObservationType));
      setObservationTypes(types);
    } catch (error) {
      console.error("Error fetching observation types:", error);
    }
  };

  const fetchObservations = useCallback(async () => {
    if (!selectedObservationType) return;
    setLoading(true);
    try {
      const q = query(
        collection(db, "observations"),
        where("organization", "==", user.organization),
        where("type", "==", doc(db, 'observationTypes', selectedObservationType))
      );
      const querySnapshot = await getDocs(q);
  
      const obs = await Promise.all(querySnapshot.docs.map(async (obsDoc) => {
        const observation = { id: obsDoc.id, ...obsDoc.data() } as Observation;
  
        if (observation.asset && observation.asset instanceof DocumentReference) {
          try {
            const assetDoc = await getDoc(observation.asset);
            if (assetDoc.exists()) {
              const assetData = assetDoc.data() as Asset;
            const assetTypeRef = assetData.assetType;
            const assetTypeDoc = await getDoc(assetTypeRef);
            const assetTypeData = assetTypeDoc.data();
            observation.assetData = {
              ...assetData,
              assetTypeData: {
                id: assetTypeRef.id,
                typeName: assetTypeData?.typeName || 'Unknown Type'
              }
            };
            } else {
              console.warn(`Asset document not found for observation ${obsDoc.id}`);
            }
          } catch (error) {
            console.error(`Error fetching asset for observation ${obsDoc.id}:`, error);
          }
        } else {
          console.warn(`Invalid or missing assetRef in observation ${obsDoc.id}`);
        }
  
        return observation;
      }));
      setObservations(obs);
    } catch (error) {
      console.error("Error fetching observations:", error);
    } finally {
      setLoading(false);
    }
  }, [user.organization, selectedObservationType]);

  const handleDeleteObservation = useCallback((id: string) => async () => {
    if (!editMode) return;
    try {
      await deleteDoc(doc(db, 'observations', id));
      setObservations(observations.filter(obs => obs.id !== id));
    } catch (error) {
      console.error("Error deleting observation:", error);
    }
  }, [observations, editMode]);

  const handleObservationUpdated = async (observationId: string, noted: boolean) => {
    const updatedObservations = observations.map(obs =>
      obs.id === observationId ? { ...obs, noted } : obs
    );
    setObservations(updatedObservations);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const openObservationModal = (observation: Observation) => {
    setSelectedObservations([observation]);
    setCurrentObservationIndex(0);
    setIsModalOpen(true);
  };

  const closeObservationModal = () => {
    setSelectedObservations([]);
    setCurrentObservationIndex(0);
    setIsModalOpen(false);
  };

  const handleNextObservation = () => {
    setCurrentObservationIndex((prevIndex) => 
      (prevIndex + 1) % selectedObservations.length
    );
  };
  
  const handlePreviousObservation = () => {
    setCurrentObservationIndex((prevIndex) => 
      (prevIndex - 1 + selectedObservations.length) % selectedObservations.length
    );
  };

  const columnDefs = useMemo<ColDef<Observation>[]>(() => {
    const baseColumns: ColDef<Observation>[] = [
      { 
        field: 'assetData.id', 
        headerName: t('Asset ID'),
        valueGetter: (params: ValueGetterParams<Observation>) => {
          return params.data?.assetData?.id || '';
        },
        cellRenderer: (params: { data: Observation }) => (
          <span 
            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            onClick={() => params.data && openObservationModal(params.data)}
          >
            {params.data?.assetData?.id || ''}
          </span>
        )
      },
      { 
        field: 'noted',
        headerName: t('Noted'),
        valueGetter: (params: ValueGetterParams<Observation>) => {
          return params.data?.noted;
        },
        cellRenderer: (params: { data: Observation }) => (
          <input
            type="checkbox"
            checked={params.data?.noted || false}
            onChange={() => {
              if (params.data) {
                params.data.noted = !params.data.noted;
                handleCellValueChanged(params);
              }
            }}
            disabled={!editMode}
          />
        )
      },
      { 
        field: 'timestamp', 
        headerName: t('Timestamp'), 
        valueFormatter: (params: ValueFormatterParams<Observation>) => 
          params.value?.toDate().toLocaleString() || ''
      },
      { 
        field: 'location', 
        headerName: t('Location'),
        valueGetter: (params: ValueGetterParams<Observation>) => {
          const location = params.data?.location;
          return location ? `${location.latitude}, ${location.longitude}` : '';
        }
      },
      { 
        field: 'assetData.assetType', 
        headerName: t('Asset Type'),
        valueGetter: (params: ValueGetterParams<Observation>) => {
          return params.data?.assetData?.assetTypeData?.typeName || t('Unknown Type');
        },
      },
      { 
        headerName: t('Days Old'), 
        valueGetter: (params: ValueGetterParams<Observation>) => {
          if (!params.data?.timestamp) return '';
          const diffTime = Math.abs(new Date().getTime() - params.data.timestamp.toDate().getTime());
          return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
      },
      { 
        headerName: t('Actions'), 
        cellRenderer: (params: { data: Observation }) => (
          <button 
            className="action-button delete-button"
            onClick={() => params.data && handleDeleteObservation(params.data.id)()}
            disabled={!editMode}
          >
            Delete
          </button>
        )
      }
    ];
  
    const selectedType = observationTypes.find(t => t.id === selectedObservationType);
    const attributeColumns: ColDef<Observation>[] = selectedType?.attributes.map(attr => ({
      field: `data.${attr.name}`,
      headerName: attr.name,
      valueGetter: (params: ValueGetterParams<Observation>) => {
        return params.data?.data?.[attr.name];
      },
      editable: editMode,
      cellEditor: attr.type === 'enum' ? 'agSelectCellEditor' : 
                    attr.type === 'boolean' ? 'agSelectCellEditor' : 'agTextCellEditor',
      cellEditorParams: attr.type === 'enum' ? { values: attr.options || [] } :
                        attr.type === 'boolean' ? { values: ['0', '1'] } : undefined,
      valueFormatter: attr.type === 'boolean' ? 
        (params: ValueFormatterParams) => params.value === '1' ? 'True' : 'False' : undefined,
    })) || [];
  
    const allColumns = [...baseColumns.slice(0, 2), ...attributeColumns, ...baseColumns.slice(2)];
  
    return allColumns.map(col => ({
      ...col,
      hide: col.field ? !visibleColumns.includes(col.field) : false
    }));
  }, [selectedObservationType, observationTypes, openObservationModal, handleDeleteObservation, editMode, visibleColumns, t]);
  

  const defaultColDef = useMemo<ColDef>(() => ({
    sortable: true,
    filter: true,
    resizable: true
  }), []);

  const extractRelevantFields = (observation: Observation, attributes: any) => {
    const updatedData: any = {};

    attributes.forEach((attr: any) => {
      if (observation.data[attr.name] !== undefined) {
        updatedData[attr.name] = observation.data[attr.name];
      }
    });

    return { data: updatedData, noted: observation.noted };
  };

  const handleCellValueChanged = useCallback(async (params: any) => {
    const selectedType = observationTypes.find(t => t.id === selectedObservationType);
    if (selectedType) {
      const updatedFields = extractRelevantFields(params.data, selectedType.attributes);

      try {
        await updateDoc(doc(db, 'observations', params.data.id), updatedFields);
        console.log('Observation updated successfully:', updatedFields);

        if (gridApiRef.current) {
          const rowNode = params.node;
          if (rowNode) {
            gridApiRef.current.refreshCells({ rowNodes: [rowNode] });
          }
        }
      } catch (error) {
        console.error('Error updating observation:', error);
      }
    }
  }, [observationTypes, selectedObservationType]);
  
  const ColumnVisibilityFilter: React.FC<{
    columns: ColDef[];
    visibleColumns: string[];
    toggleColumnVisibility: (field: string) => void;
  }> = ({ columns, visibleColumns, toggleColumnVisibility }) => {
    return (
      <div className="column-visibility-filter">
        <h4>{t('Column Visibility')}</h4>
        {columns.filter(col => col.field && col.field !== 'actions').map(col => {
          const field = col.field || '';
          return (
            <label key={field || col.headerName}> 
              <input
                type="checkbox"
                checked={visibleColumns.includes(field)}
                onChange={() => toggleColumnVisibility(field)}
              />
              {col.headerName}
            </label>
          );
        })}
      </div>
    );
  };

  const toggleColumnVisibility = (field: string) => {
    setVisibleColumns(prev => {
      if (prev.includes(field)) {
        return prev.filter(col => col !== field);
      } else {
        return [...prev, field];
      }
    });
  };

  return (
    <div className="observations-list-container">
      <h1>{t('Observations List')}</h1>
      <select className="observation-type-selector" onChange={(e) => setSelectedObservationType(e.target.value)} value={selectedObservationType}>
        <option value="">{t('Select Observation Type')}</option>
        {observationTypes.map(type => (
          <option key={type.id} value={type.id}>{type.typeName}</option>
        ))}
      </select>
      <button className="btn edit-button" onClick={toggleEditMode}>
        {editMode ? t('Disable Editing') : t('Enable Editing')}
      </button>

      <ColumnVisibilityFilter
        columns={columnDefs}
        visibleColumns={visibleColumns}
        toggleColumnVisibility={toggleColumnVisibility}
      />
      {loading ? (
        <p>{t('Loading...')}</p>
      ) : (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact<Observation>
            rowData={observations}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={(params: GridReadyEvent) => {
              gridApiRef.current = params.api;
              params.api.sizeColumnsToFit();
            }}
            onCellValueChanged={handleCellValueChanged}
            getRowId={(params: any) => params.data.id}
            pagination={true}
            paginationPageSize={pageSize}
            paginationAutoPageSize={false}
            suppressPaginationPanel={false}
          />
        </div>
      )}
      {selectedObservations.length > 0 && (
        <ObservationModal
          isOpen={isModalOpen}
          onRequestClose={closeObservationModal}
          observation={selectedObservations[currentObservationIndex]}
          user={user}
          onNext={handleNextObservation}
          onPrevious={handlePreviousObservation}
          totalObservations={selectedObservations.length}
          currentIndex={currentObservationIndex}
          onObservationUpdated={handleObservationUpdated}
        />
      )}
    </div>
  );
};

export default ObservationsListPage;