import React, { useState, useEffect } from 'react';
import { db, app } from './firebase-config';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, addDoc, deleteDoc, setDoc, collection, query, where, getDocs,DocumentReference, DocumentSnapshot, DocumentData } from 'firebase/firestore';
import { TextField, Button, RadioGroup, FormControlLabel, Radio, Box, CircularProgress, Switch, Typography, 
    Checkbox, 
    List, 
    ListItem, 
    ListItemText,
    IconButton} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ButtonInfoForm from './ButtonInfoForm';
import { UserType} from './types';
import { useNavigate } from 'react-router-dom';


const storage = getStorage(app, "gs://effiasset-d51d6-public-data");

interface PublicPageSettings {
  headerType: 'text' | 'image';
  headerText: string;
  headerImageUrl: string;
  footerText: string;
  name: string;
  contactInfo: {
    phone: string;
    email: string;
    address: string;
  };
  logoUrl: string;
  organization?: DocumentReference; 
  showNotificationButton: boolean;
  notificationButtonText: string;
  notificationUsers: string[];
}

interface PublicPageSettingsFormProps {
    user: UserType;
  }

const defaultSettings: PublicPageSettings = {
  headerType: 'text',
  headerText: '',
  headerImageUrl: '',
  footerText: '',
  name:"",
  contactInfo: { phone: '', email: '', address: '' },
  logoUrl: '',
  showNotificationButton: false,
  notificationButtonText: '',
  notificationUsers: [],  // Add this line
};

const PublicPageSettingsForm: React.FC<PublicPageSettingsFormProps> = ({ user }) => {
  const [settings, setSettings] = useState<PublicPageSettings>({ ...defaultSettings });
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [adminUsers, setAdminUsers] = useState<{ id: string; email: string }[]>([]);
  const [buttons, setButtons] = useState<any[]>([]);
  const [showButtonForm, setShowButtonForm] = useState(false);
  const navigate = useNavigate();
  const [buttonToEdit, setButtonToEdit] = useState<any | null>(null);
  const [enableVCardDownload, setEnableVCardDownload] = useState(false);

  

  useEffect(() => {
    const logs = sessionStorage.getItem('debugLogs');
    if (logs) {
      console.log('Debug logs:', logs);
      sessionStorage.removeItem('debugLogs');
    }
  }, []);

  const logDebug = (message: string) => {
    const logs = sessionStorage.getItem('debugLogs') || '';
    sessionStorage.setItem('debugLogs', logs + '\n' + message);
  };

  useEffect(() => {
    const fetchSettingsAndUsers = async () => {
        console.log('User:', user);
        setLoading(true);
        try {
          // Fetch existing settings
          const settingsDoc = await getDoc(doc(db, 'publicPageSettings', user.organization.id));
          if (settingsDoc.exists()) {
            const fetchedSettings = settingsDoc.data() as PublicPageSettings;
            setSettings(prevSettings => ({
              ...prevSettings,
              ...fetchedSettings,
              notificationUsers: fetchedSettings.notificationUsers || []  // Ensure notificationUsers is always an array
            }));
          }
      
          // Fetch admin users
          const usersQuery = query(
            collection(db, 'users'),
            where('organization', '==', user.organization),
            where('role', '==', 'admin')
          );
          const usersSnapshot = await getDocs(usersQuery);
          const adminUsersList = usersSnapshot.docs.map(doc => ({
            id: doc.id,
            email: doc.data().email
          }));
          setAdminUsers(adminUsersList);
        } catch (error) {
          console.error('Error fetching settings and users:', error);
        }
        setLoading(false);
      };

    fetchSettingsAndUsers();
    fetchButtons();
  }, [user.organization]);

  const fetchButtons = async () => {
    const buttonsQuery = query(collection(db, 'buttonInfo'), where('organization', '==', user.organization));
    const buttonsSnapshot = await getDocs(buttonsQuery);
    const fetchedButtons = await Promise.all(buttonsSnapshot.docs.map(async (doc) => {
      const data = doc.data();
      let assetTypeName = 'Unknown';
      if (data.assetType && typeof data.assetType.get === 'function') {
        try {
          const assetTypeDoc = await getDoc(data.assetType) as DocumentSnapshot<DocumentData>;
          if (assetTypeDoc.exists()) {
            const assetTypeData = assetTypeDoc.data();
            assetTypeName = (assetTypeData && 'typeName' in assetTypeData) ? assetTypeData.typeName : 'Unknown';
          }
        } catch (error) {
          console.error('Error fetching asset type:', error);
        }
      }
      return { id: doc.id, ...data, assetTypeName };
    }));
    setButtons(fetchedButtons);
  };


  const handleEditButton = (button: any) => {
    setButtonToEdit(button);
    setShowButtonForm(true);
  };

  const handleAddButton = async (buttonData: any) => {
    await fetchButtons(); // Refetch buttons to get the updated list
    setShowButtonForm(false);
    setButtonToEdit(null);
  };

  const handleDeleteButton = async (buttonId: string) => {
    setButtons(prevButtons => prevButtons.filter(button => button.id !== buttonId));
    await deleteDoc(doc(db, 'buttonInfo', buttonId));
  };

  const handleUserSelection = (userId: string) => {
    setSettings(prev => ({
      ...prev,
      notificationUsers: prev.notificationUsers.includes(userId)
        ? prev.notificationUsers.filter(id => id !== userId)
        : [...prev.notificationUsers, userId]
    }));
  };

  const handleSelectAllUsers = () => {
    setSettings(prev => ({
      ...prev,
      notificationUsers: prev.notificationUsers.length === adminUsers.length
        ? []
        : adminUsers.map(user => user.id)
    }));
  };

  useEffect(() => {
    const fetchOrganizationAndSettings = async () => {
      setLoading(true);
      if (!user) {
        console.error('No user logged in');
        setLoading(false);
        return;
      }
  
      try {
        const organizationRef = user.organization;
  
        if (!organizationRef) {
          console.error('User has no associated organization');
          setLoading(false);
          return;
        }
  
        const orgId = organizationRef.id;
        setOrganizationId(orgId);
  
        // Fetch public page settings
        const settingsDoc = await getDoc(doc(db, 'publicPageSettings', orgId));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data() as Partial<PublicPageSettings>;
          setSettings(prevSettings => ({
            ...prevSettings,
            ...data,
            contactInfo: {
              ...prevSettings.contactInfo,
              ...data.contactInfo
            },
            organization: organizationRef
          }));
        } else {
          // If no settings exist yet, set the organization reference
          setSettings(prevSettings => ({
            ...prevSettings,
            organization: organizationRef
          }));
        }
      } catch (error) {
        console.error('Error fetching organization and settings:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrganizationAndSettings();
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({ 
      ...prev, 
      [name]: type === 'checkbox' ? checked : value 
    }));
  };

  const handleContactInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      contactInfo: { ...prev.contactInfo, [name]: value },
    }));
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, field: 'headerImageUrl' | 'logoUrl') => {
    const file = e.target.files?.[0];
    if (file && organizationId) {
      const storageRef = ref(storage, `publicPageImages/${organizationId}/${field}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setSettings(prev => ({ ...prev, [field]: url }));
    }
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!organizationId) {
      console.error('No organization ID available');
      return;
    }
    setLoading(true);
    try {
      console.log('Saving settings:', settings);
      await setDoc(doc(db, 'publicPageSettings', organizationId), {
        ...settings,
        enableVCardDownload: enableVCardDownload,
        notificationUsers: settings.notificationUsers || []
      });
      console.log('Public page settings saved successfully');
      alert(t('Public page settings saved successfully'));
    } catch (error) {
      console.error('Error saving public page settings:', error);
      alert(t('Error saving public page settings'));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!organizationId) {
    return <Box>{t('No organization associated with this account')}</Box>;
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <RadioGroup
        name="headerType"
        value={settings.headerType}
        onChange={handleChange}
      >
        <FormControlLabel value="text" control={<Radio />} label={t('Text Header')} />
        <FormControlLabel value="image" control={<Radio />} label={t('Image Header')} />
      </RadioGroup>

      {settings.headerType === 'text' ? (
        <TextField
          fullWidth
          name="headerText"
          label={t('Header Text')}
          value={settings.headerText}
          onChange={handleChange}
          margin="normal"
        />
      ) : (
        <Button
          variant="contained"
          component="label"
        >
          {t('Upload Header Image')}
          <input
            type="file"
            hidden
            onChange={(e) => handleImageUpload(e, 'headerImageUrl')}
          />
        </Button>
      )}
      <TextField
        fullWidth
        name="name"
        label={t('Name')}
        value={settings.name}
        onChange={handleChange}
        margin="normal"
      />

      <TextField
        fullWidth
        name="footerText"
        label={t('Company name')}
        value={settings.footerText}
        onChange={handleChange}
        margin="normal"
      />

      <TextField
        fullWidth
        name="phone"
        label={t('Contact Phone')}
        value={settings.contactInfo.phone}
        onChange={handleContactInfoChange}
        margin="normal"
      />

      <TextField
        fullWidth
        name="email"
        label={t('Contact Email')}
        value={settings.contactInfo.email}
        onChange={handleContactInfoChange}
        margin="normal"
      />

      <TextField
        fullWidth
        name="address"
        label={t('Contact Address')}
        value={settings.contactInfo.address}
        onChange={handleContactInfoChange}
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={enableVCardDownload}
            onChange={(e) => setEnableVCardDownload(e.target.checked)}
            name="enableVCardDownload"
          />
        }
        label={t("Enable vCard download")}
      />
      <Button
        variant="contained"
        component="label"
        sx={{ mt: 2, mb: 2 }}
      >
        {t('Upload Logo')}
        <input
          type="file"
          hidden
          onChange={(e) => handleImageUpload(e, 'logoUrl')}
        />
      </Button>
      <Button type="button" onClick={handleSubmit} variant="contained" color="primary">
        {t('Save Settings')}
    </Button>

    <Typography variant="h6" sx={{ mt: 4 }}>{t('Custom Buttons')}</Typography>
      <Button onClick={() => { setShowButtonForm(true); setButtonToEdit(null); }}>
        {t('Add New Button')}
      </Button>
      
      {showButtonForm && (
        <ButtonInfoForm 
          user={user} 
          onSubmit={handleAddButton} 
          onCancel={() => { setShowButtonForm(false); setButtonToEdit(null); }}
          buttonToEdit={buttonToEdit}
        />
      )}
      
      <List>
        {buttons.map((button) => (
          <ListItem key={button.id} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEditButton(button)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteButton(button.id)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText 
              primary={button.buttonText} 
              secondary={`${t('Asset Type')}: ${button.assetTypeName}, ${t('Notification')}: ${button.notificationTitle}`} 
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};


export default PublicPageSettingsForm;