import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { getNotifications, markNotificationAsRead } from './notificationService';
import { Notification, UserType, Asset } from './types';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import AssetModal from './AssetModal';
import {  getDoc,  updateDoc, doc, DocumentReference, arrayUnion } from 'firebase/firestore';
import { db } from './firebase-config';
import Modal from 'react-modal';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

Modal.setAppElement('#root');


interface NotificationsListPageProps {
  user: UserType;
}

const NotificationsListPage: React.FC<NotificationsListPageProps> = ({ user }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<string[]>([]);

  const pageSize = 20;

  useEffect(() => {
    fetchNotifications();
  }, [user, page]);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const fetchedNotifications = await getNotifications(user.organization, user.uid, page, pageSize);
      setNotifications(prevNotifications => [...prevNotifications, ...fetchedNotifications]);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClick = async (notification: Notification) => {
    if (!notification.read) {
      await markNotificationAsRead(notification.id);
      setNotifications(prevNotifications =>
        prevNotifications.map(n =>
          n.id === notification.id ? { ...n, read: true } : n
        )
      );
    }
    if (notification.link) {
      window.open(notification.link, '_blank');
    }
  };

  const handleAssetClick = async (assetRef: DocumentReference, assetManufacturerId: string) => {
    try {
      const assetDoc = await getDoc(assetRef);
      if (assetDoc.exists()) {
        setSelectedAsset({ 
          id: assetManufacturerId, // Use the manufacturer ID here
          firebaseId: assetDoc.id, // Add this line to include the Firestore document ID
          ...assetDoc.data() 
        } as Asset);
        setIsModalOpen(true);
      } else {
        console.error('Asset not found:', assetRef.path);
      }
    } catch (error) {
      console.error('Error fetching asset:', error);
    }
  };

  
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAsset(null);
  };

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleRefresh = () => {
    setNotifications([]);
    setPage(1);
    fetchNotifications();
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedAsset || !event.target.files || event.target.files.length === 0) return;
  
    const file = event.target.files[0];
    const storage = getStorage();
    const orgRef = user.organization as DocumentReference;
    const orgDoc = await getDoc(orgRef);
    // Create a URL-friendly version of the organization name
  
    const safeOrgName = orgDoc.id;
    const storageRef = ref(storage, `asset_photos/${safeOrgName}/${selectedAsset.firebaseId}/${Date.now()}_${file.name}`);
    console.log("Uploading to path:", storageRef.fullPath);
    console.log("User organization ID:", safeOrgName);
    console.log("Asset firebaseId:", selectedAsset.firebaseId);
  
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
  
      const assetRef = doc(db, 'assets', selectedAsset.firebaseId);
      await updateDoc(assetRef, {
        photoURLs: [...(selectedAsset.photoURLs || []), downloadURL]
      });
  
      // Update local state
      setSelectedAsset({
        ...selectedAsset,
        photoURLs: [...(selectedAsset.photoURLs || []), downloadURL]
      });
  
      alert("Image uploaded successfully!");
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('storage/unauthorized')) {
          alert("You don't have permission to upload images. Please check your account permissions.");
        } else {
          console.error("Error uploading file: ", error);
          alert(`Failed to upload image: ${error.message}`);
        }
      } else {
        console.error("An unknown error occurred", error);
        alert("An unknown error occurred. Please try again.");
      }
    }
  };

  const handleDocumentUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedAsset || !event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];
    const fileType = file.type;
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];

    if (!validTypes.includes(fileType)) {
      alert(t("Only PDF and image files are allowed"));
      return;
    }

    const storage = getStorage();
    const orgRef = user.organization as DocumentReference;
    const orgDoc = await getDoc(orgRef);
    const safeOrgName = orgDoc.id;

    const storageRef = ref(storage, `documents/${safeOrgName}/${selectedAsset.firebaseId}/${Date.now()}_${file.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const assetRef = doc(db, 'assets', selectedAsset.firebaseId);
      await updateDoc(assetRef, {
        documentURLs: arrayUnion(downloadURL)
      });

      // Update local state
      setSelectedAsset({
        ...selectedAsset,
        documentURLs: [...(selectedAsset.documentURLs || []), downloadURL]
      });

      setDocuments([...documents, downloadURL]);

      alert(t("Document uploaded successfully!"));
    } catch (error) {
      console.error("Error uploading document: ", error);
      alert(t("Failed to upload document. Please try again."));
    }
  };

  const openDocument = (url: string) => {
    window.open(url, '_blank');
  };


  const columnDefs: ColDef[] = [
    { 
      field: 'assetManufacturerId', 
      headerName: t('Asset ID'),
      cellRenderer: (params: any) => (
        <a href="#" onClick={(e) => {
          e.preventDefault();
          handleAssetClick(params.data.assetId, params.data.assetManufacturerId);
        }}>
          {params.data.assetManufacturerId}
        </a>
      )
    },
    { field: 'title', headerName: t('Title') },
    { field: 'body', headerName: t('Message') },
    { field: 'notificationType', headerName: t('Type') },
    {
      field: 'createdAt',
      headerName: t('Created At'),
      valueFormatter: (params) => params.value.toDate().toLocaleString(),
    },
    {
      field: 'read',
      headerName: t('Read'),
      cellRenderer: (params: any) => params.value ? '✓' : '✗',
    },
  ];



  return (
    <div className="notifications-list-container">
      <h1>{t('Notifications')}</h1>
      <Button onClick={handleRefresh}>{t('Refresh')}</Button>
      {loading && <CircularProgress />}
      <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
        <AgGridReact
          rowData={notifications}
          columnDefs={columnDefs}
          onRowClicked={(event) => handleNotificationClick(event.data)}
        />
      </div>
      {notifications.length >= page * pageSize && (
        <Button onClick={handleLoadMore}>{t('Load More')}</Button>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Asset Details"
        className="asset-modal"
        overlayClassName="asset-modal-overlay"
        style={{
          content: {
            width: '90%',
            maxWidth: '1200px',
            margin: 'auto',
            height: '90%',
            overflow: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        {selectedAsset && (
          <AssetModal
            asset={selectedAsset}
            closeModal={closeModal}
            handleFileUpload={handleFileUpload}
            handleDocumentUpload={handleDocumentUpload}
            openDocument={openDocument}
            t={t}
            user={user}
          />
        )}
      </Modal>
    </div>
  );
};



export default NotificationsListPage;