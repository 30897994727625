import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Paper, Typography, List, ListItem, ListItemText } from '@mui/material';

const TermsOfService: React.FC = () => {
  const { t } = useTranslation();

  const renderParagraphWithLineBreaks = (textArray: string | string[]) => {
    if (typeof textArray === 'string') {
      return textArray;
    }

    return textArray.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: '2rem', marginTop: '2rem', marginBottom: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          {t('terms_of_service.title')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.general.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('terms_of_service.sections.general.content')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.description.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('terms_of_service.sections.description.content')}
        </Typography>

        {/* Basic Plan */}
        <Typography variant="h6" gutterBottom>
          {t('terms_of_service.sections.description.basic_plan.title')}
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.basic_plan.assets')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.basic_plan.price')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.basic_plan.extra_charge')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.basic_plan.users')} />
          </ListItem>
        </List>

        {/* Standard Plan */}
        <Typography variant="h6" gutterBottom>
          {t('terms_of_service.sections.description.standard_plan.title')}
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.standard_plan.assets')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.standard_plan.price')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.standard_plan.extra_charge')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.standard_plan.users')} />
          </ListItem>
        </List>

        {/* Enterprise Plan */}
        <Typography variant="h6" gutterBottom>
          {t('terms_of_service.sections.description.enterprise_plan.title')}
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.enterprise_plan.assets')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.enterprise_plan.price')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.enterprise_plan.extra_charge')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t('terms_of_service.sections.description.enterprise_plan.users')} />
          </ListItem>
        </List>

        {/* Other sections */}
        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.terms_of_use.title')}
        </Typography>
        <Typography variant="body1" paragraph>
        {renderParagraphWithLineBreaks(t('terms_of_service.sections.terms_of_use.content', { returnObjects: true }) as string[])}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.payments.title')}
        </Typography>
        <Typography variant="body1" paragraph>
        {renderParagraphWithLineBreaks(t('terms_of_service.sections.payments.content', { returnObjects: true }) as string[])}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.support.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('terms_of_service.sections.support.content')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.termination.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('terms_of_service.sections.termination.content')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.liability.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('terms_of_service.sections.liability.content')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.changes.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('terms_of_service.sections.changes.content')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.law.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('terms_of_service.sections.law.content')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('terms_of_service.sections.contact.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {renderParagraphWithLineBreaks(t('terms_of_service.sections.contact.content', { returnObjects: true }) as string[])}
        </Typography>
      </Paper>
    </Container>
  );
};

export default TermsOfService;
