import React, { useState } from 'react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { db } from './firebase-config';
import { UserType } from './types';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

interface CreateBlogPostProps {
  user: UserType;
}

const CreateBlogPost: React.FC<CreateBlogPostProps> = ({ user }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const navigate = useNavigate();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const storage = getStorage();

    let imageUrl = '';
    if (image) {
      const imageRef = ref(storage, `blog-images/${Date.now()}_${image.name}`);
      await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(imageRef);
    }

    const docRef = await addDoc(collection(db, 'blogPosts'), {
      title,
      content,
      imageUrl,
      date: Timestamp.now(),
      author: user.email,
    });

    navigate(`/blog/${docRef.id}`);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Create New Blog Post</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          multiline
          rows={10}
          margin="normal"
          required
        />
        <Box sx={{ my: 2 }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              Upload Image
            </Button>
          </label>
          {image && <Typography variant="body2" sx={{ mt: 1 }}>{image.name}</Typography>}
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Create Post
        </Button>
      </form>
    </Container>
  );
};

export default CreateBlogPost;