
import { getMessaging, getToken, onMessage, MessagePayload } from "firebase/messaging";
import { app } from './firebase-config'; // Ensure this import path is correct

// Initialize Firebase
//const app = initializeApp(app);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

// Your VAPID key here (you got this from Firebase Console)
const VAPID_KEY = 'BKgktEh0VRBHM2bUnbPZuW63kfizi5WjnOv2TQ03Qx3hKLPG6jkCitM4yLDI61o5t7Tw8vZKTXuctv3D9tgmOJo';

export const requestNotificationPermission = async (): Promise<string | null> => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      const token = await getToken(messaging, { vapidKey: VAPID_KEY });
      if (token) {
        console.log('FCM Token:', token);
        return token;
      } else {
        console.log('No registration token available. Request permission to generate one.');
        return null;
      }
    } else {
      console.log('Unable to get permission to notify.');
      return null;
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
    return null;
  }
};

export const onMessageListener = () =>
  new Promise<MessagePayload>((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

  export const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      try {
        console.log('Attempting to register service worker...');
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered with scope:', registration.scope);
        
        // Wait for the service worker to be ready
        await navigator.serviceWorker.ready;
        console.log('Service Worker is ready');
  
        // Check if the service worker is active
        if (registration.active) {
          console.log('Service Worker is active');
        } else {
          console.log('Service Worker is not active');
        }
  
        // Attempt to get the Service Worker script
        const response = await fetch('/firebase-messaging-sw.js');
        const contentType = response.headers.get('content-type');
        console.log('Service Worker script content type:', contentType);
        
        if (!contentType || !contentType.includes('javascript')) {
          console.error('Service Worker script has incorrect MIME type:', contentType);
        }
      } catch (error) {
        console.error('Service Worker registration failed:', error);
        
        // Attempt to fetch the Service Worker script to see what's being returned
        try {
          const response = await fetch('/firebase-messaging-sw.js');
          const text = await response.text();
          console.error('Content of firebase-messaging-sw.js:', text.substring(0, 200) + '...'); // Log first 200 characters
        } catch (fetchError) {
          console.error('Failed to fetch Service Worker script:', fetchError);
        }
      }
    } else {
      console.log('Service workers are not supported in this browser');
    }
  };