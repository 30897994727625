import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, app } from './firebase-config';
import { doc, getDoc, collection, query, where, limit, getDocs, DocumentReference } from 'firebase/firestore';
import { Typography, Paper, List, ListItem, ListItemText, CircularProgress, Box, Divider, Container, useMediaQuery, useTheme, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { generateVCard } from './vCardGenerator';



interface AssetData {
  id: string;
  firestoreId: string; 
  typeName: string;
  assetType: DocumentReference;
  attributes: Record<string, unknown>;
  photoURL?: string;
  organization: DocumentReference;
}

interface FirestoreAssetData {
  id: string;
  firestoreId: string;  
  assetType: DocumentReference;
  attributes?: Record<string, unknown>;
  photoURLs?: string[];
  publicPage?: boolean;
  organization: DocumentReference;
}

interface PublicPageSettings {
  headerType: 'text' | 'image';
  headerText: string;
  headerImageUrl: string;
  footerText: string;
  name: string;
  contactInfo: {
    phone: string;
    email: string;
    address: string;
  };
  logoUrl: string;
  showNotificationButton: boolean;
  notificationButtonText: string;
  enableVCardDownload: boolean;
}

const PublicAssetPage: React.FC = () => {
    const { code } = useParams<{ code: string }>();
    const [assetData, setAssetData] = useState<AssetData | null>(null);
    const [pageSettings, setPageSettings] = useState<PublicPageSettings | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [notificationSent, setNotificationSent] = useState(false);
    const { t } = useTranslation();
    const [customButtons, setCustomButtons] = useState<any[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const linkQuery = query(collection(db, 'publicAssetLinks'), where('code', '==', code), limit(1));
          const linkSnapshot = await getDocs(linkQuery);
  
          if (linkSnapshot.empty) {
            setError('Asset not found');
            setLoading(false);
            return;
          }
  
          const linkDoc = linkSnapshot.docs[0];
          const assetRef = linkDoc.data().asset as DocumentReference;
          const organizationRef = linkDoc.data().organization as DocumentReference;
  
          const assetDoc = await getDoc(assetRef);
      
          if (!assetDoc.exists()) {
            setError('Asset not found');
            setLoading(false);
            return;
          }
          const asset = assetDoc.data() as FirestoreAssetData;
  
          if (!asset.publicPage) {
            setError('Asset is not public');
            setLoading(false);
            return;
          }
  
          const assetTypeDoc = await getDoc(asset.assetType);
          const assetTypeData = assetTypeDoc.data();
          const assetTypeName = assetTypeData && 'typeName' in assetTypeData ? assetTypeData.typeName : 'Unknown Type';
  
          const newAssetData: AssetData = {
            id: asset.id,
            firestoreId: assetDoc.id,
            typeName: assetTypeName,
            assetType: asset.assetType,
            attributes: asset.attributes || {},
            photoURL: asset.photoURLs && asset.photoURLs.length > 0 ? asset.photoURLs[0] : undefined,
            organization: asset.organization,
          };

          setAssetData(newAssetData);
          
          // Fetch custom buttons after setting assetData
          await fetchCustomButtons(newAssetData);
       
          // Fetch public page settings
          let pageSettings = null;
          try {
            const settingsDoc = await getDoc(doc(db, 'publicPageSettings', organizationRef.id));
            if (settingsDoc.exists()) {
              pageSettings = settingsDoc.data() as PublicPageSettings;
            }
          } catch (error) {
            console.error("Error fetching public page settings:", error);
          }
          setPageSettings(pageSettings);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('An error occurred while fetching data');
          setLoading(false);
        }
      };

      fetchData();
    }, [code]);

    const fetchCustomButtons = async (assetData: AssetData) => {
      if (assetData && assetData.assetType) {
        console.log("Fetching custom buttons for asset type:", assetData.assetType.id);
        const buttonsQuery = query(
          collection(db, 'buttonInfo'),
          where('organization', '==', assetData.organization),
          where('assetTypes', 'array-contains', assetData.assetType.id)
        );
        const buttonsSnapshot = await getDocs(buttonsQuery);
        const fetchedButtons = buttonsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Fetched buttons:", fetchedButtons);
        setCustomButtons(fetchedButtons);
      } else {
        console.log("assetData or assetData.assetType not found");
      }
    };
      const handleCustomButtonClick = async (button: any) => {
        if (!assetData) return;
    
        const functions = getFunctions(app, 'europe-west1');
        const sendAssetNotification = httpsCallable(functions, 'sendAssetNotification');
    
        try {
          await sendAssetNotification({ 
            assetId: assetData.firestoreId,
            notificationTitle: button.notificationTitle,
            notificationBody: button.notificationBody,
            notificationUsers: button.notificationUsers
          });
          alert(t('Notification sent successfully'));
        } catch (error) {
          console.error('Error sending notification:', error);
          alert(t('Failed to send notification'));
        }
      };
  
    const sendNotification = async () => {
      if (!assetData) return;
  
      const functions = getFunctions(app, 'europe-west1');
      const sendAssetNotification = httpsCallable(functions, 'sendAssetNotification');
  
      try {
        await sendAssetNotification({ assetId: assetData.firestoreId });
        setNotificationSent(true);
      } catch (error) {
        console.error('Error sending notification:', error);
        alert(t('Failed to send notification'));
      }
    };
  

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  if (error) return <Typography color="error" align="center">{error}</Typography>;
  if (!assetData) return <Typography align="center">{t("No asset data available")}</Typography>;

  return (
    <Container maxWidth="md" sx={{ py: 3 }}>
      {pageSettings && (
        <Box mb={3}>
          {pageSettings.headerType === 'text' ? (
            <Typography variant="h4" align="center">{pageSettings.headerText}</Typography>
          ) : (
            <Box display="flex" justifyContent="center">
              <img src={pageSettings.headerImageUrl} alt="Header" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
          )}
          {pageSettings.logoUrl && (
            <Box display="flex" justifyContent="center" mt={2}>
              <img src={pageSettings.logoUrl} alt="Logo" style={{ maxHeight: '80px', width: 'auto' }} />
            </Box>
          )}
        </Box>
      )}

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>{t("Asset Information")}</Typography>
        {assetData.photoURL && (
          <Box mb={2} display="flex" justifyContent="center">
            <img 
              src={assetData.photoURL} 
              alt="Asset" 
              style={{ 
                maxWidth: isMobile ? '100%' : '300px', 
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px'
              }} 
            />
          </Box>
        )}
        <Typography variant="subtitle1" gutterBottom>ID: {assetData.id}</Typography>
        <Typography variant="subtitle1" gutterBottom>{t("Type")}: {assetData.typeName}</Typography>
        <Divider sx={{ my: 2 }} />
        <List disablePadding>
          {Object.entries(assetData.attributes).map(([key, value]) => (
            <ListItem key={key} disablePadding sx={{ py: 1 }}>
              <ListItemText 
                primary={<Typography variant="body1" fontWeight="bold">{key}</Typography>}
                secondary={<Typography variant="body2">{String(value)}</Typography>}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      {customButtons.map((button) => (
        <Box key={button.id} mt={2}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => handleCustomButtonClick(button)}
          >
            {button.buttonText}
          </Button>
        </Box>
      ))}


      {pageSettings && (
        <Box mt={3}>
          <Divider />
          <Typography variant="body2" align="left" mt={1}>{pageSettings.name}</Typography>
          <Typography variant="body2" align="left" mt={1}>{pageSettings.footerText}</Typography>
          <Typography variant="body2" align="left" mt={1} sx={{ fontSize: '0.8rem' }}>
            {pageSettings.contactInfo.phone && `${t("Phone")}: ${pageSettings.contactInfo.phone}`}<br />
            {pageSettings.contactInfo.email && `${t("Email")}: ${pageSettings.contactInfo.email}`}<br />
            {pageSettings.contactInfo.address && `${t("Address")}: ${pageSettings.contactInfo.address}`}
          </Typography>
        </Box>
      )}
      {pageSettings && pageSettings.enableVCardDownload && (
      <Button
        variant="contained"
        onClick={() => {
          const vCardContent = generateVCard(
            pageSettings.name || '',
            pageSettings.footerText || '',
            pageSettings.contactInfo.phone || '',
            pageSettings.contactInfo.email || '',
            pageSettings.contactInfo.address || ''
          );
          const blob = new Blob([vCardContent], { type: 'text/vcard;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${pageSettings.name || 'contact'}.vcf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
      >
        {t("Lataa yhteystiedot")}
      </Button>
    )}
    </Container>
  );
};

export default PublicAssetPage;