import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase-config';
import AssetTypeForm from './AssetTypeForm';
import { AssetType, UserType, AssetTypeTemplate } from './types';
import { useTranslation } from 'react-i18next';
import templateData from './assetTypeTemplates.json';
import "./AssetTypeManagement.css";

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextField from '@mui/material/TextField';


interface AssetTypeManagementProps {
  user: UserType;
}

const AssetTypeManagement: React.FC<AssetTypeManagementProps> = ({ user }) => {
    const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
    const [selectedAssetType, setSelectedAssetType] = useState<AssetType | null>(null);
    const { t } = useTranslation();
    const [groupedTemplates, setGroupedTemplates] = useState<Record<string, AssetTypeTemplate[]>>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
  
    
    const validateTemplates = (templates: any[]): AssetTypeTemplate[] => {
      return templates.map(template => ({
        id: template.id || '',
        name: template.name || '',
        category: template.category || '',
        attributes: (template.attributes || []).map((attr: any) => ({
          name: attr.name || '',
          type: attr.type || 'string',
          options: attr.options || [],
          optionColors: attr.optionColors || []
        })),
        markerSymbol: template.markerSymbol || 'circle',
        markerColor: template.markerColor || '#000000',
        colorAttribute: template.colorAttribute || ''
      }));
    };

     useEffect(() => {
    fetchAssetTypes();
    const validatedTemplates = validateTemplates(templateData.templates);
    setGroupedTemplates(groupTemplatesByCategory(validatedTemplates));
  }, [user]);

  
  const fetchAssetTypes = async () => {
    if (!user.organization) {
      console.warn("No user organization found");
      return;
    }
    const assetTypesQuery = query(collection(db, "assetTypes"), where("organization", "==", user.organization));
    const assetTypesSnapshot = await getDocs(assetTypesQuery);
    const fetchedAssetTypes = assetTypesSnapshot.docs.map(doc => {
      const data = doc.data();
      console.log("Fetched asset type:", data);
      return {
        id: doc.id,
        typeName: data.typeName || data.name,
        organization: data.organization,
        attributes: data.attributes || [],
        markerSymbol: data.markerSymbol || 'circle',
        markerColor: data.markerColor || '#000000',
        colorAttribute: data.colorAttribute || '',
      } as AssetType;
    });
    console.log("Fetched Asset Types:", fetchedAssetTypes);
    setAssetTypes(fetchedAssetTypes);
  };
  

  const handleComplete = () => {
    fetchAssetTypes();
    setSelectedAssetType(null);
  };

  const handleTemplateSelect = (template: AssetTypeTemplate) => {
    setSelectedAssetType({
      id: '',
      typeName: template.name,
      organization: user.organization,
      attributes: template.attributes,
      markerSymbol: template.markerSymbol,
      markerColor: template.markerColor,
      colorAttribute: template.colorAttribute,
    } as AssetType);
  };

  const groupTemplatesByCategory = (templates: AssetTypeTemplate[]) => {
    return templates.reduce((acc, template) => {
      if (!acc[template.category]) {
        acc[template.category] = [];
      }
      acc[template.category].push(template);
      return acc;
    }, {} as Record<string, AssetTypeTemplate[]>);
  };

  const renderTree = (nodes: Record<string, AssetTypeTemplate[]>) => {
    return Object.entries(nodes).map(([category, templates]) => (
      <TreeItem key={category} itemId={category} label={`${category} (${templates.length})`}>
        {templates
          .filter(template => 
            template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            category.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((template) => (
            <TreeItem
              key={template.id}
              itemId={template.id}
              label={template.name}
              onClick={() => handleTemplateSelect(template)}
            />
          ))}
      </TreeItem>
    ));
  };

  
  return (
    <div className="container">
      <h1 className="page-title">{t('Asset Type Management')}</h1>
      <div className="asset-type-management">
        <div className="template-list card">
          <h2>{t('Templates')}</h2>
          <TextField
            label={t('Search templates')}
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <SimpleTreeView
            aria-label="asset type templates"
            defaultExpandedItems={[]}
            expandedItems={expandedItems}
            onExpandedItemsChange={(event, itemIds) => setExpandedItems(itemIds)}
            slots={{
              collapseIcon: ExpandMoreIcon,
              expandIcon: ChevronRightIcon,
            }}
          >
            {renderTree(groupedTemplates)}
          </SimpleTreeView>
        </div>
        <div className="asset-type-form card">
          <h2>{t("Select Asset Type")}</h2>
          <select 
            className="select-input"
            onChange={(e) => {
              const selected = assetTypes.find(type => type.id === e.target.value);
              setSelectedAssetType(selected || null);
            }}
            value={selectedAssetType?.id || ''}
          >
            <option value="">{t('Create New Asset Type')}</option>
            {assetTypes.map(type => (
              <option key={type.id} value={type.id}>{type.typeName}</option>
            ))}
          </select>
          <h2>{selectedAssetType ? t('Edit Asset Type') : t('Create New Asset Type')}</h2>
          <AssetTypeForm
            user={user}
            existingAssetType={selectedAssetType}
            onComplete={handleComplete}
          />
        </div>
      </div>
    </div>
  );
};


export default AssetTypeManagement;