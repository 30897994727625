import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Link, useNavigate, useLocation   } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
//import Sitemark from './SitemarkIcon';
import { ReactNode } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';
import LanguageIcon from '@mui/icons-material/Language';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

interface NavItem {
  title: string;
  path?: string;
  onClick?: () => void;
}

interface AppAppBarProps {
  rightLinks: NavItem[];
}

export default function AppAppBar({ rightLinks }: AppAppBarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavClick = (item: NavItem) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.path) {
      if (item.path.startsWith('/')) {
        navigate(item.path);
      } else {
        if (location.pathname !== '/') {
          navigate('/', { state: { scrollTo: item.path } });
        } else {
          const element = document.getElementById(item.path);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    }
    setMobileOpen(false);
  };

  const menuItems: NavItem[] = [
    { title: t('Home'), path: 'hero' },
    { title: t('Features'), path: 'features' },
    { title: t('Pricing'), path: 'pricing' },
    { title: t('Blog'), path: 'blog' },
    { title: t('FAQ'), path: 'faq' },
    { title: t('Contact'), path: 'contactform' },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <Button
              fullWidth
              onClick={() => handleNavClick(item)}
            >
              <ListItemText primary={item.title} />
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#3498db', boxShadow: 'none' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            <Link to="/">
              <img src="/PakkaLogoV1.svg" alt="Pakka Tracker" className="navbar-logo" style={{ height: '40px' }} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((item) => (
              <Button
                key={item.title}
                onClick={() => handleNavClick(item)}
                color="inherit"
                sx={{ mx: 1 }}
              >
                {item.title}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {rightLinks.map((item, index) => (
              <Button
                key={index}
                onClick={() => handleNavClick(item)}
                color="inherit"
                sx={{ mx: 1 }}
              >
                {item.title}
              </Button>
            ))}
          </Box>
          <LanguageSelector icon={<LanguageIcon />} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}