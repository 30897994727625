import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import SitemarkIcon from './SitemarkIcon';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import MUILink from '@mui/material/Link';
import { app } from '../firebase-config'; // Adjust this import based on your project structure
import { getFunctions, httpsCallable } from 'firebase/functions';



interface FooterProps {
  children?: ReactNode;
}


function Copyright() {
  return (
    <Box sx={{ color: 'text.secondary', mt: 1 }}>
      <Typography variant="body2">
        {'Copyright © '}
        <Link color="inherit" href="https://effifleet.fi/">
          EffiFleet Oy
        </Link>
        {' '}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2">3408177-7</Typography>
      <Typography variant="body2">juho@effifleet.fi</Typography>
    </Box>
  );
}



export default function Footer({ children }: FooterProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleNewsletterSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');
  
    if (!email) {
      setSubmitMessage(t('Please enter a valid email address'));
      setIsSubmitting(false);
      return;
    }
  
    try {
      const functions = getFunctions(app, 'europe-west1');
      const newsletterSignup = httpsCallable(functions, 'newsletterSignup');
      const result = await newsletterSignup({ email });
  
      setEmail('');
      setSubmitMessage(t('thankYouForSubscribing'));
    } catch (error: any) {
      console.error('Error subscribing to newsletter:', error);
      if (error.code === 'functions/already-exists') {
        setSubmitMessage(t('This email is already subscribed.'));
      } else if (error.code === 'functions/resource-exhausted') {
        setSubmitMessage(t('Too many attempts. Please try again later.'));
      } else {
        setSubmitMessage(t('An error occurred. Please try again.'));
      }
    }
  
    setIsSubmitting(false);
  };

  const CustomLink: React.FC<{ to: string; children: React.ReactNode }> = ({ to, children }) => {
    const location = useLocation();
    if (location.pathname === '/') {
      return (
        <ScrollLink
          to={to}
          smooth={true}
          duration={500}
          offset={-64} // Adjust this value based on your header height
          style={{ cursor: 'pointer' }}
        >
          <MUILink component="span" color="text.secondary" variant="body2">
            {children}
          </MUILink>
        </ScrollLink>
      );
    } else {
      return (
        <MUILink
          component="span"
          color="text.secondary"
          variant="body2"
          onClick={() => navigate('/', { state: { scrollTo: to } })}
          style={{ cursor: 'pointer' }}
        >
          {children}
        </MUILink>
      );
    }
  };

  

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
            {t("Join the newsletter")}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
            {t("Subscribe for weekly updates. No spams ever!")}
          </Typography>
          <form onSubmit={handleNewsletterSubmit}>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="email-newsletter"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("Your email address")}
                inputProps={{
                  autoComplete: 'email',
                  'aria-label': 'Enter your email address',
                }}
                sx={{ width: '250px' }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disabled={isSubmitting}
                sx={{ flexShrink: 0 }}
              >
                {isSubmitting ? t("Subscribing...") : t("Subscribe")}
              </Button>
            </Stack>
          </form>
          {submitMessage && (
            <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
              {submitMessage}
            </Typography>
          )}
        </Box>
        </Box>
        <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
          {t("Product")}
        </Typography>
        <CustomLink to="blog">
          {t("Blog")}
        </CustomLink>
        <CustomLink to="highlights">
          {t("Highlights")}
        </CustomLink>
        <CustomLink to="pricing">
          {t("Pricing")}
        </CustomLink>
        <CustomLink to="faq">
          {t("FAQs")}
        </CustomLink>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
          {t("Company")}
        </Typography>
        <CustomLink to="about">
          {t("About us")}
        </CustomLink>
        <CustomLink to="contactform">
          {t("Contact")}
        </CustomLink>
      </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Link color="text.secondary" variant="body2" href="/terms-of-service">
            {t("Terms")}
          </Link>
          <Link color="text.secondary" variant="body2" href="/privacy-policy">
            {t("Privacy")}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="/privacy-policy">
            {t("publicLayout.navItems.privacyPolicy")}
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="/terms-of-service">
            {t("publicLayout.navItems.termsOfService")}
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >
          
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
      {children}
    </Container>
  );
}
