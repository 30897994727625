import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, DocumentData, deleteDoc, doc, query, orderBy } from 'firebase/firestore';
import { Link, useNavigate  } from 'react-router-dom';
import { db } from './firebase-config';
import { Typography, Card, CardContent, CardMedia, CardActions, Button, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserType } from './types';


interface BlogPostsProps {
    user: UserType | null;
  }
  

interface BlogPost {
  id: string;
  title: string;
  content: string;
  imageUrl?: string;
  date: Date;
  author: string;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

const BlogPosts: React.FC<BlogPostsProps> = ({ user }) => {
  const [posts, setPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = collection(db, 'blogPosts');
      const postsQuery = query(postsCollection, orderBy('date', 'desc'));
      const postSnapshot = await getDocs(postsQuery);
      const postList: BlogPost[] = postSnapshot.docs.map(doc => {
        const data = doc.data() as DocumentData;
        return {
          id: doc.id,
          title: data.title,
          content: data.content,
          imageUrl: data.imageUrl,
          date: data.date.toDate(),
          author: data.author
        };
      });
      setPosts(postList);
    };

    fetchPosts();
  }, []);

  const handleDelete = async (postId: string) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      await deleteDoc(doc(db, 'blogPosts', postId));
      setPosts(posts.filter(post => post.id !== postId));
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Blog Posts</Typography>
      {user?.superAdmin && (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/blog/create"
          style={{ marginBottom: '20px' }}
        >
          Create New Post
        </Button>
      )}
      <Box>
        {posts.map(post => (
          <Card key={post.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" component="div">
                <StyledLink to={`/blog/${post.id}`}>{post.title}</StyledLink>
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                By {post.author} on {post.date ? post.date.toDateString() : 'Unknown date'}
              </Typography>
              {post.imageUrl && (
                <CardMedia
                  component="img"
                  height="200"
                  image={post.imageUrl}
                  alt={post.title}
                  sx={{ mb: 1 }}
                />
              )}
              <Typography variant="body2" color="text.secondary">
                {post.content.substring(0, 200)}...
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link} to={`/blog/${post.id}`}>
                Read More
              </Button>
              {user?.superAdmin && (
                <Button size="small" color="secondary" onClick={() => handleDelete(post.id)}>
                  Delete
                </Button>
              )}
            </CardActions>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default BlogPosts;