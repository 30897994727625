import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from './firebase-config';
import { UserType } from './types';
import { useTranslation } from 'react-i18next';

interface SubscriptionData {
    stripeSubscriptionId: string;
    subscriptionActive: boolean;
    // Add other fields as necessary
  }
  
  const SubscriptionSuccessPage: React.FC<{ user: UserType }> = ({ user }) => {
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | null>(null);
    const { t } = useTranslation();
    
  
    useEffect(() => {
      const unsubscribe = onSnapshot(doc(db, 'organizations', user.uid), (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.stripeSubscriptionId && data.subscriptionActive) {
            setSubscriptionData({
              stripeSubscriptionId: data.stripeSubscriptionId,
              subscriptionActive: data.subscriptionActive,
              // Add other fields as necessary
            });
          }
        }
      });
  
      return () => unsubscribe();
    }, [user.uid]);
  
    if (!subscriptionData) {
      return <div>{t("Processing your subscription...")}</div>;
    }
  
    return (
      <div>
        <h1>{t("Subscription Activated Successfully!")}</h1>
        <p>{t("Your subscription is now active.")}</p>
        <p>{t("Subscription ID")}: {subscriptionData.stripeSubscriptionId}</p>
        {/* Add more details as needed */}
      </div>
    );
  };
  
  export default SubscriptionSuccessPage;