import React, { useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { UserType } from './types';
import { updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import { ColDef, GridApi, ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';


interface UserListProps {
  users: UserType[];
  onUserUpdated: () => void;
  currentUser: UserType;
}

const UserList: React.FC<UserListProps> = ({ users, onUserUpdated, currentUser }) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();

  const columnDefs = useMemo<ColDef<UserType>[]>(() => [
    { field: 'email', headerName: t('Email'), editable: false },
    { 
      field: 'role', 
      headerName: t('Role'), 
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['user', 'admin']
      }
    },
    {
      headerName: t('Actions'),
      cellRenderer: (params: ICellRendererParams<UserType>) => (
        <button 
          onClick={() => handleDeleteUser(params.data?.uid ?? '')}
          disabled={!editMode || params.data?.uid === currentUser.uid}
        >
          Delete
        </button>
      )
    }
  ], [editMode, currentUser]);

  const defaultColDef = useMemo<ColDef>(() => ({
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api);
  };

  const onCellValueChanged = async (event: any) => {
    if (event.colDef.field === 'role') {
      try {
        await updateDoc(doc(db, 'users', event.data.uid), {
          role: event.newValue
        });
        onUserUpdated();
      } catch (error) {
        console.error('Error updating user role:', error);
        gridApi?.refreshCells({rowNodes: [event.node], force: true});
      }
    }
  };

  const handleDeleteUser = async (uid: string) => {
    if (window.confirm(t('Are you sure you want to delete this user?'))) {
      try {
        await deleteDoc(doc(db, 'users', uid));
        onUserUpdated();
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Error deleting user. Please try again.');
      }
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="user-list">
      <button className="btn btn-primary" onClick={toggleEditMode}>
        {editMode ? t('Disable Editing') : t('Enable Editing')}
      </button>
      <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: '20px' }}>
        <AgGridReact<UserType>
          rowData={users}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          readOnlyEdit={!editMode}
        />
      </div>
    </div>
  );
};

export default UserList;