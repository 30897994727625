import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, query, where, getDocs, doc } from 'firebase/firestore';
import UserList from './UserList';
import AddUserForm from './AddUserForm';
import { UserType } from './types';
import { useTranslation } from 'react-i18next';


interface AdminPageProps {
  user: UserType;
}

const AdminPage: React.FC<AdminPageProps> = ({ user }) => {
  const [users, setUsers] = useState<UserType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchUsers();
  }, [user.organization]);

  const fetchUsers = async () => {
    const usersQuery = query(collection(db, "users"), where("organization", "==", user.organization));
    const usersSnapshot = await getDocs(usersQuery);
    const fetchedUsers = usersSnapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id } as UserType));
    setUsers(fetchedUsers);
  };

  const handleUserAdded = () => {
    fetchUsers();
  };

  return (
    <div className="container">
      <h1 className="page-title">{t('Admin Dashboard')}</h1>
      <div className="card">
        <h2>{t('User Management')}</h2>
        <UserList users={users} onUserUpdated={fetchUsers} currentUser={user} />
      </div>
      <div className="card">
        <h2>{t('Add New User')}</h2>
        <AddUserForm organization={user.organization} onUserAdded={handleUserAdded} />
      </div>
    </div>
  );
};

export default AdminPage;