import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import { db } from './firebase-config';
import { collection, query, where, getDocs, updateDoc, addDoc, doc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { UserType } from './types';

interface ButtonInfoFormProps {
  onSubmit: (buttonData: any) => void;
  onCancel: () => void;
  user: UserType;
  buttonToEdit?: any; // Add this prop for editing existing buttons
}

const ButtonInfoForm: React.FC<ButtonInfoFormProps> = ({ onSubmit, onCancel, user, buttonToEdit }) => {
  const [buttonText, setButtonText] = useState(buttonToEdit?.buttonText || '');
  const [selectedAssetTypes, setSelectedAssetTypes] = useState<string[]>(buttonToEdit?.assetTypes || []);
  const [notificationTitle, setNotificationTitle] = useState(buttonToEdit?.notificationTitle || '');
  const [notificationBody, setNotificationBody] = useState(buttonToEdit?.notificationBody || '');
  const [assetTypes, setAssetTypes] = useState<any[]>([]);
  const [adminUsers, setAdminUsers] = useState<any[]>([]);
  const [selectedAdminUsers, setSelectedAdminUsers] = useState<string[]>(buttonToEdit?.notificationUsers || []);
  const [allAssetTypes, setAllAssetTypes] = useState(buttonToEdit?.allAssetTypes || false);
  const [allUsers, setAllUsers] = useState(buttonToEdit?.allUsers || false);
  const { t } = useTranslation();
  
  useEffect(() => {
    fetchAssetTypes();
    fetchAdminUsers();
  }, []);

  const fetchAssetTypes = async () => {
    const assetTypesQuery = query(collection(db, 'assetTypes'), where('organization', '==', user.organization));
    const assetTypesSnapshot = await getDocs(assetTypesQuery);
    setAssetTypes(assetTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const fetchAdminUsers = async () => {
    const adminUsersQuery = query(collection(db, 'users'), where('organization', '==', user.organization), where('role', '==', 'admin'));
    const adminUsersSnapshot = await getDocs(adminUsersQuery);
    setAdminUsers(adminUsersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleAssetTypeToggle = (assetTypeId: string) => {
    setSelectedAssetTypes(prev => 
      prev.includes(assetTypeId) ? prev.filter(id => id !== assetTypeId) : [...prev, assetTypeId]
    );
  };

  const handleAdminUserToggle = (userId: string) => {
    setSelectedAdminUsers(prev => 
      prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
    );
  };

  const handleSelectAllAssetTypes = () => {
    if (selectedAssetTypes.length === assetTypes.length) {
      setSelectedAssetTypes([]);
    } else {
      setSelectedAssetTypes(assetTypes.map(type => type.id));
    }
  };

  const handleSelectAllAdminUsers = () => {
    if (selectedAdminUsers.length === adminUsers.length) {
      setSelectedAdminUsers([]);
    } else {
      setSelectedAdminUsers(adminUsers.map(user => user.id));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const buttonData = {
      buttonText,
      assetTypes: allAssetTypes ? [] : selectedAssetTypes,
      notificationTitle,
      notificationBody,
      notificationUsers: allUsers ? [] : selectedAdminUsers,
      organization: user.organization,
      allAssetTypes,
      allUsers
    };

    try {
      if (buttonToEdit) {
        await updateDoc(doc(db, 'buttonInfo', buttonToEdit.id), buttonData);
        console.log('Button updated successfully');
      } else {
        const docRef = await addDoc(collection(db, 'buttonInfo'), buttonData);
        console.log('New button added with ID:', docRef.id);
      }
      onSubmit(buttonData);
    } catch (error) {
      console.error('Error saving button:', error);
      alert(t('Error saving button'));
    }
  };

 return (
    <Box sx={{ mt: 2 }}>
      <TextField
        fullWidth
        label={t('Button Text')}
        value={buttonText}
        onChange={(e) => setButtonText(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>{t('Asset Types')}</InputLabel>
        <Select
          multiple
          value={allAssetTypes ? ['all'] : selectedAssetTypes}
          onChange={(e) => {
            const value = e.target.value as string[];
            if (value.includes('all')) {
              setAllAssetTypes(true);
              setSelectedAssetTypes([]);
            } else {
              setAllAssetTypes(false);
              setSelectedAssetTypes(value);
            }
          }}
          input={<OutlinedInput label={t('Asset Types')} />}
          renderValue={(selected) => selected.includes('all') ? t('All Asset Types') : selected.map(id => assetTypes.find(type => type.id === id)?.typeName).join(', ')}
        >
          <MenuItem value="all">
            <Checkbox checked={allAssetTypes} />
            <ListItemText primary={t('All Asset Types')} />
          </MenuItem>
          {!allAssetTypes && (
            <MenuItem value="select-all">
              <Checkbox 
                checked={selectedAssetTypes.length === assetTypes.length}
                indeterminate={selectedAssetTypes.length > 0 && selectedAssetTypes.length < assetTypes.length}
                onChange={handleSelectAllAssetTypes}
              />
              <ListItemText primary={t('Select/Deselect All')} />
            </MenuItem>
          )}
          {assetTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              <Checkbox checked={selectedAssetTypes.includes(type.id)} onChange={() => handleAssetTypeToggle(type.id)} />
              <ListItemText primary={type.typeName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>{t('Notification Recipients')}</InputLabel>
        <Select
          multiple
          value={allUsers ? ['all'] : selectedAdminUsers}
          onChange={(e) => {
            const value = e.target.value as string[];
            if (value.includes('all')) {
              setAllUsers(true);
              setSelectedAdminUsers([]);
            } else {
              setAllUsers(false);
              setSelectedAdminUsers(value);
            }
          }}
          input={<OutlinedInput label={t('Notification Recipients')} />}
          renderValue={(selected) => selected.includes('all') ? t('All Users') : selected.map(id => adminUsers.find(user => user.id === id)?.email).join(', ')}
        >
          <MenuItem value="all">
            <Checkbox checked={allUsers} />
            <ListItemText primary={t('All Users')} />
          </MenuItem>
          {!allUsers && (
            <MenuItem value="select-all">
              <Checkbox 
                checked={selectedAdminUsers.length === adminUsers.length}
                indeterminate={selectedAdminUsers.length > 0 && selectedAdminUsers.length < adminUsers.length}
                onChange={handleSelectAllAdminUsers}
              />
              <ListItemText primary={t('Select/Deselect All')} />
            </MenuItem>
          )}
          {adminUsers.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              <Checkbox checked={selectedAdminUsers.includes(user.id)} onChange={() => handleAdminUserToggle(user.id)} />
              <ListItemText primary={user.email} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label={t('Notification Title')}
        value={notificationTitle}
        onChange={(e) => setNotificationTitle(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label={t('Notification Body')}
        value={notificationBody}
        onChange={(e) => setNotificationBody(e.target.value)}
        required
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />
      <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ mr: 1 }}>
        {buttonToEdit ? t('Update Button') : t('Save Button')}
      </Button>
      <Button onClick={onCancel} variant="outlined">{t('Cancel')}</Button>
    </Box>
  );
};

export default ButtonInfoForm;