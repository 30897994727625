import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDoc, doc, deleteDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { db } from './firebase-config';
import { UserType } from './types';
import { Typography, Button, Container, Box } from '@mui/material';

interface BlogPost {
  id: string;
  title: string;
  content: string;
  imageUrl?: string;
  date: Date;
  author: string;
}


const BlogPost: React.FC<{ user: UserType | null }> = ({ user }) => {
  const [post, setPost] = useState<BlogPost | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchPost = async () => {
      if (id) {
        const docRef = doc(db, 'blogPosts', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setPost({
            id: docSnap.id,
            title: data.title,
            content: data.content,
            imageUrl: data.imageUrl,
            date: data.date.toDate(),
            author: data.author
          });
        }
      }
    };

    fetchPost();
  }, [id]);

  const navigate = useNavigate();

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      await deleteDoc(doc(db, 'blogPosts', id as string));
      navigate('/blog');
    }
  };

  if (!post) return <div>Loading...</div>;

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>{post.title}</Typography>
      <Typography variant="subtitle1" gutterBottom>
        By {post.author} on {post.date ? post.date.toDateString() : 'Unknown date'}
      </Typography>
      {post.imageUrl && (
        <Box sx={{ my: 2 }}>
          <img src={post.imageUrl} alt={post.title} style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
      )}
      <Box sx={{ my: 2 }}>
        <ReactMarkdown>{post.content}</ReactMarkdown>
      </Box>
      {user?.superAdmin && (
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Delete Post
        </Button>
      )}
    </Container>
  );
};

export default BlogPost;