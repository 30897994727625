import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Box, Link, Typography, Button } from '@mui/material';
import { User } from 'firebase/auth';
import { signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import LanguageIcon from '@mui/icons-material/Language';

import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import LanguageSelector from './LanguageSelector';

interface PublicLayoutProps {
  children: React.ReactNode;
}

const UpdatedPublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const rightLinks = user
    ? [
        { title: t('publicLayout.navItems.app'), path: '/map' },
        { title: t('Logout'), onClick: handleLogout },
      ]
    : [{ title: t('publicLayout.navItems.login'), path: '/login' }];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppAppBar rightLinks={rightLinks} />

      <Container component="main" sx={{ flexGrow: 1, mt: 8, mb: 2 }}>
        <Outlet />
        {children}
      </Container>

      <Footer>
      
      </Footer>
      <LanguageSelector icon={<LanguageIcon />} />
    </Box>
  );
};

export default UpdatedPublicLayout;