// src/env.ts

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export function isMapboxTokenAvailable(): boolean {
  return typeof MAPBOX_ACCESS_TOKEN === 'string' && MAPBOX_ACCESS_TOKEN.length > 0;
}

if (!isMapboxTokenAvailable()) {
  console.error('REACT_APP_MAPBOX_ACCESS_TOKEN is not defined in .env file');
}