import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { 
  collection, 
  addDoc, 
  doc, 
  updateDoc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  writeBatch,
  QueryDocumentSnapshot
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { AssetType, UserType, AssetTypeAttribute, ObservationType } from './types';
import "./AssetTypeForm.css";
import { useTranslation } from 'react-i18next';

import { 
  TextField, Select, MenuItem, Button, Grid, Typography, 
  Accordion, AccordionSummary, AccordionDetails, IconButton, InputLabel, FormControl,
  List, ListItem, ListItemIcon, ListItemText, Checkbox,FormControlLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description';



interface AssetTypeFormProps {
  user: UserType;
  existingAssetType?: AssetType | null;
  onComplete: () => void;
}

const AssetTypeForm: React.FC<AssetTypeFormProps> = ({ user, existingAssetType, onComplete }) => {
  const [typeName, setName] = useState('');
  const [attributes, setAttributes] = useState<AssetTypeAttribute[]>([]);
  const { t } = useTranslation();
  const [markerSymbol, setMarkerSymbol] = useState('circle');
  const [markerColor, setMarkerColor] = useState('#000000');
  const [colorAttribute, setColorAttribute] = useState('');
  const [assetType, setAssetType] = useState<AssetType>({
    id: '',
    typeName: '',
    organization: user.organization,
    attributes: [],
    markerSymbol: 'circle',
    markerColor: '#000000',
    tasks:[]
  });
  const [observationTypes, setObservationTypes] = useState<ObservationType[]>([]);
  const [selectedObservationTypes, setSelectedObservationTypes] = useState<string[]>([]);
  const [documents, setDocuments] = useState<{ url: string; name: string }[]>([]);
  const [createNewAssetIds, setCreateNewAssetIds] = useState(false);


  useEffect(() => {
    fetchObservationTypes();
  }, [user.organization]);

  useEffect(() => {
    if (existingAssetType) {
      setName(existingAssetType.typeName);
      setAttributes(existingAssetType.attributes || []);
      setMarkerSymbol(existingAssetType.markerSymbol || 'circle');
      setMarkerColor(existingAssetType.markerColor || '#000000');
      setColorAttribute(existingAssetType.colorAttribute || '');
      setAssetType(existingAssetType);
      setDocuments(existingAssetType.documentURLs || []);
      preSelectObservationTypes(existingAssetType.id);
    } else {
      setName('');
      setAttributes([]);
      setMarkerSymbol('circle');
      setMarkerColor('#000000');
      setColorAttribute('');
      setSelectedObservationTypes([]);
      setDocuments([]);

    }
  }, [existingAssetType]);

  const fetchObservationTypes = async () => {
    const observationTypesQuery = query(collection(db, "observationTypes"), where("organization", "==", user.organization));
    const observationTypesSnapshot = await getDocs(observationTypesQuery);
    const fetchedObservationTypes = observationTypesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as ObservationType));
    setObservationTypes(fetchedObservationTypes);
  };

  const preSelectObservationTypes = async (assetTypeId: string) => {
    const applicableTypes = observationTypes.filter(ot => 
      ot.applicableAssetTypes && ot.applicableAssetTypes.includes(assetTypeId)
    );
    setSelectedObservationTypes(applicableTypes.map(ot => ot.id));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!typeName.trim()) {
      alert("Asset Type Name is required");
      return;
    }
  
    const assetTypeData = {
      typeName,
      attributes,
      organization: user.organization,
      markerSymbol,
      markerColor,
      colorAttribute,
      documents,
      createNewAssetIds,
    };
  
    try {
      let assetTypeId: string;
      if (existingAssetType && existingAssetType.id) {
        await updateDoc(doc(db, 'assetTypes', existingAssetType.id), assetTypeData);
        assetTypeId = existingAssetType.id;
      } else {
        const docRef = await addDoc(collection(db, 'assetTypes'), assetTypeData);
        assetTypeId = docRef.id;
      }

      // Update observation types
      const batch = writeBatch(db);
      for (const observationType of observationTypes) {
        const observationTypeRef = doc(db, 'observationTypes', observationType.id);
        if (selectedObservationTypes.includes(observationType.id)) {
          batch.update(observationTypeRef, {
            applicableAssetTypes: Array.from(new Set([...(observationType.applicableAssetTypes || []), assetTypeId]))
          });
        } else {
          batch.update(observationTypeRef, {
            applicableAssetTypes: (observationType.applicableAssetTypes || []).filter(id => id !== assetTypeId)
          });
        }
      }
      await batch.commit();

      onComplete();
    } catch (error) {
      console.error("Error saving asset type:", error);
      alert(t("An error occurred while saving the asset type"));
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const storage = getStorage();
      const fileName = file.name; // Get the original file name
      const storageRef = ref(storage, `assetTypeDocuments/${user.organization.id}/${fileName}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        
        // Store both the download URL and the original file name
        setDocuments([...documents, { url: downloadURL, name: fileName }]);
      } catch (error) {
        console.error("Error uploading file:", error);
        alert(t("An error occurred while uploading the file"));
      }
    }
  };

  const handleDeleteDocument = async (url: string) => {
    if (window.confirm(t("Are you sure you want to delete this document?"))) {
      try {
        const storage = getStorage();
        const fileRef = ref(storage, url);
        await deleteObject(fileRef);
        setDocuments(documents.filter(doc => doc.url !== url));
      } catch (error) {
        console.error("Error deleting document:", error);
        alert(t("An error occurred while deleting the document"));
      }
    }
  };

  const handleSelectAllToggle = () => {
    if (selectedObservationTypes.length === observationTypes.length) {
      setSelectedObservationTypes([]);
    } else {
      setSelectedObservationTypes(observationTypes.map(type => type.id));
    }
  };

  const handleObservationTypeToggle = (observationTypeId: string) => {
    setSelectedObservationTypes(prevSelected =>
      prevSelected.includes(observationTypeId)
        ? prevSelected.filter(id => id !== observationTypeId)
        : [...prevSelected, observationTypeId]
    );
  };
  const updateOptionColor = (attrIndex: number, optionIndex: number, color: string) => {
    const newAttributes = [...attributes];
    if (!newAttributes[attrIndex].optionColors) {
      newAttributes[attrIndex].optionColors = [];
    }
    newAttributes[attrIndex].optionColors![optionIndex] = color;
    setAttributes(newAttributes);
  };

  const addAttribute = () => {
    setAttributes([...attributes, { name: '', type: 'string', options: [] }]);
  };

  const removeAttribute = (index: number) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const addOption = (attrIndex: number, event: React.MouseEvent) => {
    event.preventDefault();
    const newAttributes = [...attributes];
    if (!newAttributes[attrIndex].options) {
      newAttributes[attrIndex].options = [];
    }
    newAttributes[attrIndex].options!.push('');
    setAttributes(newAttributes);
  };

  const updateOption = (attrIndex: number, optionIndex: number, value: string) => {
    const newAttributes = [...attributes];
    if (!newAttributes[attrIndex].options) {
      newAttributes[attrIndex].options = [];
    }
    newAttributes[attrIndex].options![optionIndex] = value;
    setAttributes(newAttributes);
  };

  const updateAttribute = (index: number, field: keyof AssetTypeAttribute, value: string) => {
  const newAttributes = [...attributes];
  if (field === 'type' && value === 'enum') {
    newAttributes[index] = { 
      ...newAttributes[index], 
      [field]: value, 
      options: ['']  // Initialize with an empty option object
    };
  } else {
    newAttributes[index] = { ...newAttributes[index], [field]: value };
  }
  setAttributes(newAttributes);
};

  const updateExistingAssets = async (assetTypeId: string, newAttributes: Array<{name: string, type: string}>) => {
    const assetsRef = collection(db, 'assets');
const assetSnapshot = await getDocs(query(assetsRef, where('type', '==', doc(db, 'assetTypes', assetTypeId))));

    const batch = writeBatch(db);
    assetSnapshot.docs.forEach((assetDoc: QueryDocumentSnapshot) => {
      const assetData = assetDoc.data();
      newAttributes.forEach(attr => {
        if (!(attr.name in assetData.attributes)) {
          assetData.attributes[attr.name] = 'N/A';
        }
      });
      batch.update(assetDoc.ref, { attributes: assetData.attributes });
    });

    await batch.commit();
  };
  const handleDelete = async () => {
    if (!existingAssetType || !window.confirm(t("Are you sure you want to delete this asset type? This will also delete all assets of this type, related QR codes, and locations."))) {
      return;
    }

    try {
      await deleteAssetTypeAndRelatedData(existingAssetType.id);
      onComplete();
    } catch (error) {
      console.error("Error deleting asset type:", error);
      alert("An error occurred while deleting the asset type");
    }
  };

  const deleteAssetTypeAndRelatedData = async (assetTypeId: string) => {
    const batch = writeBatch(db);

    // 1. Query for all assets of this type
    const assetsQuery = query(
      collection(db, "assets"),
      where("type", "==", assetTypeId),
      where("organization", "==", user.organization)
    );
    const assetsSnapshot = await getDocs(assetsQuery);

    // 2. For each asset, delete related QR codes and locations
    for (const assetDoc of assetsSnapshot.docs) {
      const assetRef = doc(db, "assets", assetDoc.id);

      // 2a. Query for QR codes related to this asset
      const qrCodesQuery = query(
        collection(db, "qrcodes"),
        where("asset", "==", assetRef),
        where("organization", "==", user.organization)
      );
      const qrCodesSnapshot = await getDocs(qrCodesQuery);

      // 2b. For each QR code, delete related locations and the QR code itself
      for (const qrCodeDoc of qrCodesSnapshot.docs) {
        const qrCodeRef = doc(db, "qrcodes", qrCodeDoc.id);

        // Delete locations related to this QR code
        const locationsQuery = query(
          collection(db, "locations"),
          where("qrcode", "==", qrCodeRef),
          where("organization", "==", user.organization)
        );
        const locationsSnapshot = await getDocs(locationsQuery);
        locationsSnapshot.docs.forEach((locationDoc) => {
          batch.delete(doc(db, "locations", locationDoc.id));
        });

        // Delete the QR code
        batch.delete(qrCodeRef);
      }

      // Delete the asset
      batch.delete(assetRef);
    }

    // 3. Delete the asset type
    batch.delete(doc(db, "assetTypes", assetTypeId));

    // Commit the batch
    await batch.commit();
  };

  const markerSymbols = ['circle', 'triangle', 'square', 'star', 'custom'];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("Asset Type Name")}
            value={typeName}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Grid>
        
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("Attributes")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {attributes.map((attr, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label={t("Attribute Name")}
                      value={attr.name}
                      onChange={(e) => updateAttribute(index, 'name', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      fullWidth
                      value={attr.type}
                      onChange={(e) => updateAttribute(index, 'type', e.target.value as string)}
                    >
                      <MenuItem value="string">{t("String")}</MenuItem>
                      <MenuItem value="number">{t("Number")}</MenuItem>
                      <MenuItem value="boolean">{t("Boolean")}</MenuItem>
                      <MenuItem value="enum">{t("Enum")}</MenuItem>
                    </Select>
                  </Grid>
                  {attr.type === 'enum' && (
                    <Grid item xs={6}>
                      {attr.options?.map((option, optionIndex) => (
                        <div key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                          <TextField
                            value={option}
                            onChange={(e) => updateOption(index, optionIndex, e.target.value)}
                            placeholder={`Option ${optionIndex + 1}`}
                            size="small"
                            style={{ marginRight: 8 }}
                          />
                          {attr.name === colorAttribute && (
                            <input
                              type="color"
                              value={attr.optionColors?.[optionIndex] || '#000000'}
                              onChange={(e) => updateOptionColor(index, optionIndex, e.target.value)}
                              style={{ marginLeft: 8 }}
                            />
                          )}
                        </div>
                      ))}
                      <Button
                        startIcon={<AddIcon />}
                        onClick={(e) => addOption(index, e)}
                        size="small"
                      >
                        {t("Add Option")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={1}>
                    <IconButton onClick={() => removeAttribute(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={addAttribute}
                variant="outlined"
                style={{ marginTop: 16 }}
              >
                {t("Add Attribute")}
              </Button>
            </AccordionDetails>
          </Accordion>
        </Grid>


        <Grid item xs={9}>
        <FormControl fullWidth>
        <InputLabel id="marker-symbol-label">{t("Marker Symbol")}</InputLabel>
          <Select
            fullWidth
            value={markerSymbol}
            onChange={(e) => setMarkerSymbol(e.target.value as string)}
            label={t("Marker Symbol")}
          >
            <MenuItem value="circle">{t("Circle")}</MenuItem>
            <MenuItem value="square">{t("Square")}</MenuItem>
            <MenuItem value="triangle">{t("Triangle")}</MenuItem>
            <MenuItem value="star">{t("Star")}</MenuItem>
          </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={3}>
          <TextField
            fullWidth
            type="color"
            value={markerColor}
            onChange={(e) => setMarkerColor(e.target.value)}
            label={t("Default Marker Color")}
          />
        </Grid>

        <Grid item xs={12}>
        <FormControl fullWidth variant='filled' >
          <InputLabel id="color-attribute-label">{t("colorAttributeSelection")}</InputLabel>
          <Select
            labelId="color-attribute-label"
            id="color-attribute"
            value={colorAttribute}
            onChange={(e) => setColorAttribute(e.target.value as string)}
           // label={t("colorAttributeSelection")}
          >
            <MenuItem value="">{t("None")}</MenuItem>
            {attributes.filter(attr => attr.type === 'enum').map(attr => (
              <MenuItem key={attr.name} value={attr.name}>{attr.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("Applicable Observation Types")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedObservationTypes.length === observationTypes.length}
                  indeterminate={selectedObservationTypes.length > 0 && selectedObservationTypes.length < observationTypes.length}
                  onChange={handleSelectAllToggle}
                />
              }
              label={t("Select/Deselect All")}
            />
            <List>
              {observationTypes.map((observationType) => (
                <ListItem key={observationType.id} dense button onClick={() => handleObservationTypeToggle(observationType.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedObservationTypes.includes(observationType.id)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={observationType.typeName} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("Documents")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <input
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="raised-button-file">
                <Button variant="outlined" component="span" startIcon={<AttachFileIcon />}>
                  {t("Upload Document")}
                </Button>
              </label>
              <List>
              {documents.map((doc, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={doc.name} />
                  <IconButton onClick={() => handleDeleteDocument(doc.url)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
            </AccordionDetails>
          </Accordion>
          <FormControlLabel
              control={
                <Checkbox
                  checked={createNewAssetIds}
                  onChange={(e) => setCreateNewAssetIds(e.target.checked)}
                  name="createNewAssetIds"
                />
              }
              label={t("Create new Asset IDs automatically")}
            />
        </Grid>
      
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            {existingAssetType ? t('Update') : t('Create')} {t("Asset Type")}
          </Button>
          {existingAssetType && (
            <Button
              onClick={handleDelete}
              variant="contained"
              color="secondary"
              style={{ marginLeft: 16 }}
            >
              {t("Delete Asset Type")}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default AssetTypeForm;