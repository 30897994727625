import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from './firebase-config';
import { UserType, AssetType  } from './types';
import {styled,  Button, TextField, Select, MenuItem, FormControl, InputLabel, List, ListItem, ListItemText, IconButton, Checkbox, ListItemIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select';

interface NotificationRule {
  id?: string;
  triggerType: string;
  triggerValue: string;
  notificationTitle: string;
  notificationBody: string;
  recipients: string[];
  assetTypes: string[];
  allUsers: boolean;
  allAssetTypes: boolean;
}

interface Geofence {
    id: string;
    name: string;
  }

interface NotificationManagementPageProps {
  user: UserType;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 14px) scale(1)',
      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(14px, -6px) scale(0.75)',
        background: theme.palette.background.paper,
        padding: '0 4px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        maxWidth: '100%',
      },
    },
  }));
  
  const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '0 4px',
  }));

const NotificationManagementPage: React.FC<NotificationManagementPageProps> = ({ user }) => {
  const [notificationRules, setNotificationRules] = useState<NotificationRule[]>([]);
  const [newRule, setNewRule] = useState<NotificationRule>({
    triggerType: '',
    triggerValue: '',
    notificationTitle: '',
    notificationBody: '',
    recipients: [],
    assetTypes: [],
    allUsers: false,
    allAssetTypes: false,
  });
  const [allRecipientsSelected, setAllRecipientsSelected] = useState(false);
  const [allAssetTypesSelected, setAllAssetTypesSelected] = useState(false);
  const [editingRule, setEditingRule] = useState<NotificationRule | null>(null);
  const [adminUsers, setAdminUsers] = useState<{ id: string; email: string }[]>([]);
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
  const [geofences, setGeofences] = useState<Geofence[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchNotificationRules();
    fetchAdminUsers();
    fetchAssetTypes();
    fetchGeofences();
  }, []);

  const fetchNotificationRules = async () => {
    const rulesQuery = query(collection(db, 'notificationRules'), where('organization', '==', user.organization));
    const rulesSnapshot = await getDocs(rulesQuery);
    const rules = rulesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as NotificationRule));
    setNotificationRules(rules);
  };

  const fetchAdminUsers = async () => {
    const usersQuery = query(collection(db, 'users'), where('organization', '==', user.organization), where('role', '==', 'admin'));
    const usersSnapshot = await getDocs(usersQuery);
    const adminUsersList = usersSnapshot.docs.map(doc => ({
      id: doc.id,
      email: doc.data().email,
    }));
    setAdminUsers(adminUsersList);
  };

  const fetchAssetTypes = async () => {
    const assetTypesQuery = query(collection(db, 'assetTypes'), where('organization', '==', user.organization));
    const assetTypesSnapshot = await getDocs(assetTypesQuery);
    const assetTypesList = assetTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as AssetType));
    setAssetTypes(assetTypesList);
  };

  const fetchGeofences = async () => {
    const geofencesQuery = query(collection(db, 'geofences'), where('organization', '==', user.organization));
    const geofencesSnapshot = await getDocs(geofencesQuery);
    const geofencesList = geofencesSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
    setGeofences(geofencesList);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string | string[]>) => {
    const { name, value } = e.target;
    setNewRule(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setNewRule(prev => ({ 
      ...prev, 
      [name]: checked,
      ...(name === 'allUsers' && checked ? { recipients: [] } : {}),
      ...(name === 'allAssetTypes' && checked ? { assetTypes: [] } : {})
    }));
    if (name === 'allUsers') setAllRecipientsSelected(false);
    if (name === 'allAssetTypes') setAllAssetTypesSelected(false);
  };

  const handleAssetTypeToggle = (assetTypeId: string) => {
    if (newRule.allAssetTypes) return;
    setNewRule(prev => {
      const updatedAssetTypes = prev.assetTypes.includes(assetTypeId)
        ? prev.assetTypes.filter(id => id !== assetTypeId)
        : [...prev.assetTypes, assetTypeId];
      setAllAssetTypesSelected(updatedAssetTypes.length === assetTypes.length);
      return { ...prev, assetTypes: updatedAssetTypes };
    });
  };

  const handleRecipientToggle = (userId: string) => {
    if (newRule.allUsers) return;
    setNewRule(prev => {
      const updatedRecipients = prev.recipients.includes(userId)
        ? prev.recipients.filter(id => id !== userId)
        : [...prev.recipients, userId];
      setAllRecipientsSelected(updatedRecipients.length === adminUsers.length);
      return { ...prev, recipients: updatedRecipients };
    });
  };

  

  const handleSelectAllAssetTypes = () => {
    setAllAssetTypesSelected(!allAssetTypesSelected);
    setNewRule(prev => ({
      ...prev,
      assetTypes: !allAssetTypesSelected ? assetTypes.map(type => type.id) : []
    }));
  };

  const handleSelectAllRecipients = () => {
    setAllRecipientsSelected(!allRecipientsSelected);
    setNewRule(prev => ({
      ...prev,
      recipients: !allRecipientsSelected ? adminUsers.map(user => user.id) : []
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (editingRule && editingRule.id) {
      const { id, ...updateData } = newRule;
      await updateDoc(doc(db, 'notificationRules', editingRule.id), updateData);
    } else {
      await addDoc(collection(db, 'notificationRules'), { ...newRule, organization: user.organization });
    }
    fetchNotificationRules();
    setNewRule({ 
      triggerType: 'geofenceEntry', 
      triggerValue: '', 
      notificationTitle: '', 
      notificationBody: '', 
      recipients: [], 
      assetTypes: [],
      allUsers: false,
      allAssetTypes: false,
    });
    setEditingRule(null);
  };
  const handleDelete = async (id: string) => {
    await deleteDoc(doc(db, 'notificationRules', id));
    fetchNotificationRules();
  };

  const handleEdit = (rule: NotificationRule) => {
    setEditingRule(rule);
    setNewRule(rule);
  };

  return (
    <div>
      <h1>{t('Notification Management')}</h1>
      <form onSubmit={handleSubmit}>
        <StyledFormControl fullWidth margin="normal">
          <StyledInputLabel>{t('Trigger Type')}</StyledInputLabel>
          <Select
            name="triggerType"
            value={newRule.triggerType}
            onChange={handleInputChange}
            label={t('Trigger Type')}
          >
            <MenuItem value="geofenceEntry">{t('Geofence Entry')}</MenuItem>
            <MenuItem value="geofenceExit">{t('Geofence Exit')}</MenuItem>
          </Select>
        </StyledFormControl>
        <StyledFormControl fullWidth margin="normal">
          <StyledInputLabel>{t('Geofence')}</StyledInputLabel>
          <Select
            name="triggerValue"
            value={newRule.triggerValue}
            onChange={handleInputChange}
            label={t('Geofence')}
          >
            <MenuItem value="all">{t('All Geofences')}</MenuItem>
            {geofences.map((geofence) => (
              <MenuItem key={geofence.id} value={geofence.name}>
                {geofence.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <TextField
          fullWidth
          margin="normal"
          name="notificationTitle"
          label={t('Notification Title')}
          value={newRule.notificationTitle}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="notificationBody"
          label={t('Notification Body')}
          value={newRule.notificationBody}
          onChange={handleInputChange}
          multiline
          rows={3}
        />
        <StyledFormControl fullWidth margin="normal">
          <StyledInputLabel>{t('Recipients')}</StyledInputLabel>
          <Select
            multiple
            name="recipients"
            value={newRule.recipients}
            onChange={handleInputChange}
            renderValue={(selected) => (selected as string[]).map(id => adminUsers.find(user => user.id === id)?.email).join(', ')}
            disabled={newRule.allUsers}
            label={t('Recipients')}
          >
            <MenuItem>
              <Checkbox
                checked={newRule.allUsers}
                onChange={handleCheckboxChange('allUsers')}
              />
              <ListItemText primary={t('All Users')} />
            </MenuItem>
            {!newRule.allUsers && (
              <MenuItem>
                <Checkbox
                  checked={allRecipientsSelected}
                  indeterminate={newRule.recipients.length > 0 && !allRecipientsSelected}
                  onChange={handleSelectAllRecipients}
                />
                <ListItemText primary={t('Select/Deselect All')} />
              </MenuItem>
            )}
            {adminUsers.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                <Checkbox 
                  checked={newRule.recipients.includes(user.id)} 
                  onChange={() => handleRecipientToggle(user.id)}
                  disabled={newRule.allUsers}
                />
                <ListItemText primary={user.email} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledFormControl fullWidth margin="normal">
          <StyledInputLabel>{t('Asset Types')}</StyledInputLabel>
          <Select
            multiple
            name="assetTypes"
            value={newRule.assetTypes}
            onChange={handleInputChange}
            renderValue={(selected) => (selected as string[]).map(id => assetTypes.find(type => type.id === id)?.typeName).join(', ')}
            disabled={newRule.allAssetTypes}
            label={t('Asset Types')}
          >
            <MenuItem>
              <Checkbox
                checked={newRule.allAssetTypes}
                onChange={handleCheckboxChange('allAssetTypes')}
              />
              <ListItemText primary={t('All Asset Types')} />
            </MenuItem>
            {!newRule.allAssetTypes && (
              <MenuItem>
                <Checkbox
                  checked={allAssetTypesSelected}
                  indeterminate={newRule.assetTypes.length > 0 && !allAssetTypesSelected}
                  onChange={handleSelectAllAssetTypes}
                />
                <ListItemText primary={t('Select/Deselect All')} />
              </MenuItem>
            )}
            {assetTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                <Checkbox 
                  checked={newRule.assetTypes.includes(type.id)} 
                  onChange={() => handleAssetTypeToggle(type.id)}
                  disabled={newRule.allAssetTypes}
                />
                <ListItemText primary={type.typeName} />
              </MenuItem>
            ))}
          </Select>
         </StyledFormControl>
        <Button type="submit" variant="contained" color="primary">
          {editingRule ? t('Update Rule') : t('Add Rule')}
        </Button>
      </form>
      <List>
        {notificationRules.map((rule) => (
          <ListItem key={rule.id}>
            <ListItemText
              primary={`${t(rule.triggerType)}: ${rule.triggerValue === 'all' ? t('All Geofences') : rule.triggerValue}`}
              secondary={`${rule.notificationTitle} - ${rule.notificationBody}`}
            />
            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(rule)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => rule.id && handleDelete(rule.id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default NotificationManagementPage;