import React, { useEffect, useState, useMemo, useTransition } from 'react';
//import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import mapboxgl, { Map, MapMouseEvent} from 'mapbox-gl';
//import GeoJSONFeature from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { db } from './firebase-config';
import { collection, query, where, getDocs, orderBy, limit, doc, addDoc, GeoPoint, deleteDoc, DocumentReference } from 'firebase/firestore';
import { LatLngExpression } from 'leaflet';
import {ObservationType, UserType, Asset, AssetWithLocation, QRCode, Location, AssetType, Geofence } from './types';
import Select from 'react-select';
import './AssetMap.css';
import { useTranslation } from 'react-i18next';
import { GeoJSON } from 'geojson';
import { MAPBOX_ACCESS_TOKEN, isMapboxTokenAvailable } from './env';
import { IControl } from 'mapbox-gl';
import { useParams  } from 'react-router-dom';
import { TreeItem } from '@mui/x-tree-view';
import { Checkbox, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterTreeView from './FilterTreeView';
import { IconButton, Popover, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface AssetMapProps {
  user: UserType;
}

const AssetMap: React.FC<AssetMapProps> = ({ user  }) => {
  const [assetsWithLocations, setAssetsWithLocations] = useState<AssetWithLocation[]>([]);
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
  const [selectedTypeIds, setSelectedTypeIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [draw, setDraw] = useState<MapboxDraw | null>(null);
  const { t } = useTranslation();
  const [geofences, setGeofences] = useState<Geofence[]>([]);
  const { assetId } = useParams<{ assetId?: string }>();
  const [activeNotifications, setActiveNotifications] = useState<{ [assetId: string]: string[] }>({});
  const [activeObservations, setActiveObservations] = useState<{ [assetId: string]: string[] }>({});
  const [selectedFilters, setSelectedFilters] = useState<{
    assetTypes: string[];
    notificationTypes: string[];
    observationTypes: string[];
  }>({
    assetTypes: [],
    notificationTypes: [],
    observationTypes: [],
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [observationTypes, setObservationTypes] = useState<ObservationType[]>([]);

  const loadPngIcon = (iconName: string): string => {
    return `${process.env.PUBLIC_URL}/icons/${iconName}.png`;
  };

  const addIconToMap = (map: mapboxgl.Map, iconName: string) => {
    const iconUrl = loadPngIcon(iconName);
    map.loadImage(iconUrl, (error, image) => {
      if (error) {
        console.error(`Error loading icon: ${iconName}`, error);
        return;
      }
      if (!map.hasImage(iconName) && image) {
        map.addImage(iconName, image, { sdf: true });
        console.log(`Added image to map: ${iconName}`);
      }
    });
  };



  useEffect(() => {
    const fetchObservationTypes = async () => {
      if (!user.organization) return;
      
      const observationTypesQuery = query(collection(db, "observationTypes"), where("organization", "==", user.organization));
      const observationTypesSnapshot = await getDocs(observationTypesQuery);
      const fetchedObservationTypes = observationTypesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as ObservationType));
      
      setObservationTypes(fetchedObservationTypes);
    };

    fetchObservationTypes();
  }, [user.organization]);


  useEffect(() => {
    const fetchActiveObservations = async () => {
      if (!user.organization) return;
  
      const observationsQuery = query(
        collection(db, 'observations'),
        where('organization', '==', user.organization),
        where('noted', '==', false)
      );
  
      const observationsSnapshot = await getDocs(observationsQuery);
      const activeObs: { [assetId: string]: string[] } = {};
  
      observationsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.asset && data.type) {
          const assetId = data.asset.id;
          if (!activeObs[assetId]) {
            activeObs[assetId] = [];
          }
          activeObs[assetId].push(data.type.id);
        }
      });
  
      setActiveObservations(activeObs);
    };
  
    fetchActiveObservations();
  }, [user.organization]);

  useEffect(() => {
    const fetchActiveNotifications = async () => {
      if (!user.organization) return;
  
      const notificationsQuery = query(
        collection(db, 'notifications'),
        where('organization', '==', user.organization),
        where('read', '==', false)
      );
  
      const notificationsSnapshot = await getDocs(notificationsQuery);
      const activeNotifs: { [assetId: string]: string[] } = {};
  
      notificationsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.assetId && data.notificationType) {
          const assetId = data.assetId.id; // Assuming assetId is a document reference
          if (!activeNotifs[assetId]) {
            activeNotifs[assetId] = [];
          }
          if (!activeNotifs[assetId].includes(data.notificationType)) {
            activeNotifs[assetId].push(data.notificationType);
          }
        }
      });
  
      setActiveNotifications(activeNotifs);
    };
  
    fetchActiveNotifications();
  }, [user.organization]);

  useEffect(() => {
    if (map && geofences.length > 0) {
      try {
        displayGeofences();
      } catch (error) {
        console.error("Error displaying geofences:", error);
      }
    }
  }, [map, geofences]);

  useEffect(() => {
    if (map) {
      fetchGeofences();
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const setupLayers = () => {
      console.log('Setting up layers...');

      if (!map.getSource('assets')) {
        map.addSource('assets', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50
        });
        console.log('Added assets source to map');
      }

      // Remove existing layers if they exist
      ['clusters', 'cluster-count', 'unclustered-point'].forEach(layerId => {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
          console.log(`Removed existing layer: ${layerId}`);
        }
      });

      // Load PNG icons
      const iconNames = ['circle', 'triangle', 'square', 'star'];
      iconNames.forEach(iconName => addIconToMap(map, iconName));

      // Add the unclustered point layer with dynamic symbols
      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'assets',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'markerSymbol'], 'triangle'], 'triangle',
            ['==', ['get', 'markerSymbol'], 'square'], 'square',
            ['==', ['get', 'markerSymbol'], 'star'], 'star',
            'circle' // default to circle
          ],
          'icon-size': 1, // Adjust this value to change the size of the markers
          'icon-allow-overlap': true
        },
        paint: {
          'icon-color': ['get', 'markerColor'],
          'icon-halo-color': 'white',
        }
      });
      console.log('Added unclustered-point layer');

      // Add the clusters layer
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'assets',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      // Add the cluster count layer
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'assets',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      console.log('Finished setting up layers');
    };

    setupLayers();

    // Add this event listener to track style changes
    map.on('style.load', () => {
      console.log('Map style loaded. Re-adding layers.');
      setupLayers();
    });

  }, [map]);


  const filteredAssets = useMemo(() => {
    return assetsWithLocations.filter((asset) => {
      const assetTypeMatch = selectedFilters.assetTypes.length === 0 || 
        (asset.assetType && selectedFilters.assetTypes.includes((asset.assetType as DocumentReference).id));
      
      const notificationMatch = selectedFilters.notificationTypes.length === 0 || 
        (activeNotifications[asset.firebaseId] && 
         activeNotifications[asset.firebaseId].some((type) => selectedFilters.notificationTypes.includes(type)));
      
      const observationMatch = selectedFilters.observationTypes.length === 0 || 
        (activeObservations[asset.firebaseId] && 
         activeObservations[asset.firebaseId].some((type) => selectedFilters.observationTypes.includes(type)));
  
      return assetTypeMatch && 
             (selectedFilters.notificationTypes.length === 0 || notificationMatch) && 
             (selectedFilters.observationTypes.length === 0 || observationMatch);
    });
  }, [assetsWithLocations, selectedFilters, activeNotifications, activeObservations]);

  useEffect(() => {
    if (!map) return;

    console.log('Updating map with filtered assets...');

    const features = filteredAssets.map(asset => {
      if (asset.latestLocation) {
        const assetType = assetTypes.find(type => type.id === (asset.assetType as DocumentReference).id);
        let markerColor = assetType?.markerColor || '#000000';
        let markerSymbol = assetType?.markerSymbol || 'circle';

        // Apply color coding if specified
        if (assetType?.colorAttribute && asset.attributes[assetType.colorAttribute]) {
          const attribute = assetType.attributes.find(attr => attr.name === assetType.colorAttribute);
          if (attribute && attribute.type === 'enum' && attribute.options && attribute.optionColors) {
            const attributeValue = asset.attributes[assetType.colorAttribute];
            const enumIndex = attribute.options.indexOf(attributeValue);
            if (enumIndex !== -1 && enumIndex < attribute.optionColors.length) {
              markerColor = attribute.optionColors[enumIndex];
            }
          }
        }

        console.log(`Asset ${asset.id}: symbol = ${markerSymbol}, color = ${markerColor}`);

        return {
          type: 'Feature',
          properties: {
            id: asset.id,
            type: assetType?.typeName,
            markerColor: markerColor,
            markerSymbol: markerSymbol,
            attributes: JSON.stringify(asset.attributes),
            definedAttributes: assetType?.attributes.map(attr => attr.name) || [],
            address: asset.latestLocation.address,
            timestamp: asset.latestLocation.timestamp
          },
          geometry: {
            type: 'Point',
            coordinates: [asset.latestLocation.coords.longitude, asset.latestLocation.coords.latitude]
          }
        };
      }
      return null;
    }).filter(feature => feature !== null);

    console.log(`Created ${features.length} features`);

    const source = map.getSource('assets') as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: features as GeoJSON.Feature[]
      });
      console.log('Updated assets source data');
    } else {
      console.error('Assets source not found on map');
    }

    // Debug: Log the current layers on the map
    const style = map.getStyle();
    if (style && style.layers) {
      console.log('Current map layers:', style.layers.map(layer => layer.id));
    } else {
      console.log('Unable to retrieve map style or layers');
    }

    // Debug: Check if the unclustered-point layer exists and log its properties
    const unclusteredLayer = map.getLayer('unclustered-point');
    if (unclusteredLayer) {
      console.log('Unclustered-point layer properties:', unclusteredLayer);
    } else {
      console.error('Unclustered-point layer not found on map');
      console.log('Attempting to re-add unclustered-point layer');
      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'assets',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'markerSymbol'], 'triangle'], 'triangle',
            ['==', ['get', 'markerSymbol'], 'square'], 'square',
            ['==', ['get', 'markerSymbol'], 'star'], 'star',
            'circle' // default to circle
          ],
          'icon-size': 0.5,
          'icon-allow-overlap': true
        },
        paint: {
          'icon-color': ['get', 'markerColor'],
          'icon-halo-color': 'white',
          'icon-halo-width': 2
        }
      });
    }

    // Additional debugging: Log the map center and zoom level
    console.log('Map center:', map.getCenter());
    console.log('Map zoom level:', map.getZoom());

    // Check if any features are visible in the current view
    const visibleFeatures = map.queryRenderedFeatures({ layers: ['unclustered-point'] });
    console.log('Number of visible unclustered points:', visibleFeatures.length);
    if (visibleFeatures.length > 0) {
      console.log('Sample visible feature:', visibleFeatures[0]);
    }

  }, [map, filteredAssets, assetTypes]);
  
  // Define a more flexible geometry type
type MinimalGeometry = {
  type: string;
  coordinates: number[] | number[][] | number[][][] | number[][][][];
};

// Define a minimal feature type that includes only the properties we need
type MinimalFeature = {
  type: string;
  geometry: MinimalGeometry;
  properties: { [key: string]: any };
};

// Define a custom event type without the private property
type CustomMapMouseEvent = Omit<MapMouseEvent, 'features' | '_defaultPrevented'> & {
  features: MinimalFeature[];
};

useEffect(() => {
  if (assetId && map) {
    const asset = assetsWithLocations.find(a => a.id === assetId);
    if (asset && asset.latestLocation) {
      const targetLngLat = new mapboxgl.LngLat(asset.latestLocation.coords.longitude, asset.latestLocation.coords.latitude);
      
      map.flyTo({
        center: targetLngLat,
        zoom: 15,
        essential: true
      });

      map.once('moveend', () => {
        setTimeout(() => {
          const features = map.queryRenderedFeatures([0,0], {
            layers: ['unclustered-point']
          });
          
          const assetFeature = features.find(f => f.properties?.id === asset.id);
          
          if (assetFeature) {
            const point = map.project(targetLngLat);

            // Create a custom event object
            const customEvent: CustomMapMouseEvent = {
              type: 'click',
              target: map,
              originalEvent: new MouseEvent('click'),
              point: point,
              lngLat: targetLngLat,
              features: [{
                type: assetFeature.type,
                geometry: assetFeature.geometry as MinimalGeometry,
                properties: assetFeature.properties || {}
              }],
              preventDefault: () => {},
              defaultPrevented: false,
            };

            // Dispatch the custom event using type assertion
            (map as Map).fire('click', customEvent as MapMouseEvent);
          } else {
            console.log('Asset feature not found on map');
          }
        }, 100);
      });
    }
  }
}, [assetId, map, assetsWithLocations]);

  const fetchGeofences = async () => {
    if (!user.organization) return;
    
    const geofencesQuery = query(
      collection(db, "geofences"),
      where("organization", "==", user.organization)
    );
    
    const geofencesSnapshot = await getDocs(geofencesQuery);
    const fetchedGeofences = geofencesSnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        name: data.name,
        organization: data.organization,
        coordinates: data.coordinates || data.points,
      } as Geofence;
    });
    
    setGeofences(fetchedGeofences);
  };

  const displayGeofences = () => {
    if (!map) {
      console.warn('Map not initialized yet');
      return;
    }

    geofences.forEach((geofence: Geofence) => {
      const coordinates = (geofence.coordinates || geofence.points || []).map((point) => [point.longitude, point.latitude]);
      
      if (coordinates.length === 0) {
        console.warn(`Geofence ${geofence.id} has no valid coordinates`);
        return;
      }
      
      const geofencePolygon: GeoJSON.Feature<GeoJSON.Polygon> = {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'Polygon',
          'coordinates': [coordinates]
        }
      };
  
      if (map.getSource(`geofence-${geofence.id}`)) {
        (map.getSource(`geofence-${geofence.id}`) as mapboxgl.GeoJSONSource)?.setData(geofencePolygon);
      } else {
        map.addSource(`geofence-${geofence.id}`, {
          'type': 'geojson',
          'data': geofencePolygon
        });
  
        map.addLayer({
          'id': `geofence-${geofence.id}`,
          'type': 'fill',
          'source': `geofence-${geofence.id}`,
          'layout': {},
          'paint': {
            'fill-color': '#088',
            'fill-opacity': 0.3
          }
        });
  
        map.on('click', `geofence-${geofence.id}`, (e) => {
          const coordinates = e.lngLat;
          
          const deleteGeofenceText = t('delete_geofence'); 
          new mapboxgl.Popup({offset: [0, -200]})
            .setLngLat(coordinates)
            .setHTML(`
              <h3>${geofence.name}</h3>
              <button onclick="window.deleteGeofence('${geofence.id}')">${deleteGeofenceText}</button>            `)
            .addTo(map);
        });
      }
    });
  };

  

  const deleteGeofence = async (geofenceId: string) => {
    if (window.confirm('Are you sure you want to delete this geofence?')) {
      try {
        await deleteDoc(doc(db, 'geofences', geofenceId));
        alert('Geofence deleted successfully');
        await fetchGeofences();
        if (map) {
          map.removeLayer(`geofence-${geofenceId}`);
          map.removeSource(`geofence-${geofenceId}`);
        }
      } catch (error) {
        console.error('Error deleting geofence:', error);
        alert('Failed to delete geofence. Please try again.');
      }
    }
  };
  
  (window as any).deleteGeofence = deleteGeofence;

  if (isMapboxTokenAvailable()) {
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN as string;
  } else {
    console.error('Mapbox access token is not available');
  }
  const handleFilterChange = (filterType: 'assetTypes' | 'notificationTypes' | 'observationTypes', value: string[]) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  

  useEffect(() => {
    
    const fetchAssetsAndTypes = async () => {
      if (!user.organization) {
        console.error('User organization is undefined');
        setLoading(false);
        return;
      }
    
      console.log('Fetching assets for organization:', user.organization);
    
      try {
         // Check for cached data
         const cachedData = localStorage.getItem(`assetMapData_${user.organization}`);
         if (cachedData) {
           const { cachedAssetTypes, cachedAssetsWithLocations } = JSON.parse(cachedData);
           setAssetTypes(cachedAssetTypes || []);
           setAssetsWithLocations(cachedAssetsWithLocations || []);
           setSelectedTypeIds((cachedAssetTypes || []).map((type: AssetType) => type.id));
           setLoading(false);
         }
 
        // Fetch asset types
        // Inside fetchAssetsAndTypes function
        const assetTypesSnapshot = await getDocs(query(collection(db, 'assetTypes'), where('organization', '==', user.organization)));
        const fetchedAssetTypes = assetTypesSnapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Asset Type:', doc.id, data);
          return {
            id: doc.id,
            ...data
          } as AssetType;
        });
        console.log('Fetched Asset Types:', fetchedAssetTypes);
        setAssetTypes(fetchedAssetTypes);
        setSelectedTypeIds(fetchedAssetTypes.map(type => type.id)); // Initially select all types

        // Fetch assets
        const assetsSnapshot = await getDocs(
          query(collection(db, 'assets'), where('organization', '==', user.organization))
        );
        console.log('Assets snapshot:', assetsSnapshot);
        console.log('Number of assets found:', assetsSnapshot.size);
    
        const assets = assetsSnapshot.docs.map(doc => {
          const data = doc.data();
          const { id, assetType, organization, ...attributes } = data;
          return {
            firebaseId: doc.id,
            id: data.id,
            assetType: data.assetType,
            organization: data.organization,
            attributes: data.attributes || {}
          } as Asset;
        });
        console.log('Processed assets:', assets);
        
        // Fetch latest location for each asset
        const assetsWithLocations = await Promise.all(assets.map(async (asset) => {
          console.log('Processing asset:', asset.id, 'Firebase ID:', asset.firebaseId);
          const assetRef = doc(db, 'assets', asset.firebaseId);
          
          // Fetch the latest location directly for the asset
          const locationsSnapshot = await getDocs(
            query(
              collection(db, 'locations'),
              where('asset', '==', assetRef),
              where('organization', '==', user.organization),
              orderBy('timestamp', 'desc'),
              limit(1)
            )
          );
        
          let latestLocation: Location | null = null;
          if (!locationsSnapshot.empty) {
            latestLocation = locationsSnapshot.docs[0].data() as Location;
          }
        
          return {
            ...asset,
            latestLocation: latestLocation ? {
              coords: {
                latitude: latestLocation.location.latitude,
                longitude: latestLocation.location.longitude
              },
              timestamp: latestLocation.timestamp.toDate(),
              address: latestLocation.address
            } : undefined
          } as AssetWithLocation;
        }));
        
        console.log('Assets with locations:', assetsWithLocations);
        setAssetsWithLocations(assetsWithLocations);

        // Update cache
        localStorage.setItem(`assetMapData_${user.organization}`, JSON.stringify({
          cachedAssetTypes: fetchedAssetTypes,
          cachedAssetsWithLocations: assetsWithLocations
        }));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching assets and locations:', error);
        setLoading(false);
      }
    };
    const fetchData = async () => {
      await fetchAssetsAndTypes();
      //await fetchGeofences();
    };
  
    fetchData();
  }, [user.organization]);

  useEffect(() => {
    if (loading) return;
  
    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [24.9384, 60.1699],
      zoom: 10
    });
  
    const drawInstance = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    });
    
  
    mapInstance.addControl(drawInstance as unknown as IControl);
  
    mapInstance.on('load', () => {
      setMap(mapInstance);
      setDraw(drawInstance);
  
      mapInstance.addSource('assets', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });
  
      mapInstance.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'assets',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });
  
      mapInstance.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'assets',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });
  
      mapInstance.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'assets',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': ['get', 'markerColor'],
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });
  
      mapInstance.on('click', 'clusters', (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        if (features.length > 0 && features[0].properties) {
          const clusterId = features[0].properties.cluster_id;
          (mapInstance.getSource('assets') as mapboxgl.GeoJSONSource).getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;
      
              mapInstance.easeTo({
                center: (features[0].geometry as GeoJSON.Point).coordinates as [number, number],
                zoom: zoom || mapInstance.getZoom()
              });
            }
          );
        }
      });
  
      mapInstance.on('mouseenter', 'clusters', () => {
        mapInstance.getCanvas().style.cursor = 'pointer';
      });
      mapInstance.on('mouseleave', 'clusters', () => {
        mapInstance.getCanvas().style.cursor = '';
      });
    });
  
    return () => {
      mapInstance.remove();
    };
  }, [loading]);

  const fetchAddress = async (longitude: number, latitude: number): Promise<string> => {
    const url = `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}&types=address&access_token=${MAPBOX_ACCESS_TOKEN}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.features && data.features.length > 0) {
        return data.features[0].properties.full_address || data.features[0].place_name || 'Address not found';
      } else {
        return 'Address not found';
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  };

  const createPopupContent = (properties: any) => {
    return `
      <div style="max-width: 300px; overflow-wrap: break-word;">
        <h3>${properties.id}</h3>
        <p><strong>${t('Type')}:</strong> ${properties.type}</p>
          <p><strong>${t('Address')}:</strong> ${properties.address}</p>
        <p><strong>${t('Timestamp')}:</strong> ${new Date(properties.timestamp).toLocaleString()}</p>
        <hr style="margin: 10px 0; border: 0; border-top: 1px solid #eee;">
        <ul style="padding-left: 20px;">
          ${createAttributesContent(properties)}
        </ul>
        <a href="/assets/${properties.id}" target="_blank">${t('View Details')}</a>
      </div>
    `;
  };


  const createAttributesContent = (properties: any) => {
    try {
      const attributes = JSON.parse(properties.attributes);
      return Object.entries(attributes)
        .filter(([key]) => properties.definedAttributes.includes(key))
        .map(([key, value]) => {
          if (typeof value === 'boolean') {
            return `<li><strong>${t(key)}:</strong> ${value ? t('true') : t('false')}</li>`;
          } else {
            return `<li><strong>${t(key)}:</strong> ${value}</li>`;
          }
        })
        .join('');
    } catch (error) {
      console.error('Error parsing attributes JSON:', error);
      return `<li>${t('Error parsing attributes')}</li>`;
    }
  }; 

  const fitPopupToView = (map: mapboxgl.Map, popup: mapboxgl.Popup, coordinates: [number, number]) => {
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend(coordinates);
  
    // Get the popup's dimensions
    const popupElement = popup.getElement();
    if (!popupElement) {
      console.warn('Popup element not found');
      return;
    }
  
    const popupWidth = popupElement.offsetWidth;
    const popupHeight = popupElement.offsetHeight;
  
    // Calculate the optimal map position
    const optimalPosition = map.cameraForBounds(bounds, {
      padding: {
        top: popupHeight + 20,
        bottom: 20,
        left: popupWidth / 2 + 20,
        right: popupWidth / 2 + 20
      }
    });
  
    if (!optimalPosition || !optimalPosition.center) {
      console.warn('Could not calculate optimal position');
      return;
    }
  
    // Adjust the map view
    map.easeTo({
      center: optimalPosition.center,
      zoom: optimalPosition.zoom !== undefined ? Math.min(optimalPosition.zoom, map.getZoom()) : map.getZoom(),
      duration: 500
    });
  };

  useEffect(() => {
    if (!map) return;
    
    

    const features = filteredAssets.map(asset => {
      if (asset.latestLocation) {
        const assetType = assetTypes.find(type => type.id === (asset.assetType as DocumentReference).id);
        let markerColor = assetType?.markerColor || '#000000';
        
        // Apply color coding if specified
        if (assetType?.colorAttribute && asset.attributes[assetType.colorAttribute]) {
          const attribute = assetType.attributes.find(attr => attr.name === assetType.colorAttribute);
          if (attribute && attribute.type === 'enum' && attribute.options && attribute.optionColors) {
            const attributeValue = asset.attributes[assetType.colorAttribute];
            const enumIndex = attribute.options.indexOf(attributeValue);
            if (enumIndex !== -1 && enumIndex < attribute.optionColors.length) {
              markerColor = attribute.optionColors[enumIndex];
            }
          }
        }
  
        // Determine the marker symbol
        const markerSymbol = assetType?.markerSymbol || 'circle';
  
        return {
          type: 'Feature',
          properties: {
            id: asset.id,
            type: assetType?.typeName,
            markerColor: markerColor,
            markerSymbol: markerSymbol,
            attributes: JSON.stringify(asset.attributes),
            definedAttributes: assetType?.attributes.map(attr => attr.name) || [],
            address: asset.latestLocation.address,
            timestamp: asset.latestLocation.timestamp
          },
          geometry: {
            type: 'Point',
            coordinates: [asset.latestLocation.coords.longitude, asset.latestLocation.coords.latitude]
          }
        };
      }
      return null;
    }).filter(feature => feature !== null);
  
    (map.getSource('assets') as mapboxgl.GeoJSONSource)?.setData({
      type: 'FeatureCollection',
      features: features as GeoJSON.Feature[]
    });
  
    const setupLayers = () => {
      if (!map) return;
      // Remove existing layers if they exist
      ['clusters', 'cluster-count', 'unclustered-point'].forEach(layerId => {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
      });
  
      // Add the unclustered point layer with dynamic symbols
      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'assets',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'markerSymbol'], 'triangle'], 'triangle-11',
            ['==', ['get', 'markerSymbol'], 'square'], 'square-11',
            ['==', ['get', 'markerSymbol'], 'star'], 'star-11',
            'circle-11' // default to circle
          ],
          'icon-size': 1.5,
          'icon-allow-overlap': true
        },
        paint: {
          'icon-color': ['get', 'markerColor'],
          'icon-halo-color': 'white',
          'icon-halo-width': 2
        }
      });
      
      // Add this debugging code after adding the layer
      map.on('data', (e: mapboxgl.MapDataEvent | mapboxgl.MapStyleDataEvent) => {
        if (e.dataType === 'source' && e.source && e.source.type === 'geojson') {
          const source = map.getSource('assets');
          if (source === e.source) {
            // Your code here
            const features = map.querySourceFeatures('assets');
            console.log('Source features:', features);
          }
        }
      });
  
      // Add the clusters layer
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'assets',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });
  
      // Add the cluster count layer
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'assets',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });
    };
  
    if (map.loaded()) {
      setupLayers();
    } else {
      map.on('load', setupLayers);
    }
  
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      if (features.length > 0 && features[0].properties) {
        const clusterId = features[0].properties.cluster_id;
        (map.getSource('assets') as mapboxgl.GeoJSONSource).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;
  
            map.easeTo({
              center: (features[0].geometry as GeoJSON.Point).coordinates as [number, number],
              zoom: zoom || map.getZoom()
            });
          }
        );
      }
    });
  
    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });
  
    map.on('click', 'unclustered-point', async(e) => {
      if (e.features && e.features[0]) {
        const coordinates = (e.features[0].geometry as GeoJSON.Point).coordinates.slice() as [number, number];
        const properties = e.features[0].properties as any;
  
        console.log('Clicked feature properties:', properties); // Debugging log
        const address = await fetchAddress(coordinates[0], coordinates[1]);
        // Ensure attributes are correctly parsed and displayed
        let attributesContent = '';
        try {
          const attributes = JSON.parse(properties.attributes);
          attributesContent = Object.entries(attributes)
            .filter(([key]) => properties.definedAttributes.includes(key))
            .map(([key, value]) => {
              if (typeof value === 'boolean') {
                return `<li><strong>${key}:</strong> ${value ? 'true' : 'false'}</li>`;
              } else {
                return `<li><strong>${key}:</strong> ${value}</li>`;
              }
            })
            .join('');
        } catch (error) {
          console.error('Error parsing attributes JSON:', error);
          attributesContent = '<li>Error parsing attributes</li>';
        }
               
        const popupContent = createPopupContent({
          ...properties,
          address: address
        });
  
        const popup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: true,
          maxWidth: '300px'
        })
          .setLngLat(coordinates)
          .setHTML(popupContent)
          .addTo(map);

        
    
        // Use setTimeout to ensure the popup is added to the DOM before calculating its size
        setTimeout(() => {
          fitPopupToView(map, popup, coordinates);
        }, 90);  // Increased timeout to ensure DOM update
      }
    });
  
    map.on('mouseenter', 'unclustered-point', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'unclustered-point', () => {
      map.getCanvas().style.cursor = '';
    });
  
  }, [map, filteredAssets, assetTypes]);
  
  const handleTypeChange = (selectedOptions: any) => {
    setSelectedTypeIds(selectedOptions.map((option: any) => option.value));
  };
  
  const toggleAllTypes = () => {
    setSelectedTypeIds(prevIds =>
      prevIds.length === assetTypes.length ? [] : assetTypes.map(type => type.id)
    );
  };
  
  const typeOptions = assetTypes.map(type => ({
    value: type.id,
    label: type.typeName
  }));
  
  const handleCreateGeofence = async () => {
    if (!draw) return;
  
    const data = draw.getAll();
    if (data.features.length > 0) {
      const polygon = data.features[0];
      if (polygon.geometry.type === 'Polygon') {
        const coordinates = polygon.geometry.coordinates[0];
  
        const name = prompt("Enter a name for this geofence:");
        if (name) {
          try {
            const newGeofence: Omit<Geofence, 'id'> = {
              name,
              organization: user.organization,
              coordinates: coordinates.map((coord: number[]) => new GeoPoint(coord[1], coord[0]))
            };
            await addDoc(collection(db, "geofences"), newGeofence);
            alert(t("Geofence created successfully!"));
            draw.deleteAll();
            await fetchGeofences();
          } catch (error) {
            console.error(t("Error creating geofence:"), error);
            alert(t("Failed to create geofence. Please try again."));
          }
        }
      } else {
        alert(t("Please draw a polygon for the geofence."));
      }
    } else {
      alert(t("Please draw a polygon first."));
    }
  };

  
  
  return (
    <div className="asset-map-container">
      <div className="asset-map-controls" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <div className="left-controls">
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <FilterTreeView
            assetTypes={assetTypes}
            notificationTypes={Array.from(new Set(Object.values(activeNotifications).flat()))}
            observationTypes={observationTypes}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
          />
        </Popover>
      </div>
      
      <div className="right-controls" style={{ display: 'flex', alignItems: 'center' }}>
        <button 
          className="create-geofence-button"
          onClick={handleCreateGeofence}
        >
          {t('Create Geofence')}
        </button>
        <Tooltip 
          title={t("To create a geofence, use the polygon tool in the top-right corner of the map, then click 'Create Geofence'.")} 
          arrow
          placement="left"
        >
          <IconButton size="small">
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </div>
      </div>
      <div id="map" style={{ width: '100%', height: '900px' }}></div>
    </div>
  );
};

export default AssetMap;
  