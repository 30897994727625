import React, { useState, useEffect } from 'react';
import { Container, Paper, List, ListItem, ListItemText, Button } from '@mui/material';
import { getStorage, ref, listAll, getMetadata, getDownloadURL } from "firebase/storage";
import { app } from './firebase-config';
import CodeGeneratorForm from './CodeGeneratorForm';
import { UserType } from './types';
import { useTranslation } from 'react-i18next';


interface CodeGeneratorPageProps {
  user: UserType;
}

const CodeGeneratorPage: React.FC<CodeGeneratorPageProps> = ({ user }) => {
  const [generatedFiles, setGeneratedFiles] = useState<any[]>([]);
  const storage = getStorage(app, "gs://effiasset-d51d6-generated_codes");
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      fetchGeneratedFiles();
    }
  }, [user]);

  const fetchGeneratedFiles = async () => {
    if (!user) return;

    const listRef = ref(storage, user.uid);
    
    try {
      const res = await listAll(listRef);
      const filesPromises = res.items.map(async (itemRef) => {
        const metadata = await getMetadata(itemRef);
        const url = await getDownloadURL(itemRef);
        return {
          name: itemRef.name,
          createdAt: metadata.timeCreated,
          format: metadata.customMetadata?.format,
          url: url
        };
      });

      const files = await Promise.all(filesPromises);
      setGeneratedFiles(files);
    } catch (error) {
      console.error("Error fetching files: ", error);
    }
  };

  const handleOpenFile = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <CodeGeneratorForm onFileGenerated={fetchGeneratedFiles} user={user} />
      </Paper>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <h2>{t("Generated Files")}</h2>
        <List>
          {generatedFiles.map((file) => (
            <ListItem key={file.name}>
              <ListItemText 
                primary={file.name} 
                secondary={`Created: ${new Date(file.createdAt).toLocaleString()}`} 
              />
              <Button onClick={() => handleOpenFile(file.url)}>{t("Open")}</Button>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default CodeGeneratorPage;