import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  Box,
  IconButton,
  Paper,
  Link as MuiLink,
} from '@mui/material';
import { Close as CloseIcon, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { getDoc } from 'firebase/firestore';
import { Observation, Asset, UserType } from './types';
import { useTranslation } from 'react-i18next';
import { MAPBOX_ACCESS_TOKEN } from './env';


interface ObservationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  observation: Observation;
  user: UserType;
  onNext: () => void;
  onPrevious: () => void;
  totalObservations: number;
  currentIndex: number;
  onObservationUpdated?: (observationId: string, noted: boolean) => void;
}

const ObservationModal: React.FC<ObservationModalProps> = ({
  isOpen,
  onRequestClose,
  observation,
  user,
  onNext,
  onPrevious,
  totalObservations,
  currentIndex,
  onObservationUpdated,
}) => {
  const [observationType, setObservationType] = useState<string>('Loading...');
  const [asset, setAsset] = useState<Asset | null>(null);
  const [createdBy, setCreatedBy] = useState<string>('Loading...');
  const { t } = useTranslation();
  const [address, setAddress] = useState<string>('Loading address...');


  useEffect(() => {
    const fetchObservationDetails = async () => {
      if (!observation) return;

      try {
        if (observation.type) {
          const typeDoc = await getDoc(observation.type);
          setObservationType(typeDoc.data()?.typeName || t('Unknown Type'));
        }

        if (observation.asset) {
          const assetDoc = await getDoc(observation.asset);
          setAsset({ id: assetDoc.id, ...assetDoc.data() } as Asset);
        }

        if (observation.createdBy) {
          const userDoc = await getDoc(observation.createdBy);
          setCreatedBy(userDoc.data()?.email || t('Unknown User'));
        }

        if (observation.location) {
          const fetchedAddress = await fetchAddress(observation.location.longitude, observation.location.latitude);
          setAddress(fetchedAddress);
        }
      } catch (error) {
        console.error('Error fetching observation details:', error);
      }
    };

    fetchObservationDetails();
  }, [observation, t]);

  if (!observation) {
    return null;
  }

  const fetchAddress = async (longitude: number, latitude: number): Promise<string> => {
    const url = `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}&types=address&access_token=${MAPBOX_ACCESS_TOKEN}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.features && data.features.length > 0) {
        return data.features[0].properties.full_address || data.features[0].place_name || 'Address not found';
      } else {
        return 'Address not found';
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  };

  const toggleNoted = async () => {
    try {
      const newNotedStatus = !observation.noted;
      // Implement the update logic here (e.g., using updateDoc)
      if (onObservationUpdated) {
        onObservationUpdated(observation.id, newNotedStatus);
      }
    } catch (error) {
      console.error('Error updating observation noted status:', error);
    }
  };

  const formatKey = (key: string): string => {
    return key.split(/(?=[A-Z])/).join(' ').replace(/^\w/, c => c.toUpperCase());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {t("Observation Details")}
        <IconButton
          aria-label="close"
          onClick={onRequestClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="subtitle1"><strong>{t("Type")}:</strong> {observationType}</Typography>
              <Typography variant="subtitle1">
                <strong>{t("Asset ID")}:</strong>{' '}
                <MuiLink component={Link} to={`/map/${asset?.id}`} target="_blank" rel="noopener noreferrer">
                  {asset?.id || 'Loading...'}
                </MuiLink>
              </Typography>
              <Typography variant="subtitle1"><strong>{t("Created By")}:</strong> {createdBy}</Typography>
              <Typography variant="subtitle1"><strong>{t("Created")}:</strong> {observation.timestamp?.toDate().toLocaleString() || 'N/A'}</Typography>
              <Typography variant="subtitle1"><strong>{t("Location")}:</strong> {observation.location ? `${observation.location.latitude}, ${observation.location.longitude}` : 'N/A'}</Typography>
              <Typography variant="subtitle1"><strong>{t("Address")}:</strong> {address || 'N/A'}</Typography>
            </Paper>
            {observation.data && (
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>{t("Additional Data")}</Typography>
                {Object.entries(observation.data).map(([key, value]) => (
                  <Typography key={key} variant="body2">
                    <strong>{formatKey(key)}:</strong> {value?.toString() || 'N/A'}
                  </Typography>
                ))}
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {observation.photoURL && (
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>{t("Observation Photo")}</Typography>
                <Box sx={{ width: '100%', height: 300, overflow: 'hidden' }}>
                  <img src={observation.photoURL} alt="Observation" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPrevious} disabled={totalObservations <= 1} startIcon={<ChevronLeft />}>
          {t("Previous")}
        </Button>
        <Typography variant="body2" sx={{ mx: 2 }}>
          {currentIndex + 1} {t("of")} {totalObservations}
        </Typography>
        <Button onClick={onNext} disabled={totalObservations <= 1} endIcon={<ChevronRight />}>
          {t("Next")}
        </Button>
        <Button onClick={toggleNoted} color="primary" variant="contained">
          {observation.noted ? t('Mark as Unnoted') : t('Mark as Noted')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObservationModal;