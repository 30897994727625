import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Grid,
  Link as MuiLink,
  CircularProgress,
  ListItemButton,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  InsertDriveFile,
  PhotoCamera,
  Upload,
  Image as ImageIcon,
  PictureAsPdf
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { DocumentReference, getDoc, updateDoc, collection, query, where, orderBy, limit, getDocs, doc } from 'firebase/firestore';
import { db } from './firebase-config'; // Adjust the import path as needed
import { AssetType, Asset, AssetWithLocation, UserType, Observation } from './types';
import { MAPBOX_ACCESS_TOKEN, isMapboxTokenAvailable } from './env';


//interface Asset {
//  id: string;
//  //firebaseId: string;
//  photoURLs?: string[];
// documentURLs?: string[];
//  assetType: DocumentReference;
//  organization: DocumentReference;
//  createdBy: DocumentReference;
//  [key: string]: any;
//}

interface AssetModalProps {
  asset: Asset;
  closeModal: () => void;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDocumentUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openDocument: (url: string) => void;
  t: (key: string) => string;
  user: { organization: DocumentReference };
}

interface Location {
  coords: {
    latitude: number;
    longitude: number;
  };
  timestamp: Date;
  address: string;
}

const AssetModal: React.FC<AssetModalProps> = ({
  asset,
  closeModal,
  handleFileUpload,
  handleDocumentUpload,
  openDocument,
  t,
  user
}) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [latestLocation, setLatestLocation] = useState<Location | null>(null);
  const [createdByEmail, setCreatedByEmail] = useState<string | null>(null);
  const [assetTypeName, setAssetTypeName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [publicPage, setPublicPage] = useState(asset.publicPage || false);
  const [assetTypeDocuments, setAssetTypeDocuments] = useState<{ url: string; name: string }[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [address, setAddress] = useState<string>(t("Loading address..."));


  useEffect(() => {
    const fetchAssetTypeDocuments = async () => {
      setLoading(true);
      try {
        const assetTypeDoc = await getDoc(asset.assetType);
        if (assetTypeDoc.exists()) {
          const assetTypeData = assetTypeDoc.data();
          setAssetTypeDocuments(assetTypeData.documents || []);
        }
      } catch (error) {
        console.error("Error fetching asset type documents:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssetTypeDocuments();
  }, [asset.assetType]);


  const extractFileName = (url: string): string => {
    try {
      const parsedUrl = new URL(url);
      const pathname = decodeURIComponent(parsedUrl.pathname);
      return pathname.split('/').pop() || 'Unknown file';
    } catch (error) {
      return 'Unknown file';
    }
  };

  const fetchAddress = async (longitude: number, latitude: number): Promise<string> => {
    const url = `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${longitude}&latitude=${latitude}&types=address&access_token=${MAPBOX_ACCESS_TOKEN}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.features && data.features.length > 0) {
        return data.features[0].properties.full_address || data.features[0].place_name || 'Address not found';
      } else {
        return 'Address not found';
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  };

  const handlePublicPageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPublicPageValue = event.target.checked;
    setPublicPage(newPublicPageValue);
    
    try {
      await updateDoc(doc(db, 'assets', asset.firebaseId), {
        publicPage: newPublicPageValue
      });
      // Optionally, you can show a success message here
    } catch (error) {
      console.error("Error updating publicPage:", error);
      // Optionally, you can show an error message here
      setPublicPage(!newPublicPageValue); // Revert the checkbox if update fails
    }
  };


  const getFileIcon = (fileName: string) => {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdf />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return <ImageIcon />;
      default:
        return <InsertDriveFile />;
    }
  };

  useEffect(() => {
    const fetchAdditionalData = async () => {
      setLoading(true);
      try {
        // Fetch latest location
        const latestLoc = await fetchLatestLocation(asset.firebaseId);
        setLatestLocation(latestLoc);

        if (latestLoc) {
          const fetchedAddress = await fetchAddress(latestLoc.coords.longitude, latestLoc.coords.latitude);
          setAddress(fetchedAddress);
        }

        // Fetch created by email
        const createdByDoc = await getDoc(asset.createdBy);
        setCreatedByEmail(createdByDoc.data()?.email || 'Unknown');

        // Fetch asset type name
        const assetTypeDoc = await getDoc(asset.assetType);
        setAssetTypeName((assetTypeDoc.data() as AssetType)?.typeName || 'Unknown');
      } catch (error) {
        console.error("Error fetching additional data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdditionalData();
  }, [asset]);

  const fetchLatestLocation = async (assetId: string): Promise<Location | null> => {
    const locationsQuery = query(
      collection(db, "locations"),
      where("asset", "==", doc(db, "assets", assetId)),
      where("organization", "==", user.organization),
      orderBy("timestamp", "desc"),
      limit(1)
    );
    const locationSnapshot = await getDocs(locationsQuery);
    const latestLocation = locationSnapshot.docs[0]?.data();

    return latestLocation ? {
      coords: {
        latitude: latestLocation.location.latitude,
        longitude: latestLocation.location.longitude
      },
      timestamp: latestLocation.timestamp.toDate(),
      address: latestLocation.address,
    } : null;
  };

  
  const handlePreviousPhoto = () => {
    setCurrentPhotoIndex((prev) => (prev - 1 + (asset.photoURLs?.length || 0)) % (asset.photoURLs?.length || 1));
  };

  const handleNextPhoto = () => {
    setCurrentPhotoIndex((prev) => (prev + 1) % (asset.photoURLs?.length || 1));
  };

  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  const renderDocumentList = (documents: Array<string | { url: string; name: string }>, isAssetType: boolean = false) => (
    <List>
      {documents.map((doc, index) => {
        const docUrl = typeof doc === 'string' ? doc : doc.url;
        const docName = typeof doc === 'string' ? extractFileName(doc) : doc.name;
        return (
          <ListItem key={index}>
            <ListItemIcon>
              {getFileIcon(docName)}
            </ListItemIcon>
            <ListItemButton onClick={() => openDocument(docUrl)}>
              <ListItemText primary={docName} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
  
  return (
    <Box sx={{ p: 3, maxWidth: 1000, margin: 'auto' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        {t("Asset ID")}:
        <MuiLink component={Link} to={`/map/${asset.id}`} target="_blank" rel="noopener noreferrer">
          {asset.id}
        </MuiLink>
      </Typography>
  
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
            <Typography variant="h6" gutterBottom>{t("Photos")}</Typography>
            {asset.photoURLs && asset.photoURLs.length > 0 ? (
              <Box sx={{ position: 'relative', width: '100%', height: 300 }}>
                <img
                  src={asset.photoURLs[currentPhotoIndex]}
                  alt={`Asset ${asset.id}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                <IconButton
                  onClick={handlePreviousPhoto}
                  sx={{ position: 'absolute', left: 8, top: '50%', transform: 'translateY(-50%)' }}
                >
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  onClick={handleNextPhoto}
                  sx={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)' }}
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            ) : (
              <Typography>{t("No photos available")}</Typography>
            )}
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ mt: 2 }}
            >
              {t("Upload Photo")}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleFileUpload}
              />
            </Button>
          </Paper>
    
          <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
            <Typography variant="h6" gutterBottom>{t("Documents")}</Typography>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="document tabs">
              <Tab label={t("Asset Documents")} />
              <Tab label={t("Asset Type Documents")} />
            </Tabs>
            <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
            <>
              {renderDocumentList(asset.documentURLs || [])}
              <Button
                variant="contained"
                component="label"
                startIcon={<Upload />}
                sx={{ mt: 2 }}
              >
                {t("Upload Document")}
                <input
                  type="file"
                  hidden
                  accept=".pdf,image/*"
                  onChange={handleDocumentUpload}
                />
              </Button>
            </>
          )}
              {activeTab === 1 && renderDocumentList(assetTypeDocuments, true)}
            </Box>
          </Paper>
        </Grid>


  
        <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
          <Typography variant="h6" gutterBottom>{t("Asset Details")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("Type")}:</Typography>
              <Typography>{assetTypeName}</Typography>
            </Grid>
            {asset.attributes && Object.entries(asset.attributes).map(([key, value]) => (
              <Grid item xs={6} key={key}>
                <Typography variant="subtitle2">{t(key)}:</Typography>
                <Typography>{String(value)}</Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
          
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>{t("Additional Data")}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{t("Created By")}:</Typography>
                <Typography>{createdByEmail}</Typography>
              </Grid>
              {latestLocation && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{t("Latest Location")}:</Typography>
                    <Typography>
                      {latestLocation.coords.latitude}, {latestLocation.coords.longitude}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{t("Address")}:</Typography>
                    <Typography>{address}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{t("Timestamp")}:</Typography>
                    <Typography>{latestLocation.timestamp.toLocaleString()}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{t("Active observation count")}:</Typography>
                    <Typography>{asset.activeObservationCount}</Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={publicPage}
                      onChange={handlePublicPageChange}
                      name="publicPage"
                    />
                  }
                  label={t("Public Page")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Button onClick={closeModal} sx={{ mt: 3 }}>{t("Close")}</Button>
    </Box>
  );
};
  
  export default AssetModal;