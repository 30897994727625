import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './firebase-config';
import { UserType, Subscription } from './types';
import './BillingPages.css';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe('pk_live_51PgRMcD36Q5evwnab8Ec4hPjDEyHjv8evN73kqUa23l2Th3mS7yjl5IJVx6CZZAjz2C3yHMJvolWIrEM1FGEbnPx00JGLqJL45');


const BillingManagementPage: React.FC<{ user: UserType }> = ({ user }) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const subscriptionPlans = [
    { name: 'Basic', priceId: 'price_1PuqW1D36Q5evwnaVxFlLcQK', description: t('150'), price: t('50€'), overUse: t('0,3€') },
    { name: 'Standard', priceId: 'price_1PuqXkD36Q5evwnacuBuUZZi', description: t('400'), price: t('100€'), overUse: t('0,3€') },
    { name: 'Enterprise', priceId: 'price_1PuqYxD36Q5evwnaJnAGhDjX', description: t('1000'), price: t('200€'), overUse: t('0,3€') },
  ];

  useEffect(() => {
    fetchSubscriptions();
  }, [user.uid]);

  const fetchSubscriptions = async () => {
    setLoading(true);
    setError('');
    try {
      const functions = getFunctions(app, 'europe-west1');
      const getActiveSubscriptions = httpsCallable<void, { subscriptions: Subscription[] }>(functions, 'getActiveSubscriptions');
      const result = await getActiveSubscriptions();
      setSubscriptions(result.data.subscriptions);
    } catch (err: any) {
      console.error('Error fetching subscriptions:', err);
      setError('Failed to load subscription information');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePlan = async (subscriptionId: string, newPriceId: string) => {
    setLoading(true);
    try {
      const functions = getFunctions(app, 'europe-west1');
      const changePlan = httpsCallable(functions, 'changePlan');
      await changePlan({ subscriptionId, newPriceId });
      const updateUsage = httpsCallable(functions, 'updateUsageForOrganization');
      await updateUsage({ organizationId: user.organization.id });
      await fetchSubscriptions();
    } catch (error: any) {
      setError(error.message || 'Failed to change plan');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePaymentMethod = async () => {
    setLoading(true);
    setError('');
    try {
      const functions = getFunctions(app, 'europe-west1');
      const createPortalSession = httpsCallable<void, { url: string }>(functions, 'createPortalSession');
      const result = await createPortalSession();
      if (result.data && result.data.url) {
        window.location.href = result.data.url;
      } else {
        throw new Error('Failed to create portal session');
      }
    } catch (error: any) {
      console.error('Error updating payment method:', error);
      setError(error.message || 'Failed to update payment method');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelOrReactivateSubscription = async (subscriptionId: string, cancelAtPeriodEnd: boolean) => {
    setLoading(true);
    setError('');
    try {
      const functions = getFunctions(app, 'europe-west1');
      const updateSubscription = httpsCallable<{ subscriptionId: string, cancelAtPeriodEnd: boolean }, { success: boolean, message: string }>(functions, 'updateSubscription');
      const result = await updateSubscription({ subscriptionId, cancelAtPeriodEnd });
      if (result.data.success) {
        await fetchSubscriptions();
      } else {
        throw new Error(result.data.message);
      }
    } catch (error: any) {
      console.error('Error updating subscription:', error);
      setError(error.message || 'Failed to update subscription');
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (priceId: string) => {
    setLoading(true);
    try {
      const functions = getFunctions(app, 'europe-west1');
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      
      const result = await createCheckoutSession({
        organizationId: user.uid,
        priceId: priceId,
      });

      const { sessionId } = result.data as { sessionId: string };
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to initialize');

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) throw error;
    } catch (error: any) {
      setError(error.message || 'Failed to initiate checkout');
    }
    setLoading(false);
  };

  const activeSubscription = subscriptions.find(sub => sub.status === t('active') || sub.status === t('trialing'));

  if (loading) return <div className="loading">{t("Loading...")}</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="billing-container">
      <h1>{activeSubscription ? t("Manage Your Subscription") : t("Choose Your Subscription Plan")}</h1>
      <div className="subscription-plans">
        {subscriptionPlans.map((plan) => {
          const isActive = activeSubscription && activeSubscription.priceId === plan.priceId;

          return (
            <div key={plan.priceId} className="plan-container">
              <div className={`plan-card ${isActive ? 'active-plan' : ''}`}>
                <div className="plan-card-content">
                  <h2>{plan.name}</h2>
                  <p className="plan-price">{plan.price}<span className="per-month"></span></p>{t("/ kuukausi")}
                  <p className="plan-description">
                   <span className="highlight">{plan.description}</span> {t("seurattavaa omaisuuserää ")}
                  </p>
                  <p className="plan-overuse">
                    {t("Overuse")}: <span className="highlight">{plan.overUse}</span> {t("/ kuukausi / omaisuuserä")}
                  </p>
                  {isActive && activeSubscription && (
                    <>
                      <p>{t("Status")}: <span className="highlight">{activeSubscription.status}</span></p>
                    {activeSubscription.status === 'trialing' && (
                      <p>{t("Trial Ends")}: <span className="highlight">
                        {new Date(activeSubscription.trialEndsAt * 1000).toLocaleDateString()}
                      </span></p>
                    )}
                      <p>{t("Current Period End")}: <span className="highlight">{new Date(activeSubscription.currentPeriodEnd * 1000).toLocaleDateString()}</span></p>
                      <p>
                        {t("Next Invoice Amount")}: <span className="highlight">
                          {activeSubscription.nextInvoiceAmount && activeSubscription.nextInvoiceCurrency
                            ? `${(activeSubscription.nextInvoiceAmount / 100).toFixed(2)} ${activeSubscription.nextInvoiceCurrency.toUpperCase()}`
                            : 'N/A'}
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <div className="plan-card-button">
                  {activeSubscription ? (
                    isActive ? (
                      <button 
                        onClick={() => handleCancelOrReactivateSubscription(activeSubscription.id, !activeSubscription.cancelAtPeriodEnd)}
                        className={`btn ${activeSubscription.cancelAtPeriodEnd ? 'btn-success' : 'btn-warning'}`}
                      >
                        {activeSubscription.cancelAtPeriodEnd ? t("Reactivate Subscription") : t("Cancel at Period End")}
                      </button>
                    ) : (
                      <button 
                        onClick={() => handleChangePlan(activeSubscription.id, plan.priceId)}
                        className="btn btn-primary"
                      >
                        {t("Change to This Plan")}
                      </button>
                    )
                  ) : (
                    <button 
                      onClick={() => handleSubscribe(plan.priceId)}
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      {loading ? t('Processing...') : t('Subscribe')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {activeSubscription && (
        <div className="billing-actions">
          <button 
            onClick={handleUpdatePaymentMethod} 
            className="btn btn-secondary"
          >
            {t('Update Payment Method')}
          </button>
        </div>
      )}
    </div>
  );
};

export default BillingManagementPage;