import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Paper, Box } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  const renderList = (content: string) => {
    return (
      <ul>
        {content.split('\n').map((item, index) => (
          <li key={index}>{item.replace(/^- /, '')}</li>
        ))}
      </ul>
    );
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: '2rem', marginTop: '2rem', marginBottom: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          {t('privacyPolicy.title')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t('privacyPolicy.lastUpdated')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyPolicy.introduction')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.informationWeCollect')}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t('privacyPolicy.informationYouProvide')}
        </Typography>
        <Typography variant="body1" component="div">
          <ul>
            <li>{t('privacyPolicy.informationWeCollectContentEmail')}</li>
            <li>{t('privacyPolicy.informationWeCollectContentEmailNewsLetter')}</li>
            <li>{t('privacyPolicy.informationWeCollectContentPassword')}</li>
            <li>{t('privacyPolicy.informationWeCollectContentOrganization')}</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyPolicy.informationWeCollectAutomatically')}
        </Typography>
        <Typography variant="body1" component="div">
          <ul>
            <li>{t('privacyPolicy.informationWeCollectAutomaticallyContentLoc')}</li>
            <li>{t('privacyPolicy.informationWeCollectAutomaticallyContentCrash')}</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          {t('privacyPolicy.InformationYouAdd')}
        </Typography>
        <Typography variant="body1" component="div">
          <ul>
            <li>{t('privacyPolicy.InformationYouAddAssets')}</li>
            <li>{t('privacyPolicy.InformationYouAddObservations')}</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.legalBasis')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyPolicy.legalBasisContent')}
        </Typography>
        <Typography variant="body1" component="div">
          <ul>
            <li>{t('privacyPolicy.legalBasisContentNecessity')}</li>
            <li>{t('privacyPolicy.legalBasisContentLegitimateInterests')}</li>
            <li>{t('privacyPolicy.legalBasisContentConsent')}</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.howWeUseInformation')}
        </Typography>
        <Typography variant="body1" paragraph>
        {t('privacyPolicy.howWeUseInformatioContentHeader')}
        </Typography>
        <Typography variant="body1" component="div">
          {renderList(t('privacyPolicy.howWeUseInformationContent'))}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.dataSharing')}
        </Typography>
        <Typography variant="body1" paragraph>
        {t('privacyPolicy.dataSharingContentHeader')}
        </Typography>
        <Typography variant="body1" component="div">
          {renderList(t('privacyPolicy.dataSharingContent'))}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.dataRetention')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyPolicy.dataRetentionContent')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.dataSecurity')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyPolicy.dataSecurityContent')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.yourRights')}
        </Typography>
        <Typography variant="body1" component="div">
          {t('privacyPolicy.yourRightsContentHeader')}
        </Typography>
        <Typography variant="body1" component="div">
          {renderList(t('privacyPolicy.yourRightsContent'))}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.childrensPrivacy')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyPolicy.childrensPrivacyContent')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.dataBreachNotification')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyPolicy.dataBreachNotificationContent')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.changesToPolicy')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('privacyPolicy.changesToPolicyContent')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('privacyPolicy.contactUs')}
        </Typography>
        <Typography variant="body1" component="div">
          {t('privacyPolicy.contactUsContent')}
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;