import React, { useState, useEffect, ReactNode } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase-config';
import HomePage from './HomePage';
import AssetListPage from './AssetListPage';
import AssetTypeManagement from './AssetTypeManagement';
import LoginPage from './LoginPage';
import Layout from './Layout';
import AdminPage from './AdminPage';
import { doc, getDoc } from 'firebase/firestore';
import { UserType } from './types';
import './global.css';
import Modal from 'react-modal';
import ObservationTypeManagement from './ObservationTypeManagement';
import ObservationsListPage from './ObservationsListPage';
import BillingInfoPage from './BillingInfoPage';
import BillingManagementPage from './BillingManagementPage';
import SubscriptionSuccessPage from './SubscriptionSuccessPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CodeGeneratorPage from './CodeGeneratorPage';
import PublicLayout from './PublicLayout';
import EnhancedPublicHomePage from './EnhancedPublicHomePage';
import PrivacyPolicy from './PrivacyPolicy';
import BlogPosts from './BlogPosts'
import BlogPost from './BlogPost'
import CreateBlogPost from './CreateBlogPost'
import PublicAssetPage from './PublicAssetPage';
import PublicPageSettingsForm from './PublicPageSettingsForm'
import NotificationsListPage from './NotificationsListPage'
import NotificationManagementPage from './NotificationManagementPage'
import { requestNotificationPermission, onMessageListener, registerServiceWorker } from './fcmConfig';
import { updateFCMTokenForUser  } from './notificationService'; // We'll create this function later
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TermsOfService from './TermsOfService';
import SuperAdminDashboard from './SuperAdminDashboard';
import UpdatedPublicLayout from './UpdatedPublicLayout';
import TaskManagement from './TaskManagement';




const theme = createTheme();

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

const stripePromise = loadStripe('pk_live_51PgRMcD36Q5evwnab8Ec4hPjDEyHjv8evN73kqUa23l2Th3mS7yjl5IJVx6CZZAjz2C3yHMJvolWIrEM1FGEbnPx00JGLqJL45');

const App: React.FC = () => {
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ title: '', body: '' });

  Modal.setAppElement('#root');

  useEffect(() => {
    const initializeFCM = async () => {
      await registerServiceWorker();
      const token = await requestNotificationPermission();
      if (token) {
        console.log('FCM Token:', token);
        updateFCMTokenForUser(token);
      }
    };
  
    initializeFCM();

    const unsubscribe = onMessageListener().then((payload) => {
      setNotification({
        title: payload?.notification?.title || '',
        body: payload?.notification?.body || ''
      });
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribe.catch((err) => console.log('Failed to unsubscribe', err));
    };
  }, []);

  useEffect(() => {
    if (notification?.title) {
      toast.info(`${notification.title}\n${notification.body}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [notification]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          await user.getIdToken(true);
          const idTokenResult = await user.getIdTokenResult(true);
          console.log('Custom claims:', idTokenResult.claims);
          console.log('User:', user.uid);
        console.log('Custom claims:', idTokenResult.claims);
        sessionStorage.setItem('userClaims', JSON.stringify(idTokenResult.claims));
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();         
          if (userData) {
            setUser({
              ...user,
              organization: userData.organization,
              role: userData.role,
              admin: idTokenResult.claims.admin,
              superAdmin: idTokenResult.claims.superAdmin
            } as UserType);
          } else {
            setUser(user as UserType);
          }
        } catch (error) {
          console.error('Error getting token claims:', error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
        {user?.superAdmin && (
            <Route path="/super-admin" element={<SuperAdminDashboard />} />
          )}
          <Route path="/" element={
            <UpdatedPublicLayout>
              <EnhancedPublicHomePage />
            </UpdatedPublicLayout>
          } />
          <Route path="/login" element={
            <UpdatedPublicLayout>
              <LoginPage />
            </UpdatedPublicLayout>
          } />
          
          <Route path="/privacy-policy" element={
            <UpdatedPublicLayout>
              <PrivacyPolicy />
            </UpdatedPublicLayout>
          } />
          <Route path="/terms-of-service" element={
            <UpdatedPublicLayout>
              <TermsOfService />
            </UpdatedPublicLayout>
          } />
          <Route path="/asset-map" element={
            user ? (
              <Layout user={user}>
                <HomePage user={user} />
              </Layout>
            ) : (
              <Navigate to="/login" replace />
            )
          } />
          <Route path="/assets" element={
            user ? (
              <Layout user={user}>
                <AssetListPage user={user} />
              </Layout>
            ) : (
              <Navigate to="/login" replace />
            )
          } />
          <Route 
          path="/assets/:assetId" 
          element={
            user ? (
              <Layout user={user}>
                <AssetListPage user={user} />
              </Layout>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
           <Route path="/asset-types" element={
            user ? (
              <Layout user={user}>
                <AssetTypeManagement user={user} />
              </Layout>
            ) : (
              <Navigate to="/login" replace />
            )
          } />
          <Route
            path="/admin"
            element={
              user && user.role === 'admin' ? (
                <Layout user={user}>
                  <AdminPage user={user} />
                </Layout>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/billing-management"
            element={
              user ? (
                <Layout user={user}>
                  <BillingManagementPage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/observation-types"
            element={
              user ? (
                <Layout user={user}>
                  <ObservationTypeManagement user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/notifications"
            element={
              user ? (
                <Layout user={user}>
                  <NotificationsListPage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/observations"
            element={
              user ? (
                <Layout user={user}>
                  <ObservationsListPage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/billing-info"
            element={
              user ? (
                <Elements stripe={stripePromise}>
                <Layout user={user}>
                  <BillingInfoPage user={user} />
                </Layout>
                </Elements>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/generate-codes"
            element={
              user ? (
                <Layout user={user}>
                  <CodeGeneratorPage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/map"
            element={
              user ? (
                <Layout user={user}>
                  <HomePage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
          path="/task-management"
          element={
            user ? (
              <Layout user={user}>
                <TaskManagement user={user} />
              </Layout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
          <Route
            path="/subscription-success"
            element={
              user ? (
                <Layout user={user}>
                  <SubscriptionSuccessPage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/subscription-cancelled"
            element={
              user ? (
                <Layout user={user}>
                  <HomePage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/map/:assetId"
            element={
              user ? (
                <Layout user={user}>
                  <HomePage user={user} />
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route 
          path="/public-asset/:code" 
          element={
            <ErrorBoundary>
              <PublicAssetPage />
            </ErrorBoundary>
          } 
        />
        <Route path="*" element={<div>404 - Not Found</div>} />
            <Route
            path="/blog"
            element={
              <UpdatedPublicLayout>
                <BlogPosts user={user} />
              </UpdatedPublicLayout>
            }
          />
          <Route
            path="/blog/:id"
            element={
              <UpdatedPublicLayout >
                <BlogPost user={user} />
              </UpdatedPublicLayout>
            }
          />
          
        <Route
          path="/blog/create"
          element={
            user && user.superAdmin ? (
              <UpdatedPublicLayout >
                <CreateBlogPost user={user} />
              </UpdatedPublicLayout>
            ) : (
              <Navigate to="/blog" replace />
            )
          }
        />
        <Route
            path="/public-page-settings"
            element={
              user ? (
                <Layout user={user}>
                  <PublicPageSettingsForm user={user}/>
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
           <Route
            path="/notification-management"
            element={
              user ? (
                <Layout user={user}>
                  <NotificationManagementPage user={user}/>
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
    </BrowserRouter>
    <ToastContainer />
    </ThemeProvider>
  );
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong: {this.state.error?.message || 'Unknown error'}</h1>;
    }
    return this.props.children;
  }
}

export default App;
