import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  serverTimestamp, 
  runTransaction,
  DocumentReference,
  Transaction 
} from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { auth, db, app } from './firebase-config';
import { useNavigate, Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Stepper, 
  Step, 
  StepLabel,
  Paper,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    country: ''
  });
  const [isSignup, setIsSignup] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/map');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    if (!formData.email) {
      setError(t('Email is required for password reset.'));
      return;
    }
    try {
      await sendPasswordResetEmail(auth, formData.email);
      setError(t('Password reset email sent. Please check your inbox.'));
    } catch (error: any) {
      console.error("Error sending password reset email: ", error);
      setError(error.message || 'An error occurred while sending the password reset email.');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      if (!formData.email || !formData.password) {
        setError(t('Email and password are required.'));
        return;
      }
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      console.log('User logged in successfully');
      navigate('/asset-map');
    } catch (error: any) {
      console.error("Error signing in: ", error);
      setError(error.message || t('An error occurred during login'));
    }
  };

  const steps = ['Account'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
  
    if (formData.password !== formData.confirmPassword) {
      setError(t('Passwords do not match'));
      return;
    }
  
    if (!termsAccepted || !privacyAccepted) {
      setError(t('You must accept the Terms of Service and Privacy Policy to sign up.'));
      return;
    }
  
    try {
      console.log("Starting user creation process");
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      
      const userId = userCredential.user.uid;
      console.log(`User created with ID: ${userId}`);
  
      await runTransaction(db, async (transaction: Transaction) => {
        const organizationRef = doc(db, 'organizations', userId);
        const userRef = doc(db, 'users', userId);
        const acceptanceLogRef = doc(db, 'termsAcceptanceLogs', userId);
  
        const ipAddress = await fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => data.ip);
  
        transaction.set(organizationRef, {
          createdAt: serverTimestamp(),
          email: formData.email,
          type: "organization"
        });
  
        transaction.set(userRef, {
          email: formData.email,
          organization: organizationRef as DocumentReference,
          role: "admin",
          uid: userId,
          termsAccepted: serverTimestamp(),
          privacyPolicyAccepted: serverTimestamp()
        });
  
        transaction.set(acceptanceLogRef, {
          userId: userId,
          email: formData.email,
          termsAccepted: serverTimestamp(),
          privacyPolicyAccepted: serverTimestamp(),
          ipAddress: ipAddress,
          userAgent: navigator.userAgent
        });
      });
  
      console.log("Database documents created successfully");
  
      const functions = getFunctions(app, 'europe-west1');
      const setAdminClaim = httpsCallable(functions, 'setAdminClaim');
      await setAdminClaim({ uid: userId });
      console.log("Admin claim set successfully");
  
      await userCredential.user.getIdToken(true);
      console.log("Token refreshed successfully");
  
      console.log('User signed up successfully');
      navigate('/billing-info');
    } catch (error) {
      console.error("Error in signup process:", error);
      if (error instanceof FirebaseError && error.code === 'auth/email-already-in-use') {
        setError(t('This email is already in use. Please try a different email or log in.'));
      } else {
        setError(t('An error occurred during sign up. Please try again.'));
      }
    }
  };
  
  const renderStepContent = () => {
    return (
      <>
        <TextField
          fullWidth
          margin="normal"
          label={t("Email")}
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label={t("Password")}
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label={t("Confirm Password")}
          name="confirmPassword"
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
          }
          label={
            <span>
              {t("I have read and agree to the")} <Link to="/terms-of-service" target="_blank">{t("Terms of Service")}</Link>
            </span>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={privacyAccepted}
              onChange={(e) => setPrivacyAccepted(e.target.checked)}
            />
          }
          label={
            <span>
              {t("I have read and agree to the")} <Link to="/privacy-policy" target="_blank">{t("Privacy Policy")}</Link>
            </span>
          }
        />
      </>
    );
  };
  
  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          {isSignup ? t('Sign Up') : t('Login')}
        </Typography>
        {error && (
          <Typography color="error" align="center" paragraph>
            {error}
          </Typography>
        )}
        {isSignup ? (
          <form onSubmit={handleSignup}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{t(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStepContent()}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                {t('Back')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={activeStep === steps.length - 1 ? handleSignup : handleNext}
                disabled={!termsAccepted || !privacyAccepted}
              >
                {activeStep === steps.length - 1 ? t('Sign Up') : t('Next')}
              </Button>
            </Box>
          </form>
        ) : isResetPassword ? (
          <form onSubmit={handlePasswordReset}>
            <TextField
              fullWidth
              margin="normal"
              label={t("Email")}
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              {t('Send Reset Email')}
            </Button>
          </form>
        ) : (
          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              margin="normal"
              label={t("Email")}
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label={t("Password")}
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              {t('Login')}
            </Button>
          </form>
        )}
        <Button
          fullWidth
          onClick={() => {
            setIsSignup(false);
            setIsResetPassword(!isResetPassword);
          }}
          sx={{ mt: 2 }}
        >
          {isResetPassword ? t('Back to Login') : t('Forgot Password?')}
        </Button>
        {!isResetPassword && (
          <Button
            fullWidth
            onClick={() => setIsSignup(!isSignup)}
            sx={{ mt: 2 }}
          >
            {isSignup ? t('Already have an account? Login') : t('New here? Sign Up')}
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default LoginPage;