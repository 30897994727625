import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Checkbox, 
  ListItemText, 
  OutlinedInput,
  Box,
  Typography,
  Paper,
  SelectChangeEvent,
  IconButton,
  List,
  ListItem
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { collection, addDoc, query, where, getDocs, writeBatch, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from './firebase-config';
import { useTranslation } from 'react-i18next';
import { UserType, AssetType } from './types';

interface TaskManagementProps {
  user: UserType;
}

interface Question {
  text: string;
}

interface TaskType {
  name: string;
  description: string;
  recurrence: 'one-time' | 'daily' | 'weekly' | 'monthly' | 'yearly';
  interval?: number;
  applicableAssetTypes: string[];
  questions: Question[];
}

const TaskManagement: React.FC<TaskManagementProps> = ({ user }) => {
  const [taskType, setTaskType] = useState<TaskType>({
    name: '',
    description: '',
    recurrence: 'one-time',
    applicableAssetTypes: [],
    questions: [],
  });
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
  const [newQuestion, setNewQuestion] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAssetTypes = async () => {
      const assetTypesQuery = query(collection(db, 'assetTypes'), where('organization', '==', user.organization));
      const assetTypesSnapshot = await getDocs(assetTypesQuery);
      setAssetTypes(assetTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as AssetType)));
    };
    fetchAssetTypes();
  }, [user.organization]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTaskType(prevState => ({ ...prevState, [name]: value }));
  };

  const handleRecurrenceChange = (event: SelectChangeEvent<'one-time' | 'daily' | 'weekly' | 'monthly' | 'yearly'>) => {
    setTaskType(prevState => ({ ...prevState, recurrence: event.target.value as TaskType['recurrence'] }));
  };

  const handleAssetTypeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setTaskType(prevState => ({
      ...prevState,
      applicableAssetTypes: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      setTaskType(prevState => ({
        ...prevState,
        questions: [...prevState.questions, { text: newQuestion.trim() }],
      }));
      setNewQuestion('');
    }
  };

  const handleDeleteQuestion = (index: number) => {
    setTaskType(prevState => ({
      ...prevState,
      questions: prevState.questions.filter((_, i) => i !== index),
    }));
  };

  const updateAssetTypesWithTask = async (taskTypeId: string, applicableAssetTypes: string[]) => {
    const batch = writeBatch(db);

    for (const assetTypeId of applicableAssetTypes) {
      const assetTypeRef = doc(db, 'assetTypes', assetTypeId);
      batch.update(assetTypeRef, {
        tasks: arrayUnion(taskTypeId)
      });
    }

    const allAssetTypes = assetTypes.map(at => at.id);
    const removedAssetTypes = allAssetTypes.filter(at => !applicableAssetTypes.includes(at));
    for (const assetTypeId of removedAssetTypes) {
      const assetTypeRef = doc(db, 'assetTypes', assetTypeId);
      batch.update(assetTypeRef, {
        tasks: arrayRemove(taskTypeId)
      });
    }

    await batch.commit();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'taskTypes'), {
        ...taskType,
        organization: user.organization,
        createdBy: user.uid,
        createdAt: new Date(),
      });

      await updateAssetTypesWithTask(docRef.id, taskType.applicableAssetTypes);

      alert(t('Task type created successfully'));
      setTaskType({
        name: '',
        description: '',
        recurrence: 'one-time',
        applicableAssetTypes: [],
        questions: [],
      });
    } catch (error) {
      console.error('Error adding task type:', error);
      alert(t('Error creating task type'));
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t('Task Type Management')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          name="name"
          label={t('Task Name')}
          value={taskType.name}
          onChange={handleTextChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          name="description"
          label={t('Task Description')}
          value={taskType.description}
          onChange={handleTextChange}
          multiline
          rows={3}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('Recurrence')}</InputLabel>
          <Select
            name="recurrence"
            value={taskType.recurrence}
            onChange={handleRecurrenceChange}
            label={t('Recurrence')}
          >
            <MenuItem value="one-time">{t('One-time')}</MenuItem>
            <MenuItem value="daily">{t('Daily')}</MenuItem>
            <MenuItem value="weekly">{t('Weekly')}</MenuItem>
            <MenuItem value="monthly">{t('Monthly')}</MenuItem>
            <MenuItem value="yearly">{t('Yearly')}</MenuItem>
          </Select>
        </FormControl>
        {taskType.recurrence !== 'one-time' && (
          <TextField
            fullWidth
            margin="normal"
            name="interval"
            label={t('Interval')}
            type="number"
            value={taskType.interval || ''}
            onChange={handleTextChange}
            helperText={t('Number of days/weeks/months/years between recurrences')}
          />
        )}
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('Applicable Asset Types')}</InputLabel>
          <Select
            multiple
            value={taskType.applicableAssetTypes}
            onChange={handleAssetTypeChange}
            input={<OutlinedInput label={t('Applicable Asset Types')} />}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {assetTypes.map((assetType) => (
              <MenuItem key={assetType.id} value={assetType.id}>
                <Checkbox checked={taskType.applicableAssetTypes.indexOf(assetType.id) > -1} />
                <ListItemText primary={assetType.typeName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box mt={2}>
          <Typography variant="h6">{t('Questions')}</Typography>
          <List>
            {taskType.questions.map((question, index) => (
              <ListItem key={index}>
                <ListItemText primary={question.text} />
                <IconButton onClick={() => handleDeleteQuestion(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              margin="normal"
              label={t('New Question')}
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
            />
            <IconButton onClick={handleAddQuestion}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            {t('Create Task Type')}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default TaskManagement;