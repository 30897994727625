import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import { UserType } from './types';
import './Layout.css'; 
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { Badge, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getUnreadNotificationsCount } from './notificationService';

interface LayoutProps {
  children: React.ReactNode;
  user: UserType | null;
}

const Layout: React.FC<LayoutProps> = ({ children, user }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      if (user && user.organization) {
        try {
          const count = await getUnreadNotificationsCount(user.organization, user.uid);
          setUnreadCount(count);
        } catch (error) {
          console.error('Error fetching unread notifications count:', error);
        }
      }
    };
  
    fetchUnreadCount();
    // Set up an interval to fetch the count every minute
    const interval = setInterval(fetchUnreadCount, 60000);
  
    return () => clearInterval(interval);
  }, [user]);

  const handleLogout = async () => {
    try {
      if (user && user.organization) {
        localStorage.removeItem(`assetMapData_${user.organization}`);
      }
      localStorage.removeItem('cachedAssets');
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  const renderMenuItems = (isMobile: boolean = false) => (
    <>
      <Link to="/map" className="navbar-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Map')}</Link>
      <Link to="/assets" className="navbar-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Assets')}</Link>
      <Link to="/observations" className="navbar-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Observations')}</Link>
      <div className="navbar-item has-dropdown" onClick={toggleDropdown}>
        <span>{t('Settings')}</span>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <Link to="/asset-types" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Add Asset Type')}</Link>
            <Link to="/observation-types" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Observation Types')}</Link>
            <Link to="/generate-codes" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Generate codes')}</Link>
            {user && user.role === 'admin' && (
              <Link to="/task-management" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Task Management')}</Link>
            )}
            {user && user.role === 'admin' && (
              <Link to="/admin" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Admin')}</Link>
            )}
            {user && user.role === 'admin' && (
              <Link to="/billing-management" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Billing management')}</Link>
            )}
            {user && user.role === 'admin' && (
              <Link to="/notification-management" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Notification management')}</Link>
            )}
            {user && user.role === 'admin' && (
              <Link to="/public-page-settings" className="dropdown-item" onClick={isMobile ? toggleMobileMenu : undefined}>{t('Public page settings')}</Link>
            )}
          </div>
        )}
      </div>
    </>
  );


  return (
    <div className="layout">
      <nav className="navbar">
        <div className="navbar-content">
          <div className="navbar-brand">
            <Link to="/"><img src="/PakkaLogoV1.svg" alt="Pakka Tracker" className="navbar-logo" /></Link>
          </div>
          <div className="navbar-menu">
            {renderMenuItems()}
          </div>
          <div className="navbar-controls">
            <span className="navbar-item user-email">{user?.email}</span>
            <LanguageSelector icon={<LanguageIcon />} />
            <IconButton color="inherit" component={Link} to="/notifications">
              <Badge badgeContent={unreadCount} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <button onClick={handleLogout} className="btn btn-logout">{t('Logout')}</button>
            <IconButton onClick={toggleMobileMenu} color="inherit" className="navbar-mobile-menu-button">
              {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </div>
        </div>
        <div className={`navbar-mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          {renderMenuItems(true)}
          <button onClick={handleLogout} className="btn btn-logout mobile">{t('Logout')}</button>
        </div>
      </nav>
      <main className="main-content">
        {children}
      </main>
    </div>
  );
};
export default Layout;