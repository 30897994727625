import React, { useState, useEffect } from 'react';
import { Link , useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFirestore, collection, query, orderBy, limit, getDocs, DocumentData } from 'firebase/firestore';
import { db } from './firebase-config';

// MUI components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Features from './components/Features';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';

// Custom components
import ContactForm from './ContactForm';

interface BlogPost {
  id: string;
  title: string;
  content: string;
  imageUrl?: string;
  date: Date;
  author: string;
}

const EnhancedPublicHomePage: React.FC = () => {
    const { t } = useTranslation();
    const [recentPosts, setRecentPosts] = useState<BlogPost[]>([]);
    const location = useLocation();
  
    useEffect(() => {
      const fetchRecentPosts = async () => {
        const postsCollection = collection(db, 'blogPosts');
        const recentPostsQuery = query(postsCollection, orderBy('date', 'desc'), limit(3));
        const postSnapshot = await getDocs(recentPostsQuery);
        const postList: BlogPost[] = postSnapshot.docs.map(doc => {
          const data = doc.data() as DocumentData;
          return {
            id: doc.id,
            title: data.title,
            content: data.content,
            imageUrl: data.imageUrl,
            date: data.date.toDate(),
            author: data.author
          };
        });
        setRecentPosts(postList);
      };
  
      fetchRecentPosts();
    }, []);
  
    useEffect(() => {
      if (location.state && location.state.scrollTo) {
        const element = document.getElementById(location.state.scrollTo);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  
    return (
      <Box>
        <Hero />
        <Divider />
        <Features/>
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        
        {/* Blog Section */}
        <Container id="blog" maxWidth="lg" sx={{ my: 8 }}>
          <Typography variant="h4" gutterBottom>
            {t('publicHomePage.newsSection.title')}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {recentPosts.map(post => (
              <Card key={post.id} sx={{ maxWidth: 345, width: '100%' }}>
                {post.imageUrl && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={post.imageUrl}
                    alt={post.title}
                  />
                )}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {post.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('By')} {post.author} {t('on')} {post.date.toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {post.content.substring(0, 100)}...
                  </Typography>
                  <Button size="small" component={Link} to={`/blog/${post.id}`}>
                    {t('publicHomePage.newsSection.readMore')}
                  </Button>
                </CardContent>
              </Card>
            ))}
          </Box>
          <Button 
            component={Link} 
            to="/blog" 
            variant="outlined" 
            sx={{ mt: 2 }}
          >
            {t('publicHomePage.newsSection.viewAllPosts')}
          </Button>
        </Container>
  
        <Divider />
        <FAQ />
        <Divider />
  
        {/* Contact Form Section */}
        <Box id="contactform">
        <Container maxWidth="md" sx={{ my: 8 }}>
          <Typography variant="h4" gutterBottom>
            {t('contactForm.title')}
          </Typography>
          <ContactForm />
        </Container>
        </Box>
      </Box>
    );
  };
  
  export default EnhancedPublicHomePage;