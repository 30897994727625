import React, { useState } from 'react';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Switch,
  FormControlLabel
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './firebase-config';
import { UserType } from './types';
import { useTranslation } from 'react-i18next';

interface CodeGeneratorFormProps {
  onFileGenerated: () => void;
  user: UserType;
}

interface LabelDetails {
  pageWidth: number;
  pageHeight: number;
  labelWidth: number;
  labelHeight: number;
  cols: number;
  rows: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  horizontalGap: number;
  verticalGap: number;
}

const CodeGeneratorForm: React.FC<CodeGeneratorFormProps> = ({ onFileGenerated, user }) => {
  const [quantity, setQuantity] = useState<number>(1);
  const [format, setFormat] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isPublicAsset, setIsPublicAsset] = useState(false);
  const [labelDetails, setLabelDetails] = useState<LabelDetails>({
    pageWidth: 210,
    pageHeight: 297,
    labelWidth: 99,
    labelHeight: 42,
    cols: 2,
    rows: 6,
    marginTop: 21,
    marginBottom: 21,
    marginLeft: 4,
    marginRight: 4,
    horizontalGap: 3,
    verticalGap: 2,
  });
  const { t } = useTranslation();

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(event.target.value));
  };

  const handleFormatChange = (event: SelectChangeEvent) => {
    setFormat(event.target.value as string);
  };

  const handleLabelDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLabelDetails(prev => ({ ...prev, [name]: Number(value) }));
  };

  const handlePublicAssetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublicAsset(event.target.checked);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const functions = getFunctions(app, 'europe-west1');
      const generateCodes = httpsCallable(functions, 'generateCodes');
      const result = await generateCodes({ 
        quantity, 
        format, 
        labelDetails, 
        userId: user.uid,
        isPublicAsset,
        domain: 'https://pakkatracker.net/public-asset/'
      });
      
      const data = result.data as { url: string };
      window.open(data.url, '_blank');
      onFileGenerated();
    } catch (error) {
      console.error('Error generating codes:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>{t("Generate Codes")}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={isPublicAsset}
                onChange={handlePublicAssetChange}
                name="isPublicAsset"
              />
            }
            label={t("Generate for Public Asset")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Quantity")}
            type="number"
            value={quantity}
            onChange={handleQuantityChange}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Format")}</InputLabel>
            <Select
              value={format}
              onChange={handleFormatChange}
              label={t("Format")}
            >
              <MenuItem value="excel">{t("Excel")}</MenuItem>
              <MenuItem value="pdf">{t("PDF")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {format === 'pdf' && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">{t("Label Sheet Details")}</Typography>
          </Grid>
          {Object.entries(labelDetails).map(([key, value]) => (
            <Grid item xs={12} sm={6} key={key}>
              <TextField
                fullWidth
                label={t(`labelDetails.${key}`)}
                type="number"
                name={key}
                value={value}
                onChange={handleLabelDetailsChange}
                margin="normal"
              />
            </Grid>
          ))}
        </>
      )}
      </Grid>
      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        fullWidth 
        sx={{ mt: 2 }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : t('Generate Codes')}
      </Button>
    </Box>
  );
};

export default CodeGeneratorForm;