import React from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Checkbox, FormControlLabel, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ObservationType, AssetType } from './types'; // Make sure to import your types
import { useTranslation } from 'react-i18next';


interface FilterTreeViewProps {
    assetTypes: AssetType[];
    notificationTypes: string[];
    observationTypes: ObservationType[];
    selectedFilters: {
      assetTypes: string[];
      notificationTypes: string[];
      observationTypes: string[];
    };
    onFilterChange: (filterType: 'assetTypes' | 'notificationTypes' | 'observationTypes', value: string[]) => void;
  }
  
  const FilterTreeView: React.FC<FilterTreeViewProps> = ({
    assetTypes,
    notificationTypes,
    observationTypes,
    selectedFilters,
    onFilterChange,
  }) => {
    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>, nodeIds: string[]) => {
      const { checked, name } = event.target;
      const [filterType, id] = name.split('-') as ['assetTypes' | 'notificationTypes' | 'observationTypes', string];
      const newSelected = checked
        ? [...selectedFilters[filterType], ...nodeIds]
        : selectedFilters[filterType].filter((item) => !nodeIds.includes(item));
  
      onFilterChange(filterType, newSelected);
    };
    const { t } = useTranslation();

    return (
      <Box sx={{ minWidth: 300, maxWidth: 400 }}>
        <SimpleTreeView
          aria-label="filter tree"
          slots={{
            expandIcon: ChevronRightIcon,
            collapseIcon: ExpandMoreIcon,
          }}
        >
          <TreeItem
            itemId="assetTypes"
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters.assetTypes.length === assetTypes.length}
                    indeterminate={selectedFilters.assetTypes.length > 0 && selectedFilters.assetTypes.length < assetTypes.length}
                    onChange={(event) => handleToggle(event, assetTypes.map((type) => type.id))}
                    name="assetTypes"
                  />
                }
                label={t("Asset Types")}
              />
            }
          >
            {assetTypes.map((type, index) => (
              <TreeItem
                key={`assetTypes-${type.id}-${index}`}
                itemId={`assetTypes-${type.id}-${index}`}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.assetTypes.includes(type.id)}
                        onChange={(event) => handleToggle(event, [type.id])}
                        name={`assetTypes-${type.id}`}
                      />
                    }
                    label={type.typeName}
                  />
                }
              />
            ))}
          </TreeItem>
          <TreeItem
            itemId="notificationTypes"
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters.notificationTypes.length === notificationTypes.length}
                    indeterminate={selectedFilters.notificationTypes.length > 0 && selectedFilters.notificationTypes.length < notificationTypes.length}
                    onChange={(event) => handleToggle(event, notificationTypes)}
                    name="notificationTypes"
                  />
                }
                label={t("Notification Types")}
              />
            }
          >
            {notificationTypes.map((type, index) => (
              <TreeItem
                key={`notificationTypes-${type}-${index}`}
                itemId={`notificationTypes-${type}-${index}`}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.notificationTypes.includes(type)}
                        onChange={(event) => handleToggle(event, [type])}
                        name={`notificationTypes-${type}`}
                      />
                    }
                    label={type}
                  />
                }
              />
            ))}
          </TreeItem>
          <TreeItem
            itemId="observationTypes"
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters.observationTypes.length === observationTypes.length}
                    indeterminate={selectedFilters.observationTypes.length > 0 && selectedFilters.observationTypes.length < observationTypes.length}
                    onChange={(event) => handleToggle(event, observationTypes.map(type => type.id))}
                    name="observationTypes"
                  />
                }
                label={t("Observation Types")}
              />
            }
          >
            {observationTypes.map((type, index) => (
              <TreeItem
                key={`observationTypes-${type.id}-${index}`}
                itemId={`observationTypes-${type.id}-${index}`}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.observationTypes.includes(type.id)}
                        onChange={(event) => handleToggle(event, [type.id])}
                        name={`observationTypes-${type.id}`}
                      />
                    }
                    label={type.typeName}
                  />
                }
              />
            ))}
          </TreeItem>
        </SimpleTreeView>
      </Box>
    );
  };
  
  export default FilterTreeView;