import { collection, addDoc, updateDoc, doc, query, where, orderBy, limit, getDocs, DocumentReference } from 'firebase/firestore';
import { db, auth } from './firebase-config';
import { Notification } from './types';


export const createNotification = async (notification: Omit<Notification, 'id' | 'createdAt'>) => {
  try {
    const docRef = await addDoc(collection(db, 'notifications'), {
      ...notification,
      createdAt: new Date(),
      read: false,
    });
    return docRef.id;
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId: string) => {
  try {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, { read: true });
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

export const getUnreadNotificationsCount = async (organizationRef: DocumentReference, userId: string) => {
  try {
    const q = query(
      collection(db, 'notifications'),
      where('organization', '==', organizationRef),
      where('userId', '==', userId),
      where('read', '==', false)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  } catch (error) {
    console.error('Error getting unread notifications count:', error);
    throw error;
  }
};

export const getNotifications = async (organizationRef: DocumentReference, userId: string, page: number, pageSize: number) => {
  try {
    const q = query(
      collection(db, 'notifications'),
      where('organization', '==', organizationRef),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc'),
      limit(page * pageSize)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Notification));
  } catch (error) {
    console.error('Error getting notifications:', error);
    throw error;
  }
};

export const updateFCMTokenForUser = async (token: string) => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      try {
        await updateDoc(userRef, {
          fcmToken: token
        });
        console.log('FCM Token updated successfully');
      } catch (error) {
        console.error('Error updating FCM token:', error);
      }
    } else {
      console.error('No user is currently signed in');
    }
  };