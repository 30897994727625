import React, { useState } from 'react';
import { db, auth , app} from './firebase-config';
import { collection, addDoc, getDoc, doc,DocumentReference } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';


interface AddUserFormProps {
  organization: DocumentReference;
  onUserAdded: () => void;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ organization, onUserAdded }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const functions = getFunctions(app, 'europe-west1');
    const createUser = httpsCallable(functions, 'createUser');

    try {
      await auth.currentUser?.getIdToken(true);
      const result = await createUser({ email, password,  organization: organization.id, role });
      console.log('User created:', result.data);
      setEmail('');
      setPassword('');
      setRole('user');
      onUserAdded();
    } catch (error) {
      console.error('Error creating user:', error);
      // Handle error (show message to user, etc.)
    }
  };
  
  async function getCurrentUserData() {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      return userDoc.data();
    }
    return null;
  }

  return (
    <form onSubmit={handleSubmit} className="add-user-form">
      <div className="form-group">
        <label htmlFor="email">{t('Email')}</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">{t('Password')}</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group">
        <label htmlFor="role">{t('Role')}</label>
        <select
          id="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="form-input"
        >
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <button type="submit" className="btn btn-primary">{t('Add User')}</button>
    </form>
  );
};
export default AddUserForm;